<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- 회사/서비스 정보 -->
      <div class="footer-section company-info">
        <div class="logo-area">
          <img src="@/assets/logo.png" alt="INKERS" class="logo">
          <p class="slogan">Find Your Perfect Tattoo</p>
        </div>
        <div class="pc-company-info">
          <p>사업자등록번호: 207-74-67340</p>
          <p>대표자: 김태영</p>
          <p>경기도 안산시 단원구 풍전로37-9, 302동 206호</p>
       </div>
        <div class="company-details">
          <p>
            <span class="contact-item">
              <span class="material-icons">phone</span>
              010-8185-3112
            </span>
            <span class="contact-item">
              <span class="material-icons">mail</span>
              inkerskr@gmail.com
            </span>
          </p>
        </div>
      </div>

      <div class="footer-section links-section">
        <!-- 고객 지원 링크 -->
        <div class="support-links">
          <h3>고객 지원</h3>
          <ul>
            <li>
              <a href="http://pf.kakao.com/_UYUtG/chat" target="_blank" rel="noopener noreferrer">
                고객센터
              </a>
            </li>
            <li><a>이용가이드</a></li>
          </ul>
        </div>

        <!-- 정책 및 약관 -->
        <div class="policy-links">
          <h3>약관 및 정책</h3>
          <ul>
            <li><a href="#" @click.prevent="showModal('terms')">이용약관</a></li>
            <!--
            <li><a href="#" @click.prevent="showModal('privacy')">개인정보처리방침</a></li>
            <li><a href="#" @click.prevent="showModal('location')">위치기반서비스 이용약관</a></li>
            <li><a href="#" @click.prevent="showModal('youth')">청소년보호정책</a></li>
            -->
          </ul>
        </div>
      </div>
    </div>

    <!-- 회사 정보 (모바일에서만 표시) -->
    <div class="mobile-company-info">
      <p>사업자등록번호: 207-74-67340</p>
      <p>대표자: 김태영</p>
      <p>경기도 안산시 단원구 풍전로37-9, 302동 206호</p>
    </div>

    <div class="footer-bottom">
      <p>&copy; 2024 INKERS. All rights reserved.</p>
    </div>

    <!-- 모달 컴포넌트 -->
    <div v-if="activeModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ modalTitle }}</h2>
          <button class="close-button" @click="closeModal">
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body" v-html="modalContent"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
      activeModal: null,
      modalContents: {
        terms: {
          title: '이용약관',
          content: `
            <h3>제1조 (목적)</h3>
            <p>본 약관은 TATTOO MAP(이하 "회사")가 제공하는 모든 서비스(이하 "서비스")의 이용 조건 및 절차, 이용자와 회사 간의 권리, 의무 및 책임 사항 등을 규정하는 것을 목적으로 합니다.</p>
            
            <h3>제2조 (정의)</h3>
            <p>1. "서비스"란 회사가 제공하는 웹사이트, 모바일 애플리케이션 및 기타 온라인 플랫폼을 통해 제공되는 모든 서비스를 의미합니다.</p>
            <p>2. "이용자"란 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.</p>
            <p>3. "회원"이란 회사의 서비스에 가입한 자로서, 지속적으로 회사가 제공하는 서비스를 이용할 수 있는 자를 의미합니다.</p>
            <p>4. "비회원"이란 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 의미합니다.</p>

            <h3>제3조 (약관의 효력 및 변경)</h3>
            <p>1. 본 약관은 서비스를 이용하고자 하는 모든 이용자에게 그 효력이 발생합니다.</p>
            <p>2. 회사는 관련 법령을 위반하지 않는 범위에서 본 약관을 개정할 수 있으며, 변경된 약관은 공지사항을 통해 사전 공지합니다.</p>
            <p>3. 변경된 약관에 대해 이용자가 동의하지 않을 경우, 이용자는 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.</p>

            <h3>제4조 (회원 가입 및 이용계약의 체결)</h3>
            <p>1. 회원 가입은 이용자가 약관에 동의하고, 회사가 정한 절차에 따라 이용 신청을 완료함으로써 체결됩니다.</p>
            <p>2. 회사는 다음에 해당하는 경우, 회원 가입 신청을 거부하거나 취소할 수 있습니다.</p>
            <p> ● 허위 정보를 기재하거나 타인의 정보를 도용한 경우</p>
            <p> ● 회사의 서비스 운영을 방해하거나 중단시키는 행위를 한 경우</p>
            <p> ● 기타 회사가 합리적으로 판단했을 때 승인하기 어려운 경우</p>

            <h3>제5조 (서비스 이용)</h3>
            <p>1. 회사는 이용자에게 위치 기반 아티스트 탐색, 포트폴리오 열람, 상담 예약 기능 등을 포함한 서비스를 제공합니다.</p>
            <p>2. 서비스 이용은 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 다만, 회사의 사정이나 기술적 문제가 발생할 경우 서비스가 일시 중단될 수 있습니다.</p>

            <h3>제6조 (회원의 의무)</h3>
            <p>회원은 서비스를 이용함에 있어 다음 행위를 해서는 안 됩니다.</p>
            <p> ● 허위 정보 등록</p>
            <p> ● 타인의 개인정보 도용</p>
            <p> ● 회사의 서비스를 이용하여 영리 목적의 활동을 하는 행위</p>
            <p> ● 기타 법령 및 본 약관을 위반하는 행위</p>

            <h3>제7조 (회사의 의무)</h3>
            <p>1. 회사는 관련 법령 및 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공합니다.</p>
            <p>2. 회사는 이용자로부터 제기된 의견이나 불만 사항이 정당하다고 판단될 경우 이를 신속히 처리합니다.</p>

            <h3>제8조 (개인정보의 보호)</h3>
            <p>1. 회사는 이용자의 개인정보를 보호하기 위해 관련 법령 및 개인정보 처리방침에 따라 적절한 보호 조치를 취합니다.</p>
            <p>2. 이용자는 언제든지 자신의 개인정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리방침은 서비스 내에서 확인할 수 있습니다.</p>

            <h3>제9조 (서비스의 중단)</h3>
            <p>1. 회사는 천재지변, 시스템 오류 등 불가항력적인 사유로 인해 서비스 제공이 어려울 경우, 서비스를 일시 중단할 수 있습니다.</p>
            <p>2. 서비스 중단 시 회사는 사전 또는 사후에 공지사항을 통해 이를 알립니다.</p>

            <h3>제10조 (책임 제한)</h3>
            <p>1. 회사는 이용자가 서비스를 통해 얻은 정보 및 자료에 대한 신뢰도, 정확성에 대해 책임을 지지 않습니다.</p>
            <p>2. 회사는 이용자 간 또는 이용자와 제3자 간에 발생한 분쟁에 대해 개입하거나 책임을 지지 않습니다.</p>

            <h3>제11조 (관할 법원)</h3>
            <p>본 약관과 관련된 분쟁은 대한민국 법률에 따라 처리되며, 분쟁이 발생할 경우 관할 법원은 회사의 본사 소재지를 관할하는 법원으로 합니다.</p>
            <br>

            <p>본 약관은 2024년 12월 5일부터 시행됩니다.</p>
          `
        },
        privacy: {
          title: '개인정보처리방침',
          content: `
            <h3>1. 개인정보의 수집 및 이용 목적</h3>
            <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
            
            <h3>2. 개인정보의 처리 및 보유기간</h3>
            <p>회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
            <!-- 추가 개인정보처리방침 내용 -->
          `
        },
        location: {
          title: '위치기반서비스 이용약관',
          content: `
            <h3>제1조 (목적)</h3>
            <p>본 약관은 회사가 제공하는 위치기반서비스와 관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            
            <h3>제2조 (이용약관의 효력 및 변경)</h3>
            <p>1. 본 약관은 서비스를 신청한 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.</p>
            <!-- 추가 위치기반서비스 약관 내용 -->
          `
        },
        youth: {
          title: '청소년보호정책',
          content: `
            <h3>제1조 (목적)</h3>
            <p>본 정책은 회사가 제공하는 서비스와 관련하여 회사와 청소년 회원 간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
            
            <h3>제2조 (정의)</h3>
            <p>1. "청소년 회원"이란 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 청소년을 말합니다.</p>
            <p>2. "청소년 회원 정보"란 청소년 회원의 개인정보를 의미합니다.</p>
            <!-- 추가 청소년보호정책 내용 -->
          `
        }
      }
    }
  },
  computed: {
    modalTitle() {
      return this.activeModal ? this.modalContents[this.activeModal].title : ''
    },
    modalContent() {
      return this.activeModal ? this.modalContents[this.activeModal].content : ''
    }
  },
  methods: {
    showModal(type) {
      this.activeModal = type
      document.body.style.overflow = 'hidden'
    },
    closeModal() {
      this.activeModal = null
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 60px 24px 24px;
  font-family: 'Pretendard', sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 60px;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.logo-area {
  margin-bottom: 24px;
}

.logo {
  height: 28px;
  width: auto;
  filter: brightness(0) invert(1);
}

.slogan {
  color: #999;
  margin: 8px 0 0;
  font-size: 14px;
}

.company-details {
  color: #999;
  font-size: 14px;
  line-height: 1.6;
}

.company-details p {
  margin: 8px 0;
}

.pc-company-info {
  display: block;
  color: #999;
  font-size: 14px;
  line-height: 1.6;
}

.pc-company-info p {
  margin: 8px 0;
}

.contact-item {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.contact-item .material-icons {
  font-size: 16px;
  margin-right: 6px;
}

.links-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.support-links, .policy-links {
  display: flex;
  flex-direction: column;
}

h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 12px 0;
}

a {
  color: #999;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

a:hover {
  color: #3366ff;
}

.footer-bottom {
  max-width: 1200px;
  margin: 40px auto 0;
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #666;
  font-size: 14px;
}

/* 모달 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.close-button .material-icons {
  font-size: 24px;
  color: #666;
}

.modal-body {
  padding: 24px;
  color: #333;
  line-height: 1.6;
}

.modal-body h3 {
  color: #333;
  margin: 24px 0 16px;
  font-size: 18px;
}

.modal-body p {
  margin: 0 0 16px;
  font-size: 15px;
}

.mobile-company-info {
  display: none;
  text-align: center;
  color: #999;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .footer {
    padding: 32px 20px 24px;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .company-info {
    text-align: center;
  }

  .logo-area {
    margin-bottom: 16px;
  }

  .logo {
    height: 24px;
  }

  .company-details p:not(:last-child) {
    display: none;
  }

  .contact-item {
    display: inline-flex;
    margin: 0 10px;
    font-size: 13px;
  }

  .links-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }

  .support-links, .policy-links {
    flex: 1;
    min-width: 0;
  }

  h3 {
    font-size: 14px;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  li {
    margin: 8px 0;
  }

  a {
    font-size: 13px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mobile-company-info {
    display: block;
    margin-top: 24px;
  }

  .footer-bottom {
    margin-top: 24px;
    padding-top: 16px;
    font-size: 12px;
  }

  .pc-company-info {
    display: none;
  }
}

.support-links ul li a {
  color: #999;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

.support-links ul li a:hover {
  color: #3366ff;
}
</style> 