<template>
  <div id="app">
    <!-- 상단 네비게이션 바 -->
    <header class="navbar">
      <!-- 로고 섹션 -->
      <div class="logo">
        <router-link to="/" @click="goHome">
          <img alt="Logo" src="./assets/logo.png">
        </router-link>
      </div>
      <!-- 🆕 총 unread 메시지 수 배지 표시 -->
      <div class="sub-menu">
        <div @click="goToRequest" class="request-button">
          <span class="material-icons">description</span>
          <div v-if="unreadQuotesCount > 0" class="request-badge">
            {{ unreadQuotesCount > 99 ? '99+' : unreadQuotesCount }}
          </div>
        </div>
        <div @click="goToChat" class="chat-button">
          <span class="material-icons">chat_bubble_outline</span>
          <div v-if="totalUnreadCount > 0" class="unread-badge">
            {{ totalUnreadCount > 99 ? '99+' : totalUnreadCount }}
          </div>
        </div>
      </div>

      <div class="menu">
        <button @click="toggleMenu" class="menu-button" aria-label="메뉴">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6H21" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 12H21" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 18H21" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <!-- 오버레이 메뉴 내용 -->
        <div v-if="isMenuOpen" class="overlay">
          <div class="menu-box">
            <!-- 메뉴 닫기 버튼 -->
            <button @click="closeMenu" class="close-button" aria-label="메뉴 닫기">
              &times;
            </button>
            <!-- 타투이스트 프로필 섹션 -->
            <div v-if="isLoggedIn && user.userType === 'artist'" class="profile-section" @click="goToMyPage">
              <img :src="user.profileImage" alt="프로필 이미지" class="profile-image" />
              <span class="nickname">{{ user.nickname }}님</span>
            </div>
            <!-- 일반 사용자 프로필 섹션 -->
            <div v-if="isLoggedIn && user.userType === 'regular'" class="profile-section" @click="goToUserPage">
              <img :src="user.profileImage" alt="프로필 이미지" class="profile-image" />
              <span class="nickname">{{ user.nickname }}님</span>
            </div>
            <!-- 비로그인 사용자 프로필 섹션 -->
            <div v-if="!isLoggedIn" class="profile-section" @click="() => { $router.push('/login'); closeMenu(); }">
              <img src="@/assets/default-profile.jpg" alt="기본 프로필 이미지" class="profile-image" />
              <span class="nickname login-text">로그인을 해주세요.</span>
            </div>
            <!-- 메뉴 링크 목록 -->
            <router-link to="/tattoo-choice" @click="closeMenu" class="menu-item">타투 맵</router-link>
            <router-link to="/Home" @click="closeMenu" class="menu-item">타투 찾기</router-link>
            <router-link to="/find-design" @click="closeMenu" class="menu-item">디자인 찾기</router-link>
            <router-link to="/find-artist" @click="closeMenu" class="menu-item">아티스트 찾기</router-link>
            <router-link 
              v-if="isLoggedIn && user.userType === 'artist'" 
              to="/posts" 
              @click="closeMenu" 
              class="menu-item"
            >
              커뮤니티
            </router-link>
            <!-- 견적 관련 메뉴 -->
            <router-link 
              v-if="isLoggedIn && user.userType === 'regular'" 
              to="/my-tattoo-requests" 
              @click="closeMenu" 
              class="menu-item"
            >
              의뢰 요청
            </router-link>
            <router-link 
              v-if="isLoggedIn && user.userType === 'artist'" 
              to="/tattoo-request-page" 
              @click="closeMenu" 
              class="menu-item"
            >
              의뢰 요청
            </router-link>
            <router-link to="/chats" @click="closeMenu" class="menu-item">채팅</router-link>
            <!-- 인증 버튼 섹션 -->
            <div class="auth-buttons">
              <router-link v-if="!isLoggedIn" to="/signup" @click="closeMenu" class="auth-button">회원가입</router-link>
              <router-link v-if="!isLoggedIn" to="/login" @click="closeMenu" class="auth-button">로그인</router-link>
              <button v-if="isLoggedIn" @click="handleLogout" class="auth-button">로그아웃</button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- 메인 콘텐츠 영역 -->
    <router-view class="content"/>
  </div>
</template>

<script>
// 파이어베이스 관련 임포트 수정: getAuth 추가
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged, signOut, getAuth } from 'firebase/auth';
import { doc, getDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { eventBus } from './eventBus';


export default {
  name: 'App',
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  },
  // 데이터 정의
  data() {
    return {
      isMenuOpen: false,
      isLoggedIn: false,
      user: {
        profileImage: 'https://via.placeholder.com/50x50?text=User',
        nickname: '',
        userType: null
      },
      totalUnreadCount: 0, // 🆕 총 unread 메시지 수 추가
      unreadListener: null, // 🆕 Firestore 리스너 참조
      unreadQuotesCount: 0,
      quotesListener: null,
    }
  },
  // 컴포넌트 생성 시 실행
  created() {
    this.initializeAuthListener();
    eventBus.on('user-logged-out', this.handleUserLoggedOut);
    // Material Icons 동적 로드
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  },
  // 메소드 정의
  methods: {
    // 홈으로 이동하는 메소드
    goHome() {
      if (this.$route.path === '/') {
        window.location.reload();
      }
      this.closeMenu();
    },

    // 인증 상태 리스너 초기화
    async initializeAuthListener() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.isLoggedIn = true;
          await this.updateUserInfo(user.uid);
          this.setupUnreadCountListener(user.uid); // 🆕 unreadCount 리스너 설정
          this.setupUnreadQuotesListener(user.uid); // 견적서 리스너 추가
        } else {
          this.isLoggedIn = false;
          this.resetUserInfo();
          this.cleanupUnreadCountListener(); // 🆕 unreadCount 리스너 정리
          this.cleanupUnreadQuotesListener(); // 견적서 리스너 정리
        }
      });
    },

    // 사용자 정보 업데이트
    async updateUserInfo(uid) {
      try {
        // 일반 사용자 정보 확인
        const userDoc = await getDoc(doc(db, 'users', uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.user = {
            profileImage: userData.profileImageUrl || require('@/assets/default-profile.jpg'),
            nickname: userData.nickname || '사용자',
            userType: 'regular'
          };
          return;
        }

        // 타투이스트 정보 확인
        const artistDoc = await getDoc(doc(db, 'artists', uid));
        if (artistDoc.exists()) {
          const artistData = artistDoc.data();
          this.user = {
            profileImage: artistData.profileImageUrl || require('@/assets/default-profile.jpg'),
            nickname: artistData.nickname || '타투이스트',
            userType: 'artist'
          };
          return;
        }
      } catch (error) {
        console.error('사용자 정보 로드 실패:', error);
      }
    },

    // 사용자 정보 초기화
    resetUserInfo() {
      this.user = {
        profileImage: 'https://via.placeholder.com/50x50?text=User',
        nickname: '',
        userType: null
      };
      this.totalUnreadCount = 0; // 🆕 unreadCount 초기화
    },

    // 메뉴 토글
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        safeReplaceState(window.location.pathname);
      }
    },

    // 메뉴 닫기
    closeMenu() {
      this.isMenuOpen = false;
    },

    // 마이페이지로 이동
    goToMyPage() {
      this.$router.push('/my-page');
      this.closeMenu();
    },

    // 유저페이지로 이동
    goToUserPage() {
      this.$router.push('/user-page');
      this.closeMenu();
    },

    // 채팅 페이지로 이동
    goToChat() {
      const authInstance = getAuth(); // 🆕 getAuth 사용
      if (!authInstance.currentUser) {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/login');
        return;
      }
      this.$router.push('/chats');
    },

    // 견적 요청 페이지로 이동
    goToRequest() {
      const authInstance = getAuth();
      if (!authInstance.currentUser) {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/login');
        return;
      }

      const self = this;
      async function fetchUserDoc() {
        // 일반 유저 문서 확인
        const userDoc = await getDoc(doc(db, 'users', authInstance.currentUser.uid));
        if (userDoc.exists()) {
          if (userDoc.data().userType === 'regular') {
            self.$router.push('/my-tattoo-requests');
            return;
          }
        }

        // 아티스트 문서 확인 
        const artistDoc = await getDoc(doc(db, 'artists', authInstance.currentUser.uid));
        if (artistDoc.exists()) {
          self.$router.push('/tattoo-request-page');
          return;
        }
      }
      fetchUserDoc();
    },

    // 로그아웃 처리
    async handleLogout() {
      try {
        await signOut(auth);
        this.closeMenu();
        alert('로그아웃 되었습니다.');
        this.$router.push('/').then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error('로그아웃 에러:', error);
        alert('로그아웃 중 오류가 발생했습니다.');
      }
    },

    handleUserLoggedOut() {
      this.isLoggedIn = false;
      this.resetUserInfo();
      this.cleanupUnreadCountListener(); // 🆕 로그아웃 시 unreadCount 리스너 정리
      this.$router.push('/').then(() => {
        window.location.reload();
      });
    },

    // 🆕 Firestore 리스너 설정
    setupUnreadCountListener(uid) {
      // Firestore 쿼리: 'chats' 컬렉션에서 현재 사용자가 참여자인 모든 채팅 문서
      const chatsQuery = query(
        collection(db, 'chats'),
        where('participants', 'array-contains', uid)
      );

      // Firestore 리스너 설정
      this.unreadListener = onSnapshot(chatsQuery, (snapshot) => {
        let total = 0;
        snapshot.forEach((doc) => {
          const chatData = doc.data();
          const unread = chatData.unreadCount?.[uid] || 0;
          total += unread;
        });
        this.totalUnreadCount = total;
      }, (error) => {
        console.error('unreadCount 리스너 에러:', error);
      });
    },

    // 🆕 Firestore 리스너 정리
    cleanupUnreadCountListener() {
      if (this.unreadListener) {
        this.unreadListener(); // 리스너 해제
        this.unreadListener = null;
      }
      this.totalUnreadCount = 0; // 총 unread count 초기화
    },

    // 미확인 견적서 수 리스너 설정
    async setupUnreadQuotesListener(uid) {
      try {
        // 사용자 타입 확인
        const userDoc = await getDoc(doc(db, 'users', uid));
        if (!userDoc.exists() || userDoc.data().userType !== 'regular') {
          return; // 일반 사용자가 아닌 경우 리턴
        }

        // 해당 사용자의 타투 요청에 대한 쿼리
        const requestsQuery = query(
          collection(db, 'tattooRequests'),
          where('userId', '==', uid)
        );

        // Firestore 리스너 설정
        this.quotesListener = onSnapshot(requestsQuery, (snapshot) => {
          let unreadCount = 0;
          
          snapshot.forEach((doc) => {
            const request = doc.data();
            if (request.quotes) {
              // 미확인 견적서 수 계산
              unreadCount += request.quotes.filter(quote => !quote.viewed).length;
            }
          });
          
          this.unreadQuotesCount = unreadCount;
        });
      } catch (error) {
        console.error('미확인 견적서 수 리스너 에러:', error);
      }
    },

    // 리스너 정리
    cleanupUnreadQuotesListener() {
      if (this.quotesListener) {
        this.quotesListener();
        this.quotesListener = null;
      }
      this.unreadQuotesCount = 0;
    },
  },
  // 🆕 beforeDestroy를 beforeUnmount로 변경
  beforeUnmount() {
    this.cleanupUnreadCountListener(); // 🆕 언마운트 시 리스너 정리
    this.cleanupUnreadQuotesListener(); // 견적서 리스너 정리 추가
  },
  // 이미 추가된 mounted 메서드 유지
  mounted() {
    window.addEventListener('popstate', () => {
      if (this.isMenuOpen) this.closeMenu();
    });
  }
}

function safeReplaceState(url) {
  // 현재 history state를 보존
  const currentState = window.history.state;
  
  // state 객체가 null이 아닌지 확인하고, 필요한 속성들을 보존
  const preservedState = currentState ? { ...currentState } : {};
  
  // history state 업데이트
  window.history.replaceState(preservedState, '', url);
}
</script>

<style>
/* 전체 페이지 기본 스타일 */
body, html, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Noto Sans KR', sans-serif;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
body::-webkit-scrollbar {
  display: none; /* Webkit */
}

/* 네비게이션 바 스타일 */
.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  height: 60px;
  top: 0;
}

/* 콘텐츠 영역 스타일 */
.content {
  margin-top: 60px;
}

/* 로고 이미지 스타일 */
.logo img {
  height: 20px;
}

.chat-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: #555555;
  position: relative;
}

.request-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: #555555;
  position: relative;
}

.sub-menu {
  margin-left: auto;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* 메뉴 버튼 스타일 */
.menu-button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* 메뉴 버튼 아이콘 스타일 */
.menu-button svg {
  width: 24px;
  height: 24px;
  fill: none;
}

/* 오버레이 메뉴 스타일 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* 메뉴 박스 스타일 */
.menu-box {
  background-color: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  max-width: 400px;
  text-align: left;
  position: relative;
}

/* 메뉴 닫기 버튼 스타일 */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  color: #333;
  cursor: pointer;
  animation: fadeOut 0.3s ease-in-out;
}

/* 프로필 섹션 스타일 */
.profile-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 프로필 이미지 스타일 */
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 닉네임 스타일 */
.nickname {
  font-size: 18px;
  color: #333;
}

/* 메뉴 항목 링크 스타일 */
.menu-item {
  display: block;
  padding: 10px 10px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s, transform 0.2s;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
  font-weight: 500;
  width: 85%;
  margin: 0 auto;
  cursor: pointer;
}

.menu-item:last-child {
  border-bottom: none;
}

/* 인증 버튼 컨테이너 스타일 */
.auth-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* 인증 버튼 스타일 */
.auth-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  background-color: #808080;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

.auth-button:hover {
  background-color: #696969;
}

.unread-badge {
  position: absolute;
  top: -3px;
  right: -5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-badge {
  position: absolute;
  top: -3px;
  right: -5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 애니메이션 효과 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

</style>
