//타투이스트 페이지

<template>
  <div class="my-page">
    <!-- 상단 프로필 섹션 -->
    <div class="profile-header">
      <button class="profile-button" @click="GoToProfile">프로필</button>
      <button 
        v-if="user.userType === 'OKartist' && !user.hasRequestedApproval" 
        class="approval-button" 
        @click="requestApproval"
      >
        승인요청
      </button>
      <button class="edit-button" @click="openEditModal">수정</button>
    </div>

    <!-- 프로필 정보 섹션 -->
    <div class="profile-info">
      <h2>{{user.nickname}}님의 정보</h2>
      <div>
        <div v-if="user.profileImageUrl">
        <img :src="user.profileImageUrl" alt="대표 이미지" class="profile-image" />
        </div>
        <div v-else class="no-image">
          <p>대표 이미지가 없습니다.</p>
        </div>
      </div>
      <div class="profile-details">
        <div class="info-grid">
          <div class="info-item">
            <span class="label">이메일:</span>
            <span class="value">{{ user.email }}</span>
          </div>
          <div class="info-item">
            <span class="label">전화번호:</span>
            <span class="value">{{ user.phone }}</span>
          </div>
          <div class="info-item">
            <span class="label">위치:</span>
            <span class="value">{{ user.location && user.location.address ? user.location.address.split(' ').slice(0,3).join(' ') : '위치 정보 없음' }}</span>
          </div>
          <div class="info-item">
            <span class="label">시간당:</span>
            <span class="value">{{ user.hourcost }}원</span>
          </div>
          <div class="info-item">
            <span class="label">최소비용:</span>
            <span class="value">{{ user.mincost }}원</span>
          </div>
          <div class="info-item">
            <span class="label">경력:</span>
            <span class="value">{{ user.career }}년</span>
          </div>
          <div class="info-item">
            <span class="label">샵:</span>
            <span class="value">{{ user.shopName }}</span>
          </div>
          <div class="info-item">
            <span class="label">장르:</span>
            <span class="value">{{ user.genres ? user.genres.join(', ') : '장르 없음' }}</span>
          </div>
        </div>
        <div class="introduction-section">
          <span class="label">소개:</span>
          <p class="introduction">{{ user.introduction }}</p>
        </div>
      </div>
    </div>

    <!-- 하단 버튼 섹션을 표 형식의 메뉴로 변경 -->
    <div class="bottom-menu">
      <table class="menu-table">
        <tbody>
          <tr>
            <td class="menu-item" @click="showPortfolio">
              <span>포트폴리오</span>
            </td>
            <td class="menu-item" @click="showDesign">
              <span>디자인</span>
            </td>
            <td class="menu-item" @click="goToChat">
              <span>채팅</span>
              <span v-if="unreadCount" class="unread-badge">{{ unreadCount }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 포트폴리오 섹션 -->
    <div v-if="activeSection === 'portfolio'" class="portfolio-section">
      <div class="portfolio-header">
        <h3>포트폴리오 관리</h3>
        <button @click="openUploadModal" class="upload-button">업로드</button>
      </div>
      <div class="portfolio-gallery">
        <div v-for="portfolio in portfolioData" :key="portfolio.id" class="portfolio-card">
          <div class="portfolio-images">
            <img v-for="(image, imgIndex) in portfolio.images" :key="imgIndex" :src="image" class="portfolio-image" />
          </div>
          <table class="portfolio-details">
            <tbody>
              <tr>
                <th>소개</th>
                <td>{{ portfolio.description }}</td>
              </tr>
              <tr>
                <th>소요 시간</th>
                <td>{{ portfolio.duration }}시간</td>
              </tr>
              <tr>
                <th>장르</th>
                <td>{{ portfolio.genres.join(', ') }}</td>
              </tr>
            </tbody>
          </table>
          <button @click="deletePortfolio(portfolio.id)" class="delete-button">삭제</button>
        </div>
      </div>
    </div>

    <!-- 디자인 섹션 -->
    <div v-if="activeSection === 'design'" class="portfolio-section">
      <div class="portfolio-header">
        <h3>디자인 관리</h3>
        <button @click="openDesignModal" class="upload-button">업로드</button>
      </div>
      <div class="portfolio-gallery">
        <div v-for="design in designData" :key="design.id" class="portfolio-card">
          <div class="portfolio-images">
            <img v-for="(image, imgIndex) in design.images" :key="imgIndex" :src="image" class="portfolio-image" />
          </div>
          <table class="portfolio-details">
            <tbody>
              <tr>
                <th>소개</th>
                <td>{{ design.description }}</td>
              </tr>
              <tr>
                <th>소요 시간</th>
                <td>{{ design.duration }}시간</td>
              </tr>
              <tr>
                <th>장르</th>
                <td>{{ design.genres.join(', ') }}</td>
              </tr>
            </tbody>
          </table>
          <div class="portfolio-footer">
            <button @click="deleteDesign(design.id)" class="delete-button">삭제</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 업로드 모달 -->
    <div v-if="isUploadModalOpen" class="modal-overlay">
      <div class="modal">
        <h3>포트폴리오 업로드</h3>
        <div class="upload-form">
          <label for="fileInput">이미지 선택</label>
          <input 
            type="file" 
            id="fileInput"
            @change="handleFileUpload"
            accept="image/*"
          />

          <label for="description">포트폴리오 소개</label>
          <input type="text" id="description" v-model="portfolioDescription" />

          <label for="duration">소요 시간 (시간)</label>
          <input type="number" id="duration" v-model="portfolioDuration" min="0" step="0.5" />

          <label>장르</label>
          <div class="genre-buttons">
            <button
              v-for="genre in genres"
              :key="genre"
              :class="{'active': selectedGenres.includes(genre)}"
              @click="toggleGenre(genre)"
              class="genre-button"
            >
              {{ genre }}
            </button>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="submitPortfolio" class="save-button">업로드</button>
          <button @click="closeUploadModal" class="cancel-button">취소</button>
        </div>
      </div>
    </div>

    <!-- 수정 모달 -->
    <div v-if="isEditModalOpen" class="modal-overlay">
      <div class="modal">
        <h3>내 정보 수정</h3>
        <div class="edit-form">
          <label for="editNickname">닉네임</label>
          <input type="text" id="editNickname" v-model="editForm.nickname" />

          <label for="editPhone">전화번호</label>
          <input type="tel" id="editPhone" v-model="editForm.phone" @input="formatPhoneNumber" placeholder="010-0000-0000" maxlength="13" />

          <label for="editHourcost">시간당 비용</label>
          <input class="hourcost" type="number" id="editHourcost" v-model="editForm.hourcost" />
          
          <label for="editMincost">최소 비용</label>
          <input class="mincost" type="number" id="editMincost" v-model="editForm.mincost" />
          
          <label for="editCareer">경력 (년)</label>
          <input type="number" id="editCareer" v-model="editForm.career" min="0" />

          <label for="editShopName">샵 이름</label>
          <input type="text" id="editShopName" v-model="editForm.shopName" />

          <div class="location-field">
            <label for="editLocation">위치</label>
            <div class="location-display">
              <span class="location-text">{{ editForm.location && editForm.location.address ? editForm.location.address : '위치 정보 없음' }}</span>
              <button @click="$router.push('/googlemap')" class="location-button">위치수정</button>
            </div>
          </div>

          <label for="editInstagram">인스타그램 아이디</label>
          <input type="text" id="editInstagram" v-model="editForm.instagram" />

          <label for="editKakaotalk">상담 링크</label>
          <input type="text" id="editKakaotalk" v-model="editForm.kakaotalk" />

          <label for="editIntroduction">소개</label>
          <textarea id="editIntroduction" v-model="editForm.introduction"></textarea>

          <label for="editProfileImage">프로필 이미지</label>
          <input type="file" id="editProfileImage" @change="handleProfileImageUpload" />

          <label>장르</label>
          <div class="genre-buttons">
            <button
              v-for="genre in genres"
              :key="genre"
              :class="{'active': editForm.genres && editForm.genres.includes(genre)}"
              @click="toggleEditGenre(genre)"
              class="genre-button"
            >
              {{ genre }}
            </button>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="saveProfile" class="save-button">저장</button>
          <button @click="closeEditModal" class="cancel-button">취소</button>
        </div>
      </div>
    </div>

    <!-- 디자인 업로드 모달 -->
    <div v-if="isDesignModalOpen" class="modal-overlay">
      <div class="modal">
        <h3>디자인 업로드</h3>
        <div class="upload-form">
          <label for="designFileInput">이미지 선택</label>
          <input 
            type="file" 
            id="designFileInput"
            @change="handleDesignFileUpload"
            accept="image/*"
          />

          <label for="designDescription">디자인 소개</label>
          <input type="text" id="designDescription" v-model="designDescription" />

          <label>장르</label>
          <div class="genre-buttons">
            <button
              v-for="genre in genres"
              :key="genre"
              :class="{'active': selectedDesignGenres.includes(genre)}"
              @click="toggleDesignGenre(genre)"
              class="genre-button"
            >
              {{ genre }}
            </button>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="submitDesign" class="save-button">업로드</button>
          <button @click="closeDesignModal" class="cancel-button">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, collection, addDoc, deleteDoc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from '../firebaseConfig';
import { getStorage, ref as storageRef } from 'firebase/storage';


export default {
  name: 'MyPage',
  data() {
    return {
      user: {
        profileImageUrl: null,
        nickname: '',
        email: '',
        phone: '',
        location: {
          address: '',
          coordinates: {
            lat: 0,
            lng: 0
          }
        },
        hourcost: 0,
        mincost: 0,
        career: 0,
        shopName: '',
        introduction: '',
        instagram: '',
        kakaotalk: '',
        genres: [],
        userType: '',
        hasRequestedApproval: false,
      },
      editForm: {
        nickname: '',
        phone: '',
        hourcost: 0,
        mincost: 0,
        career: 0,
        shopName: '',
        introduction: '',
        instagram: '',
        kakaotalk: '',
        representativeImage: null,
        genres: []
      },
      portfolioData: [], // Firestore에서 불러온 포트폴리오 데이터
      activeSection: 'portfolio',
      previewImages: [],
      portfolioDescription: '',
      portfolioPrice: '',
      portfolioDuration: '',
      selectedGenres: [],
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화'],
      isEditModalOpen: false,
      currentUser: null, // 현재 로그인한 사용
      displayPrice: '', // 화면에 표시될 금액 (콤마 포함)
      unreadCount: 0,
      isUploadModalOpen: false,
      designData: [], // 디자인 데이터 배열 
      isDesignModalOpen: false,
      designDescription: '',
      designPrice: '',
      displayDesignPrice: '',
      selectedDesignGenres: [],
    };
  },
  created() {
    // 인증 상태 감시자 추가
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.currentUser = user;
        this.loadUserInfo();
        this.loadUnreadCount();
      } else {
        // 로그인되 않은 경우 로그인 페이지로 리다이렉트
        this.$router.push('/login');
      }
    });
  },
  mounted() {
    window.addEventListener('popstate', () => {
      if (this.isEditModalOpen) this.closeEditModal();
    });
  },
  methods: {
    async loadUserInfo() {
      try {
        if (!this.currentUser) return;  // currentUser가 없으면 함수 종료

        const userDoc = await getDoc(doc(db, "artists", this.currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // location 객체가 없나 address가 없는 경우 기본값 설정
          if (!userData.location || !userData.location.address) {
            userData.location = {
              address: '',
              coordinates: {
                lat: 0,
                lng: 0
              }
            };
          }
          this.user = userData;
          // 포트폴리오 이터 불러오기
          await this.loadPortfolios();
        } else {
          console.error("타투이스트 정보가 존재하지 않습니다.");
          // 기본 타투이스트 정보 설정
          this.user = {
            profileImageUrl: null,
            nickname: this.currentUser.email?.split('@')[0] || '타투이스트',
            email: this.currentUser.email || '',
            phone: '',
            location: {
              address: '',
              coordinates: {
                lat: 0,
                lng: 0
              }
            },
            hourcost: 0,
            mincost: 0,
            career: 0,
            shopName: '',
            introduction: '',
            instagram: '',
            kakaotalk: '',
            genres: [],
            userType: '',
            hasRequestedApproval: false,
          };
        }
      } catch (error) {
        console.error('사용자 정보 로드 실패:', error);
      }
    },
    async loadPortfolios() {
      try {
        const portfoliosCollection = collection(db, "artists", this.currentUser.uid, "portfolios");
        const portfolioSnapshot = await getDocs(portfoliosCollection);
        this.portfolioData = portfolioSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Failed to load portfolios:', error);
      }
    },
    showPortfolio() {
      this.activeSection = 'portfolio';
    },
    showUpload() {
      this.activeSection = 'upload';
    },
    handleFileUpload(event) {
      const file = event.target.files[0]; // 단일 파일만 가져오기
      if (file) {
        this.previewImages = [URL.createObjectURL(file)]; // 단일 이미지 미리보기
      }
    },
    triggerFileInput() {
      document.getElementById('fileInput').click();
    },
    toggleGenre(genre) {
      const index = this.selectedGenres.indexOf(genre);
      if (index > -1) {
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(genre);
      }
    },
    toggleEditGenre(genre) {
      if (!this.editForm.genres) {
        this.editForm.genres = [];
      }
      const index = this.editForm.genres.indexOf(genre);
      if (index > -1) {
        this.editForm.genres.splice(index, 1);
      } else {
        this.editForm.genres.push(genre);
      }
    },
    formatPrice(event) {
      // 숫자  콤마만 허용
      let value = event.target.value.replace(/[^\d]/g, '');
      
      // 숫자를 천 단위로 콤마 추가
      if (value) {
        this.displayPrice = Number(value).toLocaleString('ko-KR');
        // 실제 저장될 값은 숫자형태로 유지
        this.portfolioPrice = Number(value);
      } else {
        this.displayPrice = '';
        this.portfolioPrice = '';
      }
    },

    validatePrice() {
      if (this.displayPrice) {
        // 최소값 체크 (예: 1000원 미만 입력 시)
        if (this.portfolioPrice < 1000) {
          alert('최소 1,000원 이상 입력해주세요.');
          this.displayPrice = '';
          this.portfolioPrice = '';
        }
      }
    },

    async submitPortfolio() {
      if (!this.portfolioDescription || !this.portfolioDuration || this.selectedGenres.length === 0 || this.previewImages.length === 0) {
        alert('모든 필드를 채워주세요.');
        return;
      }

      try {
        const fileInput = document.getElementById('fileInput');
        const file = fileInput.files[0];
        if (!file) {
          alert('이미지를 선택해주세요.');
          return;
        }

        const compressedFile = await this.compressImage(file);
        const storageRef = ref(storage, `portfolios/${this.currentUser.uid}/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, compressedFile);
        const url = await getDownloadURL(storageRef);

        const artistDoc = await getDoc(doc(db, "artists", this.currentUser.uid));
        const artistData = artistDoc.data();

        const portfolioDoc = {
          images: [url],
          description: this.portfolioDescription,
          price: Number(this.portfolioPrice),
          duration: Number(this.portfolioDuration),
          genres: this.selectedGenres,
          createdAt: new Date(),
          artistId: this.currentUser.uid,
          artistName: artistData?.nickname || '이름 없음',
          artistImage: artistData?.profileImageUrl || 'default-profile.jpg',
          shopName: artistData?.shopName || '',
          location: artistData?.location || {
            address: '',
            coordinates: { lat: 0, lng: 0 }
          },
          instagram: artistData?.instagram || '',
          kakaotalk: artistData?.kakaotalk || '',
          hourcost: artistData?.hourcost || 0,
          mincost: artistData?.mincost || 0,
          career: artistData?.career || 0
        };

        const artistPortfolioRef = await addDoc(
          collection(db, "artists", this.currentUser.uid, "portfolios"), 
          portfolioDoc
        );

        await setDoc(doc(db, "portfolios", artistPortfolioRef.id), portfolioDoc);
        await this.loadPortfolios();

        this.previewImages = [];
        this.portfolioDescription = '';
        this.portfolioPrice = '';
        this.portfolioDuration = '';
        this.selectedGenres = [];
        fileInput.value = '';

        alert('포트폴리오가 성공적으로 업로드되었습니다.');
        this.closeUploadModal();
      } catch (error) {
        console.error('Failed to upload portfolio:', error);
        alert('포트폴리오 업로드 중 오류가 발생했습니다.');
      }
    },
    async deletePortfolio(portfolioId) {
      const confirmDelete = confirm('이 포트폴리오를 삭제하시겠습니까?');
      if (!confirmDelete) return;

      try {
        // 삭제할 포트폴리오 데이터 가져오기
        const portfolioToDelete = this.portfolioData.find(portfolio => portfolio.id === portfolioId);
        
        if (!portfolioToDelete) {
          throw new Error('포트리오를 찾을 수 없습니다.');
        }

        // Storage에서 이미지 삭제
        const deleteImagePromises = portfolioToDelete.images.map(async (imageUrl) => {
          try {
            // Storage 참조 생성
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('이미지 삭제 실패:', error);
            // 개별 이미지 삭제 실패 시에도 계속 진행
          }
        });

        // 모든 이미지 삭제 작업 완료 대기
        await Promise.all(deleteImagePromises);

        // Firestore에서 포트폴리오 문서 삭제
        await Promise.all([
          deleteDoc(doc(db, "artists", this.currentUser.uid, "portfolios", portfolioId)),
          deleteDoc(doc(db, "portfolios", portfolioId))
        ]);

        // 로컬 상태 업데이트
        this.portfolioData = this.portfolioData.filter(portfolio => portfolio.id !== portfolioId);
        
        alert('포트폴리오가 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('포트폴리오 삭제 실패:', error);
        alert('포트폴리오 삭제 중 오류가 발생했습니다.');
      }
    },
    openEditModal() {
      this.editForm = { ...this.user };
      this.isEditModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    closeEditModal() {
      this.isEditModalOpen = false;
      this.editForm = {
        nickname: '',
        phone: '',
        hourcost: 0,
        mincost: 0,
        career: 0,
        shopName: '',
        introduction: '',
        instagram: '',
        kakaotalk: '',
        representativeImage: null,
        genres: []
      };
    },
    formatPhoneNumber(event) {
      let value = event.target.value.replace(/[^0-9]/g, '');

      if (value.length <= 3) {
        this.editForm.phone = value;
      } else if (value.length <= 7) {
        this.editForm.phone = value.slice(0, 3) + '-' + value.slice(3, 7);
      } else {
        this.editForm.phone = value.slice(0, 3) + '-' + value.slice(3, 7) + '-' + value.slice(7, 11);
      }
    },
    async updateProfileImage(newImageUrl) {
      const storage = getStorage();
      const userDoc = await getDoc(doc(db, 'artists', this.currentUser.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const oldImageUrl = userData.profileImageUrl;
        let oldImagePath = ''; // 변수 선언 위치 변경
        
        // 기존 이미지가 있고, storage 경로를 포함하고 있다면 삭제
        if (oldImageUrl && oldImageUrl.includes('firebasestorage.googleapis.com')) {
          // URL에서 전체 경로 추출
          const pathStartIndex = oldImageUrl.indexOf('/o/') + 3;
          const pathEndIndex = oldImageUrl.indexOf('?');
          oldImagePath = decodeURIComponent(oldImageUrl.substring(pathStartIndex, pathEndIndex));
          const oldImageRef = storageRef(storage, oldImagePath);
          
          await deleteObject(oldImageRef);
        }

        // 새 이미지 URL로 프로필 업데이트
        await updateDoc(doc(db, 'artists', this.currentUser.uid), {
          profileImageUrl: newImageUrl
        });

        // 로컬 상태 업데이트
        this.user.profileImageUrl = newImageUrl;
      }
    },
    async handleProfileImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        const compressedFile = await this.compressImage(file);
        const storage = getStorage();
        const newImageRef = storageRef(storage, `profile-images/${this.currentUser.uid}/${Date.now()}_${file.name}`);
        
        const uploadResult = await uploadBytes(newImageRef, compressedFile);
        const newImageUrl = await getDownloadURL(uploadResult.ref);
        
        await this.updateProfileImage(newImageUrl);
      } catch (error) {
        console.error('프로필 이미지 업로드 실패:', error);
        alert('이미지 업로드 중 오류가 발생했습니다.');
      }
    },
    async saveProfile() {
      try {
        let profileImageUrl = this.user.profileImageUrl;

        // 대표 이미지가 변경된 경우 업로드
        if (this.editForm.representativeImage) {
          const storageRef = ref(storage, `profile-images/${this.currentUser.uid}/${Date.now()}_${this.editForm.representativeImage.name}`);
          await uploadBytes(storageRef, this.editForm.representativeImage);
          profileImageUrl = await getDownloadURL(storageRef);
        }

        // 빈 값이 아닌 필드만 업데이트 객체에 포함
        const updateData = {};
        
        if (this.editForm.nickname) updateData.nickname = this.editForm.nickname;
        if (this.editForm.phone) updateData.phone = this.editForm.phone;
        if (this.editForm.hourcost !== undefined) updateData.hourcost = this.editForm.hourcost;
        if (this.editForm.mincost !== undefined) updateData.mincost = this.editForm.mincost;
        if (this.editForm.career !== undefined) updateData.career = this.editForm.career;
        if (this.editForm.shopName) updateData.shopName = this.editForm.shopName;
        if (this.editForm.introduction) updateData.introduction = this.editForm.introduction;
        updateData.instagram = this.editForm.instagram || '';
        updateData.kakaotalk = this.editForm.kakaotalk || '';
        if (profileImageUrl) updateData.profileImageUrl = profileImageUrl;
        if (this.editForm.genres) updateData.genres = this.editForm.genres;
        if (this.editForm.location) updateData.location = this.editForm.location;

        // Firestore에 타투이스트 정보 업데이트 (artists 컬렉션)
        await setDoc(doc(db, "artists", this.currentUser.uid), updateData, { merge: true });

        // 사용자 정보 갱신 (기존 데이터 유지하면서 업데이트된 필드만 변경)
        this.user = { ...this.user, ...updateData };
        
        this.closeEditModal();
        alert('프로필이 성공적으로 업데이트되었습니다.');
      } catch (error) {
        console.error('Failed to update user info:', error);
        alert('프로필 업데이트 중 오류가 발생했습니다.');
      }
    },
    goToChat() {
      this.$router.push('/chats');
    },
    GoToProfile() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        // 현재 로그인된 아티스트의 uid를 사용하여 프로필 페이지로 이동
        this.$router.push(`/profile/${user.uid}`);
      } else {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/login');
      }
    },
    async loadUnreadCount() {
      if (!this.currentUser) return;
      
      try {
        const chatsRef = collection(db, 'chats');
        const q = query(
          chatsRef,
          where('participants', 'array-contains', this.currentUser.uid)
        );
        
        const querySnapshot = await getDocs(q);
        let count = 0;
        
        querySnapshot.forEach(doc => {
          const chatData = doc.data();
          if (chatData.unreadCount && chatData.unreadCount[this.currentUser.uid]) {
            count += chatData.unreadCount[this.currentUser.uid];
          }
        });
        
        this.unreadCount = count;
      } catch (error) {
        console.error('Failed to load unread count:', error);
      }
    },
    openUploadModal() {
      this.isUploadModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    closeUploadModal() {
      this.isUploadModalOpen = false;
      this.previewImages = [];
      this.portfolioDescription = '';
      this.portfolioPrice = '';
      this.portfolioDuration = '';
      this.selectedGenres = [];
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.value = '';
      }
    },
    showDesign() {
      this.activeSection = 'design';
      this.loadDesigns();
    },
    async loadDesigns() {
      try {
        if (!this.currentUser) return;

        const designsCollection = collection(db, "artists", this.currentUser.uid, "designs");
        const designSnapshot = await getDocs(designsCollection);
        
        this.designData = designSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            description: data.description || '',
            price: data.price || 0,
            duration: data.duration || 0,
            genres: data.genres || [],
            // imageUrl을 images 배열로 변환
            images: data.images || []
          };
        });

        //console.log('Processed designData:', this.designData);
      } catch (error) {
        console.error('Failed to load designs:', error);
      }
    },
    openDesignModal() {
      this.isDesignModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    closeDesignModal() {
      this.isDesignModalOpen = false;
      this.designDescription = '';
      this.designPrice = '';
      //this.designDuration = '';
      this.selectedDesignGenres = [];
      this.previewDesignImages = [];
      const fileInput = document.getElementById('designFileInput');
      if (fileInput) {
        fileInput.value = '';
      }
    },
    formatDesignPrice(event) {
      // 숫자  콤마만 허용
      let value = event.target.value.replace(/[^\d]/g, '');
      
      // 숫자를 천 단위로 콤마 추가
      if (value) {
        this.displayDesignPrice = Number(value).toLocaleString('ko-KR');
        // 실제 저장될 값은 숫자형태로 유지
        this.designPrice = Number(value);
      } else {
        this.displayDesignPrice = '';
        this.designPrice = '';
      }
    },

    validateDesignPrice() {
      if (this.displayDesignPrice) {
        // 최소값 체크 (예: 1000원 미만 입력 시)
        if (this.designPrice < 1000) {
          alert('최소 1,000원 이상 입력해주세요.');
          this.displayDesignPrice = '';
          this.designPrice = '';
        }
      }
    },

    async submitDesign() {
      if (!this.designDescription || this.selectedDesignGenres.length === 0) {
        alert('모든 필드를 채워주세요.');
        return;
      }

      try {
        const fileInput = document.getElementById('designFileInput');
        const file = fileInput.files[0];
        if (!file) {
          alert('이미지를 선택해주세요.');
          return;
        }

        const compressedFile = await this.compressImage(file);
        const storageRef = ref(storage, `designs/${this.currentUser.uid}/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, compressedFile);
        const url = await getDownloadURL(storageRef);

        const artistDoc = await getDoc(doc(db, "artists", this.currentUser.uid));
        const artistData = artistDoc.data();

        const designDoc = {
          images: [url],
          description: this.designDescription,
          price: Number(this.designPrice),
          genres: this.selectedDesignGenres,
          createdAt: new Date(),
          artistId: this.currentUser.uid,
          artistName: artistData?.nickname || '이름 없음',
          artistImage: artistData?.profileImageUrl || 'default-profile.jpg',
          shopName: artistData?.shopName || '',
          location: artistData?.location || {
            address: '',
            coordinates: { lat: 0, lng: 0 }
          },
          hourcost: artistData?.hourcost || 0,
          mincost: artistData?.mincost || 0,
          career: artistData?.career || 0
        };

        const artistDesignRef = await addDoc(
          collection(db, "artists", this.currentUser.uid, "designs"), 
          designDoc
        );

        await setDoc(doc(db, "designs", artistDesignRef.id), designDoc);
        await this.loadDesigns();

        fileInput.value = '';
        this.closeDesignModal();
        alert('디자인이 성공적으로 업로드되었습니다.');
      } catch (error) {
        console.error('Failed to upload design:', error);
        alert('디자인 업로드 중 오류가 발생했습니다.');
      }
    },
    async deleteDesign(designId) {
      const confirmDelete = confirm('이 디자인을 삭제하시겠습니까?');
      if (!confirmDelete) return;

      try {
        // 삭제할 디자인 데이터 가져오기
        const designToDelete = this.designData.find(design => design.id === designId);
        
        if (!designToDelete) {
          throw new Error('디자인을 찾을 수 없습니다.');
        }

        // Storage에서 이미지 삭제
        const deleteImagePromises = designToDelete.images.map(async (imageUrl) => {
          try {
            // Storage 참조 생성
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('이미지 삭제 실패:', error);
            // 개별 이미지 삭제 실패 시에도 계속 진행
          }
        });

        // 모든 이미지 삭제 작업 완료 대기
        await Promise.all(deleteImagePromises);

        // Firestore에서 디자인 문서 삭제
        await Promise.all([
          deleteDoc(doc(db, "artists", this.currentUser.uid, "designs", designId)),
          deleteDoc(doc(db, "designs", designId))
        ]);

        // 로컬 상태 업데이트
        this.designData = this.designData.filter(design => design.id !== designId);
        
        alert('디자인이 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('디자인 삭제 실패:', error);
        alert('디자인 삭제 중 오류가 발생했습니다.');
      }
    },
    toggleDesignGenre(genre) {
      const index = this.selectedDesignGenres.indexOf(genre);
      if (index > -1) {
        this.selectedDesignGenres.splice(index, 1);
      } else {
        this.selectedDesignGenres.push(genre);
      }
    },
    // 파일 업로드 핸들러 추가
    handleDesignFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        // 필요한 경우 여기에 미리보기 로직 추가
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewDesignImages = [e.target.result];
        };
        reader.readAsDataURL(file);
      }
    },
    async requestApproval() {
      try {
        // 필수 필드 검증
        const missingFields = this.getMissingFields();
        if (missingFields.length > 0) {
          alert(`다음 정보를 입력해주세요:\n- ${missingFields.join('\n- ')}`);
          return;
        }

        // 포트폴리오 확인
        const portfoliosRef = collection(db, "artists", this.currentUser.uid, "portfolios");
        const portfolioSnapshot = await getDocs(portfoliosRef);
        
        if (portfolioSnapshot.empty) {
          alert('최소 1개 이상의 포트폴리오를 등록해주세요.');
          return;
        }

        // 승인 요청 데이터 생성
        const approvalRequest = {
          artistId: this.currentUser.uid,
          artistName: this.user.nickname,
          phone: this.user.phone,
          location: this.user.location,
          hourcost: this.user.hourcost,
          mincost: this.user.mincost,
          career: this.user.career,
          genres: this.user.genres,
          introduction: this.user.introduction,
          portfolioCount: portfolioSnapshot.size,
          status: 'pending', // pending, approved, rejected
          requestDate: new Date(),
          profileImageUrl: this.user.profileImageUrl || null
        };

        // 승인 요청 저장
        await addDoc(collection(db, "approvalRequests"), approvalRequest);

        // 아티스트 문서 업데이트
        await updateDoc(doc(db, "artists", this.currentUser.uid), {
          hasRequestedApproval: true
        });

        // 로컬 상태 업데이트
        this.user.hasRequestedApproval = true;

        alert('승인 요청이 성공적으로 제출되었습니다.');
      } catch (error) {
        console.error('승인 요청 실패:', error);
        alert('승인 요청 중 오류가 발생했습니다.');
      }
    },

    getMissingFields() {
      const missingFields = [];
      if (!this.user.phone) missingFields.push('전화번호');
      if (!this.user.location?.address) missingFields.push('위치');
      if (!this.user.hourcost) missingFields.push('시간당 비용');
      if (!this.user.mincost) missingFields.push('최소 비용');
      if (!this.user.career) missingFields.push('경력');
      if (!this.user.genres || this.user.genres.length === 0) missingFields.push('장르');
      if (!this.user.introduction) missingFields.push('소개');
      return missingFields;
    },
    async compressImage(file) {
      try {
        const maxWidth = 1920;
        const maxHeight = 1920;
        const maxSizeMB = 1;

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              
              if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              }
              if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }

              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);

              let quality = 0.7;

              const compress = () => {
                canvas.toBlob((blob) => {
                  if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                    quality -= 0.1;
                    compress();
                  } else {
                    resolve(blob);
                  }
                }, 'image/jpeg', quality);
              };

              compress();
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        });
      } catch (error) {
        console.error('이미지 압축 실패:', error);
        return file;
      }
    }
  },
};
</script>

<style scoped>
.my-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.profile-button {
  padding: 8px 16px;
  background-color: #17AE60;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button{
  padding: 8px 16px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background-color: #636e72;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-info h2{
  margin: 0;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-image {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  color: #666;
}

.profile-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.info-item {
  display: flex;
  gap: 8px;
  align-items: center;
}

.label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  min-width: 60px;
}

.value {
  font-size: 16px;
  color: #2d3436;
}

.introduction-section {
  margin-top: 15px;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.introduction {
  margin: 8px 0 0 0;
  line-height: 1.6;
  color: #2d3436;
  white-space: pre-wrap;
}


.chat-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.location-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 20px;
}

.location-text {
  color: #333;
  flex: 1;
}

.location-button {
  padding: 8px 16px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 10px;
}

.location-button:hover {
  background-color: #636e72;
}

/* 표 형식 메뉴 스타일 */
.bottom-menu {
  width: 100%;
  z-index: 1000;
  margin: 20px 0; /* 상하 여백 추가 */
  border-radius: 10px;
}

.menu-table {
  width: 100%;
  border-collapse: collapse;
}

.menu-table tr {
  display: flex;
  justify-content: space-around;
}

.menu-item {
  flex: 1; /* 메뉴 아이템이 동일한 너비를 가지도록 설정 */
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
  color: #2d3436;
}

.menu-item:hover {
  background-color: #f5f6fa;
}

.menu-item span {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

/* 채팅 알림 배지 스타일 */
.unread-badge {
  position: absolute;
  top: 5px;
  right: 25%;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-header {
  background-color: #fff;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-header h3 {
  margin: 10px 0;
  font-size: 24px;
}

.upload-button {
  padding: 10px 20px;
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #636e72;
}

.portfolio-section, .upload-section {
  margin-top: 10px;
}

.portfolio-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
}

.portfolio-card {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
}

.delete-button {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 8px 16px;
  background-color: #d63031;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #e17055;
}

.portfolio-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.portfolio-image:hover {
  transform: scale(1.02);
}

.portfolio-details {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  font-size: 14px;
}

.portfolio-details th,
.portfolio-details td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.portfolio-details th {
  width: 30%;
  color: #666;
  font-weight: 500;
  background-color: #f8f9fa;
  min-width: 60px;
}

.portfolio-details td {
  color: #2d3436;
}

.file-upload {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.file-upload-button {
  padding: 10px 20px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-upload-button:hover {
  background-color: #636e72;
}

.preview-section {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.preview-image-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.upload-form label {
  font-size: 15px;
  font-weight: 600;
  color: #2d3436;
  display: block;
}

.upload-form input {
  margin-bottom: 10px;
}

.upload-form input[type="file"] {
  width: 90%;
  padding: 16px;
  background-color: #f8f9fa;
  border: 2px dashed #e9ecef;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-form input[type="file"]:hover {
  border-color: #2d3436;
  background-color: #f1f3f5;
}

.upload-form input[type="text"],
.upload-form input[type="number"] {
  width: 90%;
  padding: 14px 18px;
  border: 2px solid #e9ecef;
  border-radius: 14px;
  font-size: 15px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.upload-form input:focus {
  border-color: #2d3436;
  outline: none;
  box-shadow: 0 0 0 4px rgba(45, 52, 54, 0.1);
  transform: translateY(-2px);
}

.preview-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 16px;
  margin-top: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.preview-image:hover {
  transform: scale(1.02);
}

.genre-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 12px;
}

.genre-button {
  padding: 8px 16px;
  border-radius: 12px;
  border: 2px solid #e9ecef;
  background-color: white;
  color: #2d3436;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.genre-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.genre-button.active {
  background-color: #2d3436;
  color: white;
  border-color: #2d3436;
}

.submit-button {
  padding: 10px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #636e72;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

.modal {
  background-color: #ffffff;
  border-radius: 20px;
  width: 80%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  margin: auto;
  max-height: 85vh;
  overflow-y: auto;
  padding: 32px;
  animation: slideUp 0.3s ease;
}

.edit-form {
  display: flex;
  flex-direction: column;
}

.edit-form label {
  font-size: 14px;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 8px;
  display: block;
}

.edit-form input, 
.edit-form textarea {
  width: 90%;
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 15px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}

.edit-form input:focus, 
.edit-form textarea:focus {
  border-color: #2d3436;
  outline: none;
  box-shadow: 0 0 0 4px rgba(45, 52, 54, 0.1);
}

.edit-form textarea {
  min-height: 50px;
  resize: vertical;
}

.genre-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.genre-button {
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid #e9ecef;
  background-color: white;
  color: #2d3436;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.genre-button.active {
  background-color: #2d3436;
  color: white;
  border-color: #2d3436;
}

.location-display {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  background-color: #f8f9fa;
}

.location-button {
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #2d3436;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.modal-buttons {
  display: flex;
  gap: 12px;
  margin-top: 32px;
}

.save-button, 
.cancel-button {
  flex: 1;
  padding: 14px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.2s ease;
}

.save-button {
  background-color: #2d3436;
  color: white;
}

.save-button:hover {
  background-color: #1e2527;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #e9ecef;
  color: #2d3436;
}

.cancel-button:hover {
  background-color: #dee2e6;
  transform: translateY(-2px);
}

/* 애니메이션 효과 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* 스크롤바 스타일 업데이트 */
.modal::-webkit-scrollbar {
  width: 6px;
}

.modal::-webkit-scrollbar-track {
  background: transparent;
}

.modal::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 3px;
}

/* 반형 디자인 */
@media (max-width: 768px) {
  .profile-details {
    width: 100%;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .portfolio-image {
    width: 100%;
    height: auto;
  }

  .menu-item span {
    font-size: 12px;
  }
}

@media (max-width: 480px) {

  .menu-item span {
    font-size: 14px;
  }

  .unread-badge {
    top: 3px;
    right: 20%;
    font-size: 8px;
    min-width: 16px;
    height: 16px;
  }
}

.approval-button {
  padding: 8px 16px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 10px;
}

.approval-button:hover {
  background-color: #2980b9;
}
</style>
