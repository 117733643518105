<template>
  <div class="main-page">
    <section class="hero-section">
      <div class="hero-content">
        <h1>Find Your Perfect Tattoo</h1>
        <p>당신에게 딱 맞는 타투를 찾아보세요</p>
        <div class="search-container">
          <button @click="$router.push('/tattoo-choice')" class="search-button">
            <span class="material-icons">place</span>
            내 주변 검색
          </button>
        </div>
      </div>
    </section>

    <!-- 퀵 메뉴 섹션 추가 -->
    <section class="quick-menu">
      <div class="menu-item" @click="$router.push('/home')">
        <span class="material-icons">photo_library</span><br>
        <p>타투 찾기</p>
      </div>
      <div class="menu-item" @click="$router.push('/find-design')">
        <span class="material-icons">palette</span><br>
        <p>디자인 찾기</p>
      </div>
      <div class="menu-item" @click="$router.push('/find-artist')">
        <span class="material-icons">person_search</span><br>
        <p>아티스트 찾기</p>
      </div>
      <div class="menu-item" @click="handleTattooRequestClick">
        <span class="material-icons">description</span><br>
        <p>타투 의뢰</p>
      </div>
    </section>

    <!-- 배너 섹션 개선 -->
    <section v-if="banners.length > 0" class="banner-section">
      <div class="banner-container">
        <div class="banner-slider" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
          <div v-for="(banner, index) in banners" :key="index" class="banner-slide">
            <img :src="banner" :alt="`배너 ${index + 1}`" class="banner-image">
          </div>
        </div>
        <button class="banner-nav prev" @click="prevSlide" aria-label="이전 배너">
          <span class="material-icons">chevron_left</span>
        </button>
        <button class="banner-nav next" @click="nextSlide" aria-label="다음 배너">
          <span class="material-icons">chevron_right</span>
        </button>
      </div>
    </section>

    <!-- 아티스트 섹션 템플릿 수정 -->
    <section class="artists-section">
      <div class="section-header">
        <h2>아티스트</h2>
        <router-link to="/find-artist" class="view-all">
          더보기 <span class="material-icons">arrow_forward</span>
        </router-link>
      </div>
      <div class="artists-slider-container">
        <div class="artists-slider" ref="artistSlider" 
             @touchstart="handleArtistTouchStart" 
             @touchmove="handleArtistTouchMove"
             @touchend="handleArtistTouchEnd">
          <div v-for="artist in randomArtists" :key="artist.id" 
               class="artist-card" 
               @click="artist.id ? goToProfile(artist.id) : $router.push('/find-artist')">
            <template v-if="!artist.id">
              <div class="view-more-content">
                <span class="material-icons">add_circle</span>
                <p>더 많은 아티스트 보기</p>
              </div>
            </template>
            <template v-else>
              <div class="artist-image-container">
                <img :src="artist.profileImage" :alt="artist.name">
              </div>
              <div class="artist-info">
                <h3>{{ artist.name }}</h3>
                <p class="artist-location">
                  <span class="material-icons">place</span>
                  {{ artist.location }}
                </p>
                <div class="artist-genres">
                  <span v-for="genre in artist.genres.slice(0,2)" 
                        :key="genre" 
                        class="genre-tag">
                    {{ genre }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- 타투 섹션 추가 -->
    <section class="portfolio-section">
      <div class="section-header">
        <h2>타투</h2>
        <router-link to="/Home" class="view-all">
          더보기 <span class="material-icons">arrow_forward</span>
        </router-link>
      </div>
      <div class="portfolio-slider-container">
        <div class="portfolio-slider" ref="portfolioSlider" 
             @touchstart="handlePortfolioTouchStart" 
             @touchmove="handlePortfolioTouchMove"
             @touchend="handlePortfolioTouchEnd">
          <div v-for="portfolio in randomPortfolios" :key="portfolio.id" 
               class="portfolio-card" 
               @click="portfolio.id ? goToPortfolio(portfolio.id) : $router.push('/Home')">
            <template v-if="!portfolio.id">
              <div class="view-more-content">
                <span class="material-icons">add_circle</span>
                <p>더 많은 타투 보기</p>
              </div>
            </template>
            <template v-else>
              <div class="portfolio-image-container">
                <img :src="portfolio.image" :alt="portfolio.description">
              </div>
              <div class="portfolio-info">
                <p class="portfolio-description">{{ portfolio.description }}</p>
                <div class="portfolio-genres">
                  <span v-for="genre in portfolio.genres.slice(0,2)" 
                        :key="genre" 
                        class="genre-tag">
                    {{ genre }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- 디자인 섹션 추가 -->
    <section class="design-section">
      <div class="section-header">
        <h2>디자인</h2>
        <router-link to="/find-design" class="view-all">
          더보기 <span class="material-icons">arrow_forward</span>
        </router-link>
      </div>
      <div class="design-slider-container">
        <div class="design-slider" ref="designSlider" 
             @touchstart="handleDesignTouchStart" 
             @touchmove="handleDesignTouchMove"
             @touchend="handleDesignTouchEnd">
          <div v-for="design in randomDesigns" :key="design.id" 
               class="design-card" 
               @click="design.id ? goToDesign(design.id) : $router.push('/find-design')">
            <template v-if="!design.id">
              <div class="view-more-content">
                <span class="material-icons">add_circle</span>
                <p>더 많은 디자인 보기</p>
              </div>
            </template>
            <template v-else>
              <div class="design-image-container">
                <img :src="design.image" :alt="design.description">
              </div>
              <div class="design-info">
                <p class="design-description">{{ design.description }}</p>
                <div class="design-genres">
                  <span v-for="genre in design.genres.slice(0,2)" 
                        :key="genre" 
                        class="genre-tag">
                    {{ genre }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { db } from '@/firebaseConfig'
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import Footer from './Footer.vue'

export default {
  name: 'MainPage',
  components: {
    Footer
  },
  data() {
    return {
      currentSlide: 0,
      banners: [],
      randomArtists: [],
      randomPortfolios: [],
      randomDesigns: [], // 디자인 데이터 추가
      isDragging: false,
      startPos: 0,
      currentTranslate: 0,
      prevTranslate: 0,
      currentIndex: 0,
      currentPortfolioTranslate: 0,
      currentPortfolioIndex: 0,
      currentDesignTranslate: 0, // 디자인 슬라이더 위치
      currentDesignIndex: 0, // 디자인 인덱스
      touchStartX: 0,
      touchEndX: 0,
      sliderScrollLeft: 0,
      isTouching: false, // 터치 중인지 여부
      isTouchingArtist: false,
      isTouchingPortfolio: false,
      isTouchingDesign: false,
      artistSliderScrollLeft: 0,
      portfolioSliderScrollLeft: 0,
      designSliderScrollLeft: 0,
      artistStartPos: 0,
      artistCurrentTranslate: 0,
      artistPrevTranslate: 0,
      artistIsDragging: false,
      artistCurrentIndex: 0,

      portfolioStartPos: 0,
      portfolioCurrentTranslate: 0,
      portfolioPrevTranslate: 0,
      portfolioIsDragging: false,
      portfolioCurrentIndex: 0,

      designStartPos: 0,
      designCurrentTranslate: 0,
      designPrevTranslate: 0,
      designIsDragging: false,
      designCurrentIndex: 0,
    }
  },
  mounted() {
    this.fetchBanners();
    this.startSlideShow();
  },
  beforeUnmount() {
    this.stopSlideShow();
  },
  async created() {
    await this.fetchRandomArtists();
    await this.fetchRandomPortfolios();
    await this.fetchRandomDesigns(); // 디자인 데이터 가져오기
  },
  methods: {
    async fetchBanners() {
      try {
        const storage = getStorage();
        const bannerRef = ref(storage, 'banner');
        
        const result = await listAll(bannerRef);
        
        const urls = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return url;
          })
        );
        
        this.banners = urls.sort((a, b) => {
          const nameA = a.split('banner')[1];
          const nameB = b.split('banner')[1];
          return nameA.localeCompare(nameB);
        });

        if (this.banners.length > 0) {
          this.startSlideShow();
        }
      } catch (error) {
        console.error('배너 이미지 로드 실패:', error);
      }
    },

    async handleTattooRequestClick() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        // 로그인하지 않은 사용자는 로그인 페이지로 리다이렉트
        if (!user) {
          this.$router.push('/login');
          return;
        }

        // artists 컬렉션에서 현재 사용자가 타투이스트인지 확인
        const artistDoc = await getDoc(doc(db, 'artists', user.uid));
        if (artistDoc.exists()) {
          // 타투이스트인 경우 타투이스트용 의뢰 페이지로 이동
          this.$router.push('/tattoo-request-page');
          return;
        }

        // users 컬렉션에서 일반 사용자인지 확인
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          // 일반 사용자인 경우 사용자용 의뢰 페이지로 이동
          this.$router.push('/my-tattoo-requests');
          return;
        }

        // artists나 users 컬렉션 어디에도 사용자 정보가 없는 경우
        // 에러 로그를 남기고 로그인 페이지로 리다이렉트
        console.error('User not found in any collection');
        this.$router.push('/login');

      } catch (error) {
        // 사용자 타입 확인 중 오류 발생시 에러 로그를 남기고 로그인 페이지로 리다이렉트
        console.error('Error checking user type:', error);
        this.$router.push('/login');
      }
    },

    startSlideShow() {
      if (this.banners.length > 1) {
        this.slideInterval = setInterval(() => {
          this.currentSlide = (this.currentSlide + 1) % this.banners.length;
        }, 5000);
      }
    },
    stopSlideShow() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
      }
    },
    async fetchRandomArtists() {
      try {
        const artistsRef = db.collection('artists')
        const snapshot = await artistsRef.where('userType', '==', 'artist').get()
        const artists = []
        
        snapshot.forEach(doc => {
          const artistData = doc.data()
          artists.push({
            id: doc.id,
            profileImage: artistData.profileImageUrl || 'https://via.placeholder.com/100x100?text=No+Profile',
            name: artistData.nickname || '이름 없음',
            genres: artistData.genres || ['장르 정보 없음'],
            location: artistData.location?.address?.split(' ').slice(0, 2).join(' ') || '위치 정보 없음',
            userType: artistData.userType
          })
        })
        
        // 랜덤으로 3명 선택 후 "더보기" 카드 추가
        this.randomArtists = this.shuffleArray(artists).slice(0, 3)
        this.randomArtists.push({ id: null }) // "더보기" 카드 추가
      } catch (error) {
        console.error('Error fetching artists:', error)
      }
    },
    async fetchRandomPortfolios() {
      try {
        const portfoliosRef = db.collection('portfolios')
        const snapshot = await portfoliosRef.get()
        const portfolios = []
        
        for (const doc of snapshot.docs) {
          const portfolioData = doc.data()
          
          // 포트폴리오 작성자의 userType 확인
          const artistDoc = await db.collection('artists').doc(portfolioData.artistId).get()
          const artistData = artistDoc.data()
          
          if (artistData && artistData.userType === 'artist') { // artist인 경우만 추가
            portfolios.push({
              id: doc.id,
              image: portfolioData.images || 'https://via.placeholder.com/100x100?text=No+Image',
              description: portfolioData.description || '설명 없음',
              genres: portfolioData.genres || ['장르 정보 없음'],
              artistId: portfolioData.artistId
            })
          }
        }
        
        // 랜덤으로 3개 선택 후 "더보기" 카드 추가
        this.randomPortfolios = this.shuffleArray(portfolios).slice(0, 3)
        this.randomPortfolios.push({ id: null })
      } catch (error) {
        console.error('Error fetching portfolios:', error)
      }
    },
    async fetchRandomDesigns() {
      try {
        const designsRef = db.collection('designs')
        const snapshot = await designsRef.get()
        const designs = []
        
        for (const doc of snapshot.docs) {
          const designData = doc.data()
          
          // 디자인 작성자의 userType 확인
          const artistDoc = await db.collection('artists').doc(designData.artistId).get()
          const artistData = artistDoc.data()
          
          if (artistData && artistData.userType === 'artist') { // artist인 경우만 추가
            designs.push({
              id: doc.id,
              image: designData.images || 'https://via.placeholder.com/100x100?text=No+Image',
              description: designData.description || '설명 없음',
              genres: designData.genres || ['장르 정보 없음'],
              artistId: designData.artistId
            })
          }
        }
        
        // 랜덤으로 3개 선택 후 "더보기" 카드 추가
        this.randomDesigns = this.shuffleArray(designs).slice(0, 3)
        this.randomDesigns.push({ id: null })
      } catch (error) {
        console.error('Error fetching designs:', error)
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    prevArtist() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentTranslate = this.currentIndex * -56;
      }
    },
    nextArtist() {
      if (this.currentIndex < this.randomArtists.length - 2) {
        this.currentIndex++;
        this.currentTranslate = this.currentIndex * -56;
      }
    },
    prevPortfolio() {
      if (this.currentPortfolioIndex > 0) {
        this.currentPortfolioIndex--;
        this.currentPortfolioTranslate = this.currentPortfolioIndex * -56;
      }
    },
    nextPortfolio() {
      if (this.currentPortfolioIndex < this.randomPortfolios.length - 2) {
        this.currentPortfolioIndex++;
        this.currentPortfolioTranslate = this.currentPortfolioIndex * -56;
      }
    },
    prevDesign() {
      if (this.currentDesignIndex > 0) {
        this.currentDesignIndex--;
        this.currentDesignTranslate = this.currentDesignIndex * -56;
      }
    },
    nextDesign() {
      if (this.currentDesignIndex < this.randomDesigns.length - 2) {
        this.currentDesignIndex++;
        this.currentDesignTranslate = this.currentDesignIndex * -56;
      }
    },
    formatGenres(genres) {
      if (!genres || genres.length === 0) return '장르 정보 없음';
      const firstGenre = genres[0];
      const additionalCount = genres.length - 1;
      return additionalCount > 0 ? `${firstGenre} 외 ${additionalCount}개 장르` : firstGenre;
    },
    // 프로필 페이지로 이동하는 함수 추가
    goToProfile(artistId) {
      if (!artistId) return; // artistId가 없는 경우 처리
      this.$router.push(`/profile/${artistId}`);
    },

    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.banners.length - 1;
      }
      this.stopSlideShow();
      this.startSlideShow();
    },

    nextSlide() {
      if (this.currentSlide < this.banners.length - 1) {
        this.currentSlide++;
      } else {
        this.currentSlide = 0;
      }
      this.stopSlideShow();
      this.startSlideShow();
    },

    // 아티스트 섹션 터치 이벤트 핸들러
    handleArtistTouchStart(e) {
      this.artistIsDragging = true;
      this.artistStartPos = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      this.artistCurrentTranslate = this.artistPrevTranslate;
    },

    handleArtistTouchMove(e) {
      if (!this.artistIsDragging) return;
      const currentPosition = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      const diff = currentPosition - this.artistStartPos;
      this.artistCurrentTranslate = this.artistPrevTranslate + diff;
    },

    handleArtistTouchEnd() {
      this.artistIsDragging = false;
      const card = document.querySelector('.artist-card');
      if (!card) return;
      
      const cardWidth = card.offsetWidth + 20; // 20은 gap
      const movedBy = this.artistCurrentTranslate - this.artistPrevTranslate;
      
      if (Math.abs(movedBy) > cardWidth / 3) {
        if (movedBy < 0 && this.artistCurrentIndex < this.randomArtists.length - 1) {
          this.artistCurrentIndex++;
        } else if (movedBy > 0 && this.artistCurrentIndex > 0) {
          this.artistCurrentIndex--;
        }
      }
      
      this.artistCurrentTranslate = -(cardWidth * this.artistCurrentIndex);
      this.artistPrevTranslate = this.artistCurrentTranslate;
    },

    // 포트폴리오 섹션 터치 이벤트 핸들러
    handlePortfolioTouchStart(e) {
      this.portfolioIsDragging = true;
      this.portfolioStartPos = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      this.portfolioCurrentTranslate = this.portfolioPrevTranslate;
    },

    handlePortfolioTouchMove(e) {
      if (!this.portfolioIsDragging) return;
      const currentPosition = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      const diff = currentPosition - this.portfolioStartPos;
      this.portfolioCurrentTranslate = this.portfolioPrevTranslate + diff;
    },

    handlePortfolioTouchEnd() {
      this.portfolioIsDragging = false;
      const card = document.querySelector('.portfolio-card');
      if (!card) return;
      
      const cardWidth = card.offsetWidth + 20;
      const movedBy = this.portfolioCurrentTranslate - this.portfolioPrevTranslate;
      
      if (Math.abs(movedBy) > cardWidth / 3) {
        if (movedBy < 0 && this.portfolioCurrentIndex < this.randomPortfolios.length - 1) {
          this.portfolioCurrentIndex++;
        } else if (movedBy > 0 && this.portfolioCurrentIndex > 0) {
          this.portfolioCurrentIndex--;
        }
      }
      
      this.portfolioCurrentTranslate = -(cardWidth * this.portfolioCurrentIndex);
      this.portfolioPrevTranslate = this.portfolioCurrentTranslate;
    },

    // 포트폴리오 상세 페이지로 이동
    goToPortfolio(portfolioId) {
      if (!portfolioId) return;
      const portfolio = this.randomPortfolios.find(p => p.id === portfolioId);
      if (portfolio && portfolio.artistId) {
        this.$router.push(`/profile/${portfolio.artistId}`);
      }
    },

    // 디자인 섹션 터치 이벤트 핸들러
    handleDesignTouchStart(e) {
      this.designIsDragging = true;
      this.designStartPos = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      this.designCurrentTranslate = this.designPrevTranslate;
    },

    handleDesignTouchMove(e) {
      if (!this.designIsDragging) return;
      const currentPosition = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
      const diff = currentPosition - this.designStartPos;
      this.designCurrentTranslate = this.designPrevTranslate + diff;
    },

    handleDesignTouchEnd() {
      this.designIsDragging = false;
      const card = document.querySelector('.design-card');
      if (!card) return;
      
      const cardWidth = card.offsetWidth + 20;
      const movedBy = this.designCurrentTranslate - this.designPrevTranslate;
      
      if (Math.abs(movedBy) > cardWidth / 3) {
        if (movedBy < 0 && this.designCurrentIndex < this.randomDesigns.length - 1) {
          this.designCurrentIndex++;
        } else if (movedBy > 0 && this.designCurrentIndex > 0) {
          this.designCurrentIndex--;
        }
      }
      
      this.designCurrentTranslate = -(cardWidth * this.designCurrentIndex);
      this.designPrevTranslate = this.designCurrentTranslate;
    },

    // 디자인 상세 페이지로 이동
    goToDesign(designId) {
      if (!designId) return;
      const design = this.randomDesigns.find(d => d.id === designId);
      if (design && design.artistId) {
        this.$router.push(`/profile/${design.artistId}`);
      }
    },
  }
}
</script>

<style scoped>
/* 전체 페이지 스타일 */
.main-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* 히어로 섹션 */
.hero-section {
  background: linear-gradient(135deg, #000000 0%, #333333 100%);
  color: white;
  padding: 60px 20px;
  padding-top: 30px;
  text-align: center;
  position: relative;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-content p {
  font-size: 20px;
  margin-bottom: 30px;
  opacity: 0.9;
}

.search-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.search-button {
  width: 100%;
  background: #dfe6e9;
  color: black;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 16px;
}

/* 퀵 메뉴 */
.quick-menu {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* 부모 컨테이너에 맞게 조정 */
  gap: 15px;
  padding: 20px;
  margin-top: -40px;
  position: relative;
  z-index: 1;
  box-sizing: border-box; /* 박스 크기 조정 */
}

.menu-item {
  background: white;
  padding: 15px 15px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.menu-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.material-icons {
  font-size: 32px;
  color: #2d3436;
}

.menu-item p {
  font-size: 16px;
  margin: 0;
}

/* 배너 섹션 스타일 */
.banner-section {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}

.banner-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner-slider {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.banner-slide {
  min-width: 100%;
  height: 100%;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 배너 네비게이션 버튼 */
.banner-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
}

.banner-container:hover .banner-nav {
  opacity: 1;
}

.banner-nav.prev {
  left: 20px;
}

.banner-nav.next {
  right: 20px;
}

.banner-nav:hover {
  background: rgba(255, 255, 255, 0.4);
  transform: translateY(-50%) scale(1.1);
}

.banner-nav .material-icons {
  color: #333;
  font-size: 24px;
}

/* 아티스트 섹션 스타일 */
.artists-section {
  padding: 15px 0 10px 0;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 28px;
  margin: 0;
  font-weight: 600;
  color: #333;
}

.view-all {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #666;
  font-size: 14px;
  transition: color 0.3s ease;
}

.view-all:hover {
  color: #333;
}

.view-all .material-icons {
  font-size: 14px;
  margin-left: 4px;
}

.artist-card {
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
  flex: 0 0 230px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.artist-card:hover .artist-image-container img {
  transform: scale(1.05);
}

.artist-image-container {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.artist-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.material-icons {
  font-size: 32px;
}

.artist-info {
  padding: 20px;
}

.artist-info h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0 0 8px 0;
}

.artist-location {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #666;
  font-size: 14px;
  margin: 0 0 12px 0;
}

.artist-location .material-icons {
  font-size: 16px;
}

.artist-genres {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.genre-tag {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-size: 13px;
  color: #555;
  transition: background-color 0.3s ease;
}

.genre-tag:hover {
  background-color: #eeeeee;
}

/* 아티스트 슬라이더 스타일 수정 */
.artists-slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
}

.artists-slider {
  display: flex;
  gap: 20px;
  padding: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.artists-slider::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* 터치 스크롤 힌트 스타일 */
.artists-slider::after {
  content: '';
  flex: 0 0 20px;
}

/* 기존 스타일 유지하고 더보기 카드 스타일만 추가 */
.view-more-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.view-more-content .material-icons {
  font-size: 40px;
  margin-bottom: 10px;
  color: #666;
}

.view-more-content p {
  color: #666;
}

/* 포트폴리오 섹션 스타일 */
.portfolio-section {
  padding: 15px 0 10px 0;
  background-color: #ffffff;
  margin-top: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)
}

.portfolio-slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
}

.portfolio-slider {
  display: flex;
  gap: 20px;
  padding: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.portfolio-slider::-webkit-scrollbar {
  display: none;
}

.portfolio-card {
  flex: 0 0 230px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.portfolio-image-container {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.portfolio-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolio-card:hover .portfolio-image-container img {
  transform: scale(1.05);
}

.portfolio-info {
  padding: 15px;
}

.portfolio-description {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: …;
}

.portfolio-genres {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* 디자인 섹션 스타일 */
.design-section {
  padding: 15px 0 10px 0;
  background-color: #ffffff;
  margin-top: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)
}

.design-slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
}

.design-slider {
  display: flex;
  gap: 20px;
  padding: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.design-slider::-webkit-scrollbar {
  display: none;
}

.design-card {
  flex: 0 0 230px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.design-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.design-image-container {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.design-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.design-info {
  padding: 15px;
}

.design-description {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: …;
}

.design-genres {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.genre-tag {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-size: 13px;
  color: #555;
}

@media (max-width: 768px) {  
  .search-container {
    flex-direction: column;
  }
  
  .quick-menu {
    grid-template-columns: repeat(2, 1fr);
  }

  .banner-nav {
    width: 32px;
    height: 32px;
    opacity: 1; /* 모바일에서는 항상 표시 */
  }

  .banner-nav.prev {
    left: 10px;
  }

  .banner-nav.next {
    right: 10px;
  }
}
</style>