// 일반 사용자가 보는 견적 요청 페이지

<template>
<div class="tattoo-request-user-wrapper">
  <div v-if="isComponentMounted" class="tattoo-request-user">
    <div class="page-header">
      <h2 class="title">내 타투 의뢰 요청</h2>

      <div>
        <router-link 
          v-if="tattooRequests && tattooRequests.length < 3" 
          to="/tattoo-request" 
          class="request-button"
        >
          의뢰요청
        </router-link>
        <div v-else class="request-limit-message">
          의뢰 요청은 최대 3개까지만 등록 가능합니다
        </div>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
      로딩 중...
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
      <button @click="retryFetch" class="retry-button">다시 시도</button>
    </div>

    <div v-else>
      <div v-if="!tattooRequests || tattooRequests.length === 0" class="no-requests">
        <div class="empty-state">
          <p>작성한 타투 의뢰 요청이 없습니다.</p>
        </div>
      </div>

      <div v-else class="requests-slider">
        <div class="slides-container">
          <div 
            class="slides-wrapper"
            :style="{transform: `translateX(-${currentSlide * 100}%)`}"
          >
            <div 
              v-for="request in tattooRequests" 
              :key="request.id" 
              class="request-item"
            >
              <div class="request-header">
                <div class="time-info">
                  <div v-if="!isExpired(request.createdAt)">
                    <div>의뢰 마감: {{ getRemainingTime(request.createdAt) }}</div>
                  </div>
                  <div v-else>
                    <div>삭제 예정: {{ getDeletionTime(request.createdAt) }}
                      <button 
                        v-if="isExpired(request.createdAt)" 
                        @click="deleteRequest(request.id)" 
                        class="delete-button"
                    > 삭제
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="request-details">
                <table class="details-table">
                  <tbody>
                    <tr>
                      <th>희망 날짜</th>
                      <td>{{ formatDate(request.preferredDate) }}</td>
                    </tr>
                    <tr>
                      <th>희망 시간</th>
                      <td>{{ request.preferredTime }}</td>
                    </tr>
                    <tr>
                      <th>희망 지역</th>
                      <td>{{ request.city }} {{ request.district }}</td>
                    </tr>
                    <tr>
                      <th>장르</th>
                      <td>{{ Array.isArray(request.tattooType) ? request.tattooType.join(', ') : request.tattooType }}</td>
                    </tr>
                    <tr>
                      <th>부위</th>
                      <td>{{ request.location }}</td>
                    </tr>
                    <tr>
                      <th>사이즈</th>
                      <td>{{ request.size }}</td>
                    </tr>
                    <tr>
                      <th>예산</th>
                      <td>{{ request.budget ? request.budget.toLocaleString() : '0' }}원</td>
                    </tr>
                    <tr>
                      <th>상세설명</th>
                      <td class="description-cell">{{ request.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="reference-images">
                <span v-if="request.referenceImages && request.referenceImages.length > 0">
                  <span v-for="(url, index) in request.referenceImages" :key="index">
                    <img :src="url" alt="참고 이미지" class="reference-image" @error="handleImageError"/>
                  </span>
                </span>
                <span v-else>
                  참고 이미지 없음
                </span>
              </div>

              <div v-if="request.quotes && request.quotes.length > 0" class="quotes-section">
                <button @click="openQuoteModal(request.quotes)" class="view-quote-button">
                  의뢰서 {{ request.quotes.length }}개 확인하기
                </button>
              </div>

              <div v-else class="no-quotes">
                아직 받은 의뢰서가 없습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="selectedQuotes" class="modal-overlay" @click="closeQuoteModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h3>타투이스트 의뢰서</h3>
        <button class="close-button" @click="closeQuoteModal">X</button>
      </div>
      
      <div class="modal-body">
        <div v-for="(quote, index) in selectedQuotes" :key="index" class="quote-detail-item">
          <!-- 견적서 상세 내용 -->
          <router-link 
            :to="`/profile/${quote.artistId}`" 
            class="profile-link"
            @click="markQuoteAsViewed(quote.requestId, selectedQuotes.indexOf(quote))">
            <button class="view-profile-button">
              프로필 보기
            </button>
          </router-link>
          <table class="quote-details-table">
            <tbody>
              <tr>
                <th>위치</th>
                <td>{{ quote.artistAddress }}</td>
              </tr>
              <tr>
                <th>금액</th>
                <td>{{ quote.quotePrice ? quote.quotePrice.toLocaleString() : '0' }}원</td>
              </tr>
              <tr>
                <th>소요시간</th>
                <td>{{ quote.estimatedTime }}시간</td>
              </tr>
              <tr>
                <th>메시지</th>
                <td>{{ quote.quoteMessage }}</td>
              </tr>
              <tr>
                <th>의뢰 확인</th>
                <td>
                  <div class="quote-status" :class="quote.viewed ? 'viewed' : 'not-viewed'">
                    <i :class="quote.viewed"></i>
                    {{ quote.viewed ? '확인함' : '미확인' }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 필요한 Firebase 및 서비스 import
import { getCurrentUserType } from '@/services/AuthService';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebaseConfig';

export default {
  name: 'TattooRequestUser',
  // Composition API setup 함수
  setup() {
    // 날짜 포맷팅 함수 정의
    const formatDate = (date) => {
      if (!date) return 'N/A';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    };

    return {
      formatDate,
    };
  },
  // 컴포넌트의 반응형 데이터 정의
  data() {
    return {
      userType: null, // 사용자 타입 (regular/artist)
      tattooRequests: [], // 사용자의 타투 요청 목록
      loading: true, // 로딩 상태
      currentUserId: null, // 현재 로그인한 사용자 ID
      currentSlide: 0, // 현재 슬라이드 인덱스
      error: null, // 에러 상태
      isComponentMounted: false, // 컴포넌트 마운트 상태
      retryCount: 0, // 재시도 횟수
      selectedQuotes: null,
    };
  },
  // 컴포넌트 생성 시 실행
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await this.checkUserType();
          this.startTimer();
        } catch (error) {
          console.error('컴포넌트 초기화 실패:', error);
          this.error = '컴포넌트를 초기화하는 중 오류가 발생했습니다.';
        } finally {
          this.isComponentMounted = true;
        }
      } else {
        this.error = '사용자가 인증되지 않았습니다.';
        this.isComponentMounted = true;
      }
    });
  },
  // 컴포넌트가 제거될 때 실행
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  
  methods: {
    // 견적서 확인 상태 업데이트
    async markQuoteAsViewed(requestId, quoteIndex) {
      try {
        // 요청 ID가 없는 경우 현재 보고 있는 견적서의 요청 ID를 찾습니다
        if (!requestId) {
          const request = this.tattooRequests.find(req => 
            req.quotes.some(q => q === this.selectedQuotes[quoteIndex])
          );
          if (request) {
            requestId = request.id;
          } else {
            throw new Error('해당하는 요청을 찾을 수 없습니다.');
          }
        }

        const requestDocRef = doc(db, 'tattooRequests', requestId);
        const requestDocSnap = await getDoc(requestDocRef);

        if (!requestDocSnap.exists()) {
          throw new Error('요청 문서를 찾을 수 없습니다.');
        }

        const requestData = requestDocSnap.data();
        const updatedQuotes = [...requestData.quotes];
        
        // 해당 견적서의 viewed 상태를 true로 업데이트
        if (updatedQuotes[quoteIndex]) {
          updatedQuotes[quoteIndex] = {
            ...updatedQuotes[quoteIndex],
            viewed: true,
            viewedAt: new Date()
          };

          // Firestore 문서 업데이트
          await updateDoc(requestDocRef, {
            quotes: updatedQuotes
          });

          // 로컬 상태 업데이트
          const requestIndex = this.tattooRequests.findIndex(req => req.id === requestId);
          if (requestIndex !== -1) {
            this.tattooRequests[requestIndex].quotes = updatedQuotes;
            
            // selectedQuotes도 업데이트
            if (this.selectedQuotes) {
              this.selectedQuotes = updatedQuotes;
            }
          }

          console.log('견적서 확인 상태가 업데이트되었습니다.');
        }
      } catch (error) {
        console.error('견적서 확인 상태 업데이트 실패:', error);
      }
    },

    // 마감시간까지 남은 시간 계산 (72시간)
    isExpired(createdAt) {
      if (!createdAt) return true;
      const now = new Date();
      const created = createdAt.toDate(); // Firestore Timestamp를 Date 객체로 변환
      const hours = (now - created) / (1000 * 60 * 60);
      return hours > 72;
    },
    
    // 마감까지 남은 시간 표시 (등록일로부터 72시간)
    getRemainingTime(createdAt) {
      if (!createdAt) return '0시간 0분 0초';
      const now = new Date();
      const created = createdAt.toDate(); // Firestore Timestamp를 Date 객체로 변환
      const diffMs = (created.getTime() + (72 * 60 * 60 * 1000)) - now.getTime();
      
      if (diffMs <= 0) return '마감됨';
      
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);
      
      return `${diffHours}시간 ${diffMinutes}분 ${diffSeconds}초`;
    },
    
    // 삭제까지 남은 시간 표시 (마감 후 48시간)
    getDeletionTime(createdAt) {
      if (!createdAt) return '0시간 0분 0초';
      const now = new Date();
      const created = createdAt.toDate(); // Firestore Timestamp를 Date 객체로 변환
      const diffMs = (created.getTime() + (120 * 60 * 60 * 1000)) - now.getTime();
      
      if (diffMs <= 0) return '삭제 예정';
      
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);
      
      return `${diffHours}시간 ${diffMinutes}분 ${diffSeconds}초`;
    },
    
    // 시간 정보 업데이트 - 1초마다 실
    updateTimes() {
      if (this.tattooRequests) {
        this.tattooRequests = [...this.tattooRequests];
      }
    },
    
    // created()에서 호출할 타이머 시작 메서드
    startTimer() {
      this.timer = setInterval(this.updateTimes, 1000); // 1초마다 업데이트
    },

    // Firebase Storage에서 이미지 URL 가져오기
    async getImageUrl(imagePath) {
      try {
        const storage = getStorage();
        const imageRef = ref(storage, imagePath);
        const url = await getDownloadURL(imageRef);
        return url;
      } catch (error) {
        console.error('이미지 URL 가져오기 실패:', error);
        return ''; 
      }
    },

    // 이미지 로드 에러 처리
    handleImageError(event) {
      event.target.src = '/fallback-image.jpg'; // 대체 이미지 표시
    },

    // 용자 타입 확인 및 권한 체크
    async checkUserType() {
      try {
        this.userType = await getCurrentUserType();
        // 일반 사용자가 아닌 경우 접근 제한
        if (this.userType !== 'regular') {
          throw new Error('이 페이지는 일반 사용자만 접근할 수 있습니다.');
        }
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          this.currentUserId = user.uid;
          await this.fetchUserTattooRequests();
        } else {
          throw new Error('사용자가 인증되지 않았습니다.');
        }
      } catch (error) {
        console.error('사용자 유형 확인 실패:', error);
        this.error = error.message;
        throw error;
      }
    },
    // 사용자의 타투 요청 목록을 데이터베이스에서 조회하는 메서드
    async fetchUserTattooRequests() {
      try {
        // 딩 상태 활성화 및 이전 에러 초기화
        this.loading = true;
        this.error = null;
        
        // 'tattooRequests' 컬렉션에 대한 참조 생성
        const requestsRef = collection(db, 'tattooRequests');
        // 현재 로그인한 사용자의 요청만 필터링하는 쿼리 생성
        const q = query(requestsRef, where('userId', '==', this.currentUserId));
        // 쿼리 실행하여 문서 스냅샷 가져오기
        const querySnapshot = await getDocs(q);

        // 요청 데이터를 저장할 배열 초기화
        const requests = [];
        // 각 문서에 대해 반복 처리
        for (const docSnap of querySnapshot.docs) {
          const requestData = docSnap.data();
          const quotesWithLocation = await Promise.all(requestData.quotes.map(async quote => {
            // 타투이스트 정보 가져오기
            const artistRef = doc(db, 'artists', quote.artistId);
            const artistDoc = await getDoc(artistRef);
            let artistAddress = '주소 정보 없음';
            
            if (artistDoc.exists()) {
              const artistData = artistDoc.data();
              // 타투이스트의 주소 정보 가져오기
              // 경로: /tattooRequests/{requestId}/quotes/artistAddress
              // 예시: "대한국 서울특별시 마포구"
              if (artistData.location?.address) {
                artistAddress = artistData.location.address.split(' ').slice(0, 3).join(' ');
              }
            }
            
            return {
              ...quote,
              artistAddress // 타투이스트의 주소 정보 추가
            };
          }));
          
          requests.push({
            id: docSnap.id,
            ...requestData,
            quotes: quotesWithLocation
          });
        }

        this.tattooRequests = requests;
      } catch (error) {
        console.error('타투 견적 요청 불러오기 실패:', error);
        this.error = '타투 견적 요청을 불러오는 중 오류가 발생했습니다.';
        throw error;
      } finally {
        this.loading = false;
      }
    },

    // 데이터 로드 재시도
    async retryFetch() {
      if (this.retryCount >= 3) {
        this.error = '최대 재시도 횟수를 초과했습니다. 페이지를 새로고침해주세요.';
        return;
      }
      
      this.retryCount++;
      try {
        await this.fetchUserTattooRequests();
      } catch (error) {
        console.error('재시도 실패:', error);
      }
    },

    // 문자열의 첫 글자를 대문자로 변환
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    // 견적 요청 삭제 처리
    async deleteRequest(requestId) {
      try {
        if (!confirm('정말로 이 견적 요청을 삭제하시겠습니까?')) {
          return;
        }

        const requestDocRef = doc(db, 'tattooRequests', requestId);
        await deleteDoc(requestDocRef);

        alert('견적 요청이 성공적으로 삭제되었습니다.');
        await this.fetchUserTattooRequests();
      } catch (error) {
        console.error('견적 요청 삭제 실패:', error);
        alert('견적 요청을 삭제하는 중 오류가 발생했습니다.');
      }
    },

    openQuoteModal(quotes) {
      this.selectedQuotes = quotes;
    },
    closeQuoteModal() {
      this.selectedQuotes = null;
    },
  },
};
</script>

<style scoped>
.tattoo-request-user {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  font-family: 'Pretendard', sans-serif;
  background-color: #f8f9fa;
  margin-top: 50px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: #444444;
  margin: 0 0 0 15px;
}

.request-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #444444;
  color: white;
  font-size: 0.8rem;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.request-button:hover {
  background-color: #333333;
  transform: translateY(-2px);
}

.request-limit-message {
  color: #dc3545;
  font-weight: 500;
  padding: 0.8rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.1);
}

.request-limit-message i {
  margin-right: 0.5rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #495057;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #e9ecef;
  border-top: 4px solid #212529;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.empty-state {
  text-align: center;
  padding: 3rem;
  color: #868e96;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.empty-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.requests-slider {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.slides-container {
  overflow: hidden;
  width: 100%;
}

.slides-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.3s ease;
}

.request-item {
  background: linear-gradient(to bottom, #444444 10%, #ffffff 12%);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  color: #ffffff;
}

.request-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
}

.time-info {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.time-info div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.request-date {
  color: #868e96;
  font-size: 0.9rem;
}

.delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  margin-left: auto;
}

.request-details {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1rem;
  margin: 10px 0;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
}

.details-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
}

.details-table tr {
  transition: background-color 0.2s ease;
}

.details-table tr:hover {
  background-color: #f8f9fa;
}

.details-table th,
.details-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e9ecef;
}

.details-table th {
  width: 30%;
  color: #495057;
  font-weight: 600;
  font-size: 0.95rem;
  vertical-align: top;
}

.details-table td {
  color: #212529;
  font-size: 0.95rem;
}

.details-table tr:last-child th,
.details-table tr:last-child td {
  border-bottom: none;
}

.details-table i {
  margin-right: 0.75rem;
  color: #868e96;
  width: 16px;
  text-align: center;
}

.description-cell {
  white-space: pre-line;
  line-height: 1.5;
  max-width: 400px;
}

.reference-images {
  display: flex;
  justify-content: center;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem 0;
  color: black;
}

.reference-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.quotes-section {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.quotes-section h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #343a40;
  margin-bottom: 1.5rem;
}

.quote-content {
  display: grid;
  gap: 1rem;
}

.quote-check-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  background-color: #444444;
  color: white;
  border: none;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  width: 100%;
  max-width: 100%;
  text-decoration: none;
}

.view-profile-button i {
  font-size: 0.9rem;
}

.artist-location {
  color: #6B7280;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.artist-location i {
  margin-right: 0.5rem;
}

.quote-price,
.quote-duration,
.quote-message {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #F9FAFB;
  border-radius: 8px;
  color: #374151;
}

.quote-price i,
.quote-duration i,
.quote-message i {
  margin-right: 0.75rem;
  color: #6B7280;
  font-size: 1rem;
}

.quote-footer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}
.quote-status {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.quote-status.viewed {
  background: #e9ecef;
  color: #444444;
}

.quote-status.not-viewed {
  background: #dc3545;
  color: #ffffff;
}

.no-quotes {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  color: #6B7280;
}

.no-quotes i {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #9CA3AF;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
}

.modal-header h3 {
  margin: 0;
  color: #2d3436;
}

.close-button {
  position: inherit;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
}

.modal-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-detail-item {
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.view-quote-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  background-color: #444444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.view-quote-button:hover {
  background-color: #333333;
}

.quote-details-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.quote-details-table th,
.quote-details-table td {
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
}

.quote-details-table th {
  width: 35%;
  color: #495057;
  font-weight: 600;
}

.quote-details-table td {
  color: #212529;
}

.quote-details-table i {
  color: #868e96;
}

/* 모바일 반응형 */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 1rem;
  }

  .quote-details-table th,
  .quote-details-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .details-table th,
  .details-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .details-table th {
    width: 35%;
  }

  .details-table i {
    margin-right: 0.5rem;
  }
}
</style>
