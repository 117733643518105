<template>
  <div class="google-map-container">
    <div id="map" class="map"></div>
    <div class="address-display">
      <p>선택된 주소: {{ selectedAddress || '선택된 위치가 없습니다.' }}</p>
      <div class="button-container">
        <button class="save-location-btn" @click="saveLocation">위치 저장</button>
        <button class="cancel-btn" @click="cancelLocation">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */
import { doc, setDoc, getDocs, collection, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '@/firebaseConfig';

export default {
  name: 'GoogleMap',
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    initialPosition: {
      type: Object,
      default: () => ({
        lat: 37.5665, // 기본 서울 위치
        lng: 126.9780,
      }),
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      selectedCoordinates: null,
      selectedAddress: '',
    };
  },
  mounted() {
    this.loadGoogleMaps().then(() => {
      this.initializeMap();
    }).catch(error => {
      console.error('Google Maps API 로드 실패:', error);
      alert('Google Maps API를 불러오는 데 실패했습니다.');
    });
  },
  methods: {
    // Google Maps API 스크립트 로드
    loadGoogleMaps() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
          return;
        }

        // 기존 스크립트가 있는지 확인
        const existingScript = document.getElementById('google-maps-script');
        if (existingScript) {
          existingScript.remove();
        }

        window.initMap = () => {
          resolve();
        };

        const script = document.createElement('script');
        script.id = 'google-maps-script';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onerror = (error) => {
          reject(error);
        };
        document.head.appendChild(script);
      });
    },
    // 지도 초기화
    async initializeMap() {
      const auth = getAuth();
      const user = auth.currentUser;

      // 기본 위치 (서울시청)
      const defaultLocation = {
        lat: 37.5666805,
        lng: 126.9784147
      };

      let initialLocation = defaultLocation;

      // 아티스트의 위치 정보 확인
      if (user) {
        const artistDoc = await getDoc(doc(db, "artists", user.uid));
        if (artistDoc.exists() && artistDoc.data().location) {
          const location = artistDoc.data().location;
          if (location.latitude && location.longitude) {
            initialLocation = {
              lat: location.latitude,
              lng: location.longitude
            };
          }
        }
      }

      // 지도 초기화
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: initialLocation,
        zoom: 15,
      });
      
      // 마커 생성
      this.marker = new google.maps.Marker({
        position: initialLocation,
        map: this.map,
        draggable: true,
      });

      this.selectedCoordinates = initialLocation;
      this.reverseGeocode(this.selectedCoordinates);

      // 지도 클릭 시 마커 이동
      this.map.addListener('click', (e) => {
        const clickedLocation = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };
        this.marker.setPosition(clickedLocation);
        this.selectedCoordinates = clickedLocation;
        this.reverseGeocode(clickedLocation);
      });

      // 마커 드래그 종료 시 주소 업데이트
      this.marker.addListener('dragend', () => {
        const newPosition = this.marker.getPosition();
        this.selectedCoordinates = {
          lat: newPosition.lat(),
          lng: newPosition.lng(),
        };
        this.reverseGeocode(this.selectedCoordinates);
      });
    },
    // 좌표를 주소로 변환
    reverseGeocode(coords) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: coords }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.selectedAddress = results[0].formatted_address;
            this.emitLocation();
          } else {
            alert('결과를 찾을 수 없습니다.');
          }
        } else {
          alert('Geocoder 실패: ' + status);
        }
      });
    },
    // 선택한 위치 정보 전달
    emitLocation() {
      this.$emit('location-selected', {
        coordinates: this.selectedCoordinates,
        address: this.selectedAddress,
      });
    },
    // 위치 저장
    async saveLocation() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          alert('로그인이 필요합니다.');
          return;
        }

        if (!this.selectedAddress || !this.selectedCoordinates) {
          alert('저장할 위치를 선택해주세요.');
          return;
        }

        // 아티스트 위치 정보 업데이트
        await setDoc(doc(db, 'artists', user.uid), {
          location: {
            address: this.selectedAddress,
            latitude: this.selectedCoordinates.lat,
            longitude: this.selectedCoordinates.lng
          },
          coordinates: this.selectedCoordinates
        }, { merge: true });

        // 해당 아티스트의 모든 포트폴리오 위치 정보 업데이트
        const portfoliosRef = collection(db, 'artists', user.uid, 'portfolios');
        const portfoliosSnapshot = await getDocs(portfoliosRef);
        
        const updatePromises = portfoliosSnapshot.docs.map(async (portfolioDoc) => {
          await updateDoc(doc(db, 'artists', user.uid, 'portfolios', portfolioDoc.id), {
            location: {
              address: this.selectedAddress,
              latitude: this.selectedCoordinates.lat,
              longitude: this.selectedCoordinates.lng
            },
            coordinates: this.selectedCoordinates
          });
        });

        await Promise.all(updatePromises);

        alert('위치가 성공적으로 저장되었습니다.');
        this.$router.push('/my-page');
      } catch (error) {
        console.error('위치 저장 실패:', error);
        alert('위치 저장에 실패했습니다.');
      }
    },
    // 위치 취소
    cancelLocation() {
      this.$router.push('/my-page');
    }
  },
};
</script>

<style scoped>
.google-map-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.map {
  flex: 1;
  margin: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.address-display {
  padding: 20px;
  background-color: white;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* 버튼 사이 간격 */
  padding: 0 20px;
}

.save-location-btn {
  margin: 10px 0;
  padding: 12px 24px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 40%; /* 너비 조정 */
  max-width: 150px;
}

.save-location-btn:hover {
  background-color: #636e72;
}

.cancel-btn {
  margin: 10px 0;
  padding: 12px 24px;
  background-color: #e74c3c; /* 취소 버튼 색상 */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 40%; /* 너비 조정 */
  max-width: 150px;
}

.cancel-btn:hover {
  background-color: #c0392b;
}
</style>