<template>
  <div class="admin-dashboard">
    <aside class="sidebar">
      <h2>관리자 대시보드</h2>
      <nav class="nav-menu">
        <button
          v-for="menu in menus"
          :key="menu.id"
          :class="['nav-item', { active: currentMenu === menu.id }]"
          @click="currentMenu = menu.id"
        >
          <i :class="menu.icon"></i>
          {{ menu.name }}
        </button>
      </nav>
    </aside>

    <main class="main-content">
      <h1>{{ getCurrentMenuName }}</h1>

      <!-- 승인 요청 섹션 -->
      <div v-if="currentMenu === 'approvalRequests'" class="approval-requests-section">
        <table class="data-table">
          <thead>
            <tr>
              <th>UID</th>
              <th>프로필</th>
              <th>닉네임</th>
              <th>이메일</th>
              <th>경력</th>
              <th>샵</th>
              <th>장르</th>
              <th>위치</th>
              <th>요청일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="artist in pendingApprovals" :key="artist.id">
              <td>{{ artist.id }}</td>
              <td>
                <img 
                  :src="artist.profileImageUrl || require('@/assets/default-profile.jpg')" 
                  :alt="artist.nickname" 
                  class="profile-image"
                />
              </td>
              <td>{{ artist.nickname }}</td>
              <td>{{ artist.email }}</td>
              <td>{{ artist.career }}년</td>
              <td>{{ artist.shopName }}</td>
              <td>{{ artist.genres?.join(', ') }}</td>
              <td>{{ artist.location?.address }}</td>
              <td>{{ formatDate(artist.requestDate) }}</td>
              <td>
                <button 
                  class="approve-btn"
                  @click="handleApproval(artist.id, true)"
                >
                  승인
                </button>
                <button 
                  class="reject-btn"
                  @click="handleApproval(artist.id, false)"
                >
                  거절
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 사용자 관리 섹션 -->
      <section v-if="currentMenu === 'users'" class="users-section">
        <!-- 유저 타입 선택 탭 -->
        <div class="user-type-tabs">
          <button 
            :class="['tab-button', { active: selectedUserType === 'regular' }]"
            @click="selectedUserType = 'regular'"
          >
            일반 사용자
          </button>
          <button 
            :class="['tab-button', { active: selectedUserType === 'artist' }]"
            @click="selectedUserType = 'artist'"
          >
            타투이스트
          </button>
        </div>

        <!-- 사용자 목록 테이블 -->
        <table class="data-table">
          <thead>
            <tr>
              <th>UID</th>
              <th>프로필</th>
              <th>닉네임</th>
              <th>이메일</th>
              <th>가입일</th>
              <th v-if="selectedUserType === 'artist'">샵</th>
              <th v-if="selectedUserType === 'artist'">경력</th>
              <th v-if="selectedUserType === 'artist'">장르</th>
              <th>상태</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" :key="user.id">
              <td>{{ user.id }}</td>
              <td>
                <img 
                  :src="user.profileImageUrl || require('@/assets/default-profile.jpg')" 
                  :alt="user.nickname" 
                  class="profile-image"
                />
              </td>
              <td>{{ user.nickname }}</td>
              <td>{{ user.email }}</td>
              <td>{{ formatDate(user.createdAt) }}</td>
              <td v-if="selectedUserType === 'artist'">{{ user.shopName || '-' }}</td>
              <td v-if="selectedUserType === 'artist'">{{ user.career || 0 }}년</td>
              <td v-if="selectedUserType === 'artist'">{{ user.genres?.join(', ') || '-' }}</td>
              <td>{{ user.status || '활성' }}</td>
              <td>
                <button @click="openEditModal(user)" class="edit-btn">수정</button>
                <button @click="confirmDelete(user.id)" class="delete-btn">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- 포트폴리오 관리 -->
      <section v-if="currentMenu === 'portfolios'">
        <h2>포트폴리오 관리</h2>
        <table class="data-table">
          <thead>
            <tr>
              <th>이미지</th>
              <th>타투이스트</th>
              <th>설명</th>
              <th>작업일</th>
              <th>작업 크기</th>
              <th>작업 타입</th>
              <th>작업장소</th>
              <th>작업 시간</th>
              <th>작업 가격</th>
              <th>작업 상태</th>
              <th>작업 유형</th>
              <th>작업 스타일</th>
              <th>작업 상세</th>
              <th>작업수정</th>
              <th>작업삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="portfolio in portfolios" :key="portfolio.id">
              <td>
                <img 
                  :src="portfolio.images?.[0] || ''" 
                  alt="포트폴리오 이미지" 
                  class="portfolio-image" 
                />
              </td>
              <td>{{ portfolio.artistName }}</td>
              <td>{{ portfolio.description }}</td>
              <td>{{ formatDate(portfolio.createdAt) }}</td>
              <td>{{ portfolio.size }}</td>
              <td>{{ portfolio.type }}</td>
              <td>{{ portfolio.location }}</td>
              <td>{{ portfolio.time }}</td>
              <td>{{ portfolio.price }}</td>
              <td>{{ portfolio.status }}</td>
              <td>{{ portfolio.workType }}</td>
              <td>{{ portfolio.style }}</td>
              <td>{{ portfolio.details }}</td>
              <td>
                <button @click="openEditModal(portfolio)" class="edit-btn">수정</button>
              </td>
              <td>
                <button @click="confirmDelete(portfolio.id)" class="delete-btn">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- 디자인 관리 -->
      <section v-if="currentMenu === 'designs'">
        <h2>디자인 관리</h2>
        <table class="data-table">
          <thead>
            <tr>
              <th>디자인 이미지</th>
              <th>디자인 이름</th>
              <th>설명</th>
              <th>작업자</th>
              <th>등록일</th>
              <th>작업 상태</th>
              <th>작업 수정</th>
              <th>작업 삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="design in designs" :key="design.id">
              <td>
                <img 
                  :src="design.imageUrl || ''" 
                  alt="디자인 이미지" 
                  class="design-image" 
                />
              </td>
              <td>{{ design.name }}</td>
              <td>{{ design.description }}</td>
              <td>{{ design.artistName }}</td>
              <td>{{ formatDate(design.createdAt) }}</td>
              <td>{{ design.status }}</td>
              <td>
                <button @click="openEditModal(design)" class="edit-btn">수정</button>
              </td>
              <td>
                <button @click="confirmDelete(design.id)" class="delete-btn">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- 게시글 관리 -->
      <section v-if="currentMenu === 'posts'">
        <h2>게시글 관리</h2>
        <table class="data-table">
          <thead>
            <tr>
              <th>게시글 제목</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>게시글 상태</th>
              <th>작업 수정</th>
              <th>작업 삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="post in posts" :key="post.id">
              <td>{{ post.title }}</td>
              <td>{{ post.author }}</td>
              <td>{{ formatDate(post.createdAt) }}</td>
              <td>{{ post.status }}</td>
              <td>
                <button @click="openEditModal(post)" class="edit-btn">수정</button>
              </td>
              <td>
                <button @click="confirmDelete(post.id)" class="delete-btn">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- 타투샵 관리 -->
      <section v-if="currentMenu === 'shops'" class="shop-management">
        <h2>타투샵 관리</h2>
        
        <div class="shop-requests">
          <div v-for="request in shopRequests" :key="request.id" class="request-card">
            <div class="request-header">
              <h4>{{ request.name }}</h4>
              <span class="status" :class="request.status">{{ getStatusText(request.status) }}</span>
            </div>
            
            <div class="request-details">
              <p><strong>신청자:</strong> {{ request.ownerNickname }}</p>
              <p><strong>주소:</strong> {{ request.address }}</p>
              <p><strong>설명:</strong> {{ request.description }}</p>
              <p><strong>신청일:</strong> {{ formatDate(request.createdAt) }}</p>
              
              <div class="shop-images">
                <img 
                  v-for="(image, index) in request.images" 
                  :key="index" 
                  :src="image" 
                  alt="Shop image"
                  @click="openImageModal(image)"
                  class="shop-image"
                >
              </div>
            </div>

            <div class="request-actions" v-if="request.status === 'pending'">
              <button @click="approveShop(request)" class="approve-btn">승인</button>
              <button @click="rejectShop(request)" class="reject-btn">거절</button>
            </div>
          </div>
        </div>
      </section>

      <!-- 모달: 수정 -->
      <div v-if="isEditModalOpen" class="modal-overlay" @click="closeEditModal">
        <div class="modal" @click.stop>
          <div class="modal-header">
            <h3>수정</h3>
            <button @click="closeEditModal" class="close-button">&times;</button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitEdit">
              <!-- 동적으로 폼 필드 생성 -->
              <div v-for="(value, key) in editForm" :key="key" class="form-group">
                <label :for="key">{{ key }}</label>
                <input v-if="isTextField(key)" :id="key" v-model="editForm[key]" type="text" />
                <textarea v-else-if="isTextareaField(key)" :id="key" v-model="editForm[key]"></textarea>
                <select v-else-if="isSelectField(key)" :id="key" v-model="editForm[key]">
                  <option v-for="option in getOptionsForField(key)" :key="option" :value="option">{{ option }}</option>
                </select>
                <!-- 추가 필��� 타입에 따른 입력 요소들 -->
              </div>
              <button type="submit" class="submit-button">저장</button>
            </form>
          </div>
        </div>
      </div>

      <!-- 모달: 삭제 확인 -->
      <div v-if="isDeleteModalOpen" class="modal-overlay" @click="closeDeleteModal">
        <div class="modal" @click.stop>
          <div class="modal-header">
            <h3>삭제 확인</h3>
            <button @click="closeDeleteModal" class="close-button">&times;</button>
          </div>
          <div class="modal-body">
            <p>정말로 삭제하시겠습니까?</p>
            <div class="modal-actions">
              <button @click="deleteItem" class="confirm-button">예</button>
              <button @click="closeDeleteModal" class="cancel-button">아니오</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 이미지 모달 -->
      <div v-if="selectedImage" class="image-modal" @click="closeImageModal">
        <img :src="selectedImage" alt="확대된 이미지" />
      </div>
    </main>
  </div>
</template>

<script>
import { db } from '@/firebaseConfig';
import { 
  collection, 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc,
  query, 
  where,
  getDoc,
  addDoc
} from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';

export default {
  name: 'AdminDashboard',
  data() {
    return {
      currentMenu: 'users', // 현재 선택된 메뉴
      menus: [
        { id: 'approvalRequests', name: '승인 요청' },
        { id: 'users', name: '사용자 관리', icon: 'fas fa-users' },
        { id: 'portfolios', name: '포트폴리오 관리', icon: 'fas fa-images' },
        { id: 'designs', name: '디자인 관리', icon: 'fas fa-paint-brush' },
        { id: 'posts', name: '게시글 관리', icon: 'fas fa-edit' },
        { id: 'shops', name: '타투샵 관리', icon: 'fas fa-store' }
      ],
      users: [], // 사용자 목
      portfolios: [], // 포트폴리오 목록
      designs: [], // 디자인 목록
      posts: [], // 게시글 목록
      isEditModalOpen: false, // 수정 모달 열림 여부
      isDeleteModalOpen: false, // 삭제 모달 열림 여부
      editForm: {}, // 수정 폼 데이터
      itemToDelete: null, // 삭제할 항목 ID
      pendingApprovals: [], // 승인 대기 중인 타투이스트 목록
      selectedUserType: 'regular', // 선택된 유저 타입 (regular 또는 artist)
      shopRequests: [],
      selectedImage: null,
    };
  },
  computed: {
    // 현재 선택된 메뉴의 이름을 반���
    getCurrentMenuName() {
      const menu = this.menus.find(m => m.id === this.currentMenu);
      return menu ? menu.name : '';
    },
    // 선택된 유저 타입에 따라 필터링된 사용자 목록
    filteredUsers() {
      if (this.selectedUserType === 'artist') {
        return this.users.filter(user => 
          user.userType === 'artist' || user.userType === 'OKartist'
        );
      } else {
        return this.users.filter(user => 
          user.userType === 'regular' || !user.userType
        );
      }
    }
  },
  created() {
    // 컴포넌트가 생성될 때 데이터 가져오기
    this.fetchData();
  },
  methods: {
    // 데이터 가져오기
    async fetchData() {
      await Promise.all([
        this.fetchPendingApprovals(), // 승인 대기 중인 타투이스트 목록 가져오기
        this.fetchUsers(), // 사용자 목록 가져오기
        this.fetchPortfolios(), // 포트폴리오 목록 가져오기
        this.fetchDesigns(), // 디자인 목록 가져오기
        this.fetchPosts(), // 게시글 목록 가져오기
        this.loadShopRequests() // 타투샵 요청 목록 가져오기
      ]);
    },
    async fetchUsers() {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const regularUsers = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // 타투이스트 목록 가져오기
        const artistsSnapshot = await getDocs(collection(db, 'artists'));
        const artistUsers = artistsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // 모든 사용자 목록 합치기
        this.users = [...regularUsers, ...artistUsers];
      } catch (error) {
        console.error('사용자 목록 로드 실패:', error);
      }
    },
    async fetchPortfolios() {
      try {
        const portfoliosSnapshot = await getDocs(collection(db, 'portfolios'));
        this.portfolios = portfoliosSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // images 배열이 없는 경우 빈 배열로 초기화
          images: doc.data().images || []
        }));
      } catch (error) {
        console.error('포트폴리오 목록 로드 실패:', error);
      }
    },
    async fetchDesigns() {
      try {
        const designsSnapshot = await getDocs(collection(db, 'designs'));
        this.designs = designsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // imageUrl이 없는 경우 기본 이미지 경로 설정
          imageUrl: doc.data().images || ''
        }));
      } catch (error) {
        console.error('디자인 목록 로드 실패:', error);
      }
    },
    async fetchPosts() {
      // Firestore에서 'posts' 컬렉션의 문서들을 가져옴
      const postsSnapshot = await getDocs(collection(db, 'posts'));
      // 가져온 문서들을 배열로 변환하여 posts 데이터에 저장
      this.posts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },

    // 승인 기 중인 타투이스트 목록 가져오기
    async fetchPendingApprovals() {
      try {
        const approvalQuery = query(
          collection(db, 'artists'),
          where('userType', '==', 'OKartist'),  // OKartist인 경우만
          where('hasRequestedApproval', '==', true)
        );
        const snapshot = await getDocs(approvalQuery);
        this.pendingApprovals = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error('승인 요청 목록 로드 실패:', error);
      }
    },

    // 승인/거절 처리
    async handleApproval(artistId, isApproved) {
      try {
        const artistRef = doc(db, 'artists', artistId);
        
        if (isApproved) {
          // 승인 처리
          await updateDoc(artistRef, {
            hasRequestedApproval: false,
            isApproved: true,
            userType: 'artist',  // OKartist에서 artist로 변경
            approvalDate: new Date()
          });
          alert('승인이 완료되었습니다.');
        } else {
          // 거절 처리
          await updateDoc(artistRef, {
            hasRequestedApproval: false,
            isApproved: false,
            rejectionDate: new Date()
          });
          alert('승인이 거절되었습니다.');
        }
        
        // 목록 새로고침
        await this.fetchPendingApprovals();
      } catch (error) {
        console.error('승인 처리 실패:', error);
        alert('승인 처리 중 오류가 발생했습니다.');
      }
    },

    // 수정 모달 관련 메서드
    openEditModal(item) {
      // 수정할 항목의 데이터를 editForm에 저장하고 모달을 엶
      this.editForm = { ...item }; // 깊은 복사 권장
      this.isEditModalOpen = true;
    },
    closeEditModal() {
      // 수정 모달을 닫고 editForm 초기화
      this.isEditModalOpen = false;
      this.editForm = {};
    },
    async submitEdit() {
      try {
        let docRef;
        if (this.currentMenu === 'users') {
          // 선택된 유저 타입에 따라 컬렉션 선택
          const collectionName = this.selectedUserType === 'artist' ? 'artists' : 'users';
          docRef = doc(db, collectionName, this.editForm.id);
        } else {
          docRef = doc(db, this.currentMenu, this.editForm.id);
        }

        const updateData = { ...this.editForm };
        delete updateData.id; // ID는 업데이트 데이터에서 제거

        await updateDoc(docRef, updateData);
        alert('수정이 완료되었습니다.');
        this.closeEditModal();
        this.fetchData(); // 최신 데이터 다시 가져오기
      } catch (error) {
        console.error('수정 실패:', error);
        alert('수정을 실패했습니다.');
      }
    },

    // 삭제 모달 관련 메서드
    confirmDelete(id) {
      // 삭제할 항목의 ID를 저장하고 모달을 엶
      this.itemToDelete = id;
      this.isDeleteModalOpen = true;
    },
    closeDeleteModal() {
      // 삭제 모달을 닫고 itemToDelete 초기화
      this.isDeleteModalOpen = false;
      this.itemToDelete = null;
    },
    async deleteItem() {
      try {
        const storage = getStorage();
        
        if (this.currentMenu === 'users') {
          // 선택된 유저 타입에 따라 컬렉션 선택
          const collectionName = this.selectedUserType === 'artist' ? 'artists' : 'users';
          const userDoc = await getDoc(doc(db, collectionName, this.itemToDelete));
          const userData = userDoc.data();

          // 프로필 이미지 삭제
          if (userData.profileImageUrl) {
            try {
              const imageRef = ref(storage, userData.profileImageUrl);
              await deleteObject(imageRef);
            } catch (error) {
              console.error('프로필 이미지 삭제 실패:', error);
            }
          }

          // 타투이스트인 경우 포트폴리오 이미지들도 삭제
          if (this.selectedUserType === 'artist') {
            // 해당 타투이스트의 포트폴리오 가져오기
            const portfoliosSnapshot = await getDocs(
              query(collection(db, 'portfolios'), where('artistId', '==', this.itemToDelete))
            );

            // 포트폴리오 이미지 삭제 및 문서 삭제
            for (const portfolioDoc of portfoliosSnapshot.docs) {
              const portfolioData = portfolioDoc.data();
              
              // 포트폴리오 이미지 삭제
              if (portfolioData.images && Array.isArray(portfolioData.images)) {
                for (const imageUrl of portfolioData.images) {
                  try {
                    const imageRef = ref(storage, imageUrl);
                    await deleteObject(imageRef);
                  } catch (error) {
                    console.error('포트폴리오 이미지 삭제 실패:', error);
                  }
                }
              }

              // 포트폴리오 문서 삭제
              await deleteDoc(doc(db, 'portfolios', portfolioDoc.id));
            }
          }

          // 유저 문서 삭제
          await deleteDoc(doc(db, collectionName, this.itemToDelete));
        } else if (this.currentMenu === 'portfolios') {
          // 포트폴리오 삭제
          const portfolioDoc = await getDoc(doc(db, 'portfolios', this.itemToDelete));
          const portfolioData = portfolioDoc.data();

          // 포트폴리오 이미지들 삭제
          if (portfolioData.images && Array.isArray(portfolioData.images)) {
            for (const imageUrl of portfolioData.images) {
              try {
                const imageRef = ref(storage, imageUrl);
                await deleteObject(imageRef);
              } catch (error) {
                console.error('포트폴리오 이미지 삭제 실패:', error);
              }
            }
          }

          // 포트폴리오 문서 삭제
          await deleteDoc(doc(db, 'portfolios', this.itemToDelete));
        } else if (this.currentMenu === 'designs') {
          // 디자인 삭제
          const designDoc = await getDoc(doc(db, 'designs', this.itemToDelete));
          const designData = designDoc.data();

          // 디자인 이미지 삭제
          if (designData.imageUrl) {
            try {
              const imageRef = ref(storage, designData.imageUrl);
              await deleteObject(imageRef);
            } catch (error) {
              console.error('디자인 이미지 삭제 실패:', error);
            }
          }

          // 디자인 문서 삭제
          await deleteDoc(doc(db, 'designs', this.itemToDelete));
        } else {
          // 다른 컬렉션의 문서 삭제
          await deleteDoc(doc(db, this.currentMenu, this.itemToDelete));
        }

        alert('삭제가 완료되었습니다.');
        this.closeDeleteModal();
        this.fetchData(); // 최신 데이터 다시 가져오기
      } catch (error) {
        console.error('삭제 실패:', error);
        alert('삭제를 실패했습니다.');
      }
    },

    // 폼 필드 유형 확인
    isTextField(field) {
      // 텍스트 필드인지 확인
      const textFields = ['nickname', 'email', 'title', 'description', 'artistName', 'location', 'price', 'status', 'workType', 'style'];
      return textFields.includes(field);
    },
    isTextareaField(field) {
      // 텍스트 영역 필��인지 확인
      const textareaFields = ['details'];
      return textareaFields.includes(field);
    },
    isSelectField(field) {
      // 선택 필드인지 확인
      const selectFields = ['userType', 'type', 'workType', 'status'];
      return selectFields.includes(field);
    },
    getOptionsForField(field) {
      // 선택 필드의 옵션을 반환
      const options = {
        userType: ['regular', 'artist'],
        type: ['리얼리스틱', '트라이벌', '블랙앤그레이', '올드스쿨'],
        workType: ['단일', '복합'],
        status: ['활성', '비활성'],
      };
      return options[field] || [];
    },

    // 날짜 포맷팅
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date.seconds ? date.seconds * 1000 : date);
      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(d);
    },

    getTabName(tab) {
      const tabNames = {
        users: '사용자 관리',
        portfolios: '포트폴리오 관리',
        designs: '디자인 관리',
        shops: '타투샵 관리'
      };
      return tabNames[tab] || tab;
    },

    async loadShopRequests() {
      try {
        const snapshot = await getDocs(collection(db, 'shopRequests'));
        this.shopRequests = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('샵 요청 목록 로드 실패:', error);
      }
    },

    async approveShop(request) {
      try {
        await addDoc(collection(db, 'shops'), {
          ...request,
          status: 'approved',
          approvedAt: new Date()
        });

        await updateDoc(doc(db, 'shopRequests', request.id), {
          status: 'approved'
        });

        await this.loadShopRequests();
        alert('샵이 승인되었습니다.');
      } catch (error) {
        console.error('샵 승인 실패:', error);
        alert('승인 처리 중 오류가 발생했습니다.');
      }
    },

    async rejectShop(request) {
      try {
        await updateDoc(doc(db, 'shopRequests', request.id), {
          status: 'rejected'
        });

        await this.loadShopRequests();
        alert('샵 등록이 거절되었습니다.');
      } catch (error) {
        console.error('샵 거절 실패:', error);
        alert('거절 처리 중 오류가 발생했습니다.');
      }
    },

    getStatusText(status) {
      const statusMap = {
        pending: '대기 중',
        approved: '승인됨',
        rejected: '거절됨'
      };
      return statusMap[status] || status;
    },

    openImageModal(image) {
      this.selectedImage = image;
    },

    closeImageModal() {
      this.selectedImage = null;
    },
  },
  mounted() {
    // 기존 mounted 로직 유지
    this.loadShopRequests();
  }
};
</script>

<style scoped>
.admin-dashboard {
  display: flex;
  height: 100vh;
  background: #f0f2f5;
}

/* 사이드바 스타일 */
.sidebar {
  width: 250px;
  background: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.nav-menu {
  display: flex;
  flex-direction: column;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 16px;
  color: #555;
}

.nav-item:hover {
  background: #f5f5f5;
}

.nav-item.active {
  background: #e3f2fd;
  color: #1976d2;
}

/* 메인 콘텐츠 스타일 */
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.main-content h1 {
  margin-bottom: 20px;
  color: #333;
}

/* 데이터 테이블 스타일 */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

.data-table th,
.data-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

.profile-image,
.portfolio-image,
.design-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.edit-btn,
.delete-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
}

.edit-btn {
  background-color: #2196f3;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.edit-btn:hover {
  background-color: #1976d2;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

/* 모달 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.modal-header {
  padding: 15px 20px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #555;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #45a049;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirm-button {
  padding: 8px 16px;
  background-color: #f44336;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.cancel-button {
  padding: 8px 16px;
  background-color: #9e9e9e;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #d32f2f;
}

.cancel-button:hover {
  background-color: #757575;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    box-shadow: none;
  }

  .main-content {
    padding: 10px;
  }

  .data-table th,
  .data-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
}

.approval-requests-section {
  margin-top: 20px;
}

.approve-btn,
.reject-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.approve-btn {
  background-color: #2ecc71;
  color: white;
}

.reject-btn {
  background-color: #e74c3c;
  color: white;
}

.approve-btn:hover {
  background-color: #27ae60;
}

.reject-btn:hover {
  background-color: #c0392b;
}

.user-type-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  background-color: #f0f0f0;
}

.tab-button.active {
  background-color: #2d3436;
  color: white;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.edit-btn,
.delete-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.edit-btn {
  background-color: #3498db;
  color: white;
}

.delete-btn {
  background-color: #e74c3c;
  color: white;
}

.edit-btn:hover {
  background-color: #2980b9;
}

.delete-btn:hover {
  background-color: #c0392b;
}

.portfolio-image,
.design-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.portfolio-image:hover,
.design-image:hover {
  transform: scale(1.1);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .portfolio-image,
  .design-image {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 480px) {
  .portfolio-image,
  .design-image {
    width: 60px;
    height: 60px;
  }
}

.shop-management {
  margin-top: 2rem;
}

.request-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.status {
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.status.pending {
  background: #fff3cd;
  color: #856404;
}

.status.approved {
  background: #d4edda;
  color: #155724;
}

.status.rejected {
  background: #f8d7da;
  color: #721c24;
}

.shop-images {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem 0;
}

.shop-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.shop-image:hover {
  transform: scale(1.05);
}

.approve-btn, .reject-btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  margin-right: 1rem;
}

.approve-btn {
  background: #28a745;
  color: white;
}

.approve-btn:hover {
  background: #218838;
}

.reject-btn {
  background: #dc3545;
  color: white;
}

.reject-btn:hover {
  background: #c82333;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.image-modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
</style> 