<template>
  <!-- 채팅 목록 페이지 전체 컨테이너 -->
  <div class="chat-list-page">
    <!-- 페이지 제목 -->
    <h2 class="page-title">채팅 목록</h2>
    
    <!-- 로딩 상태 표시 -->
    <div v-if="isLoading" class="loading-state">
      로딩 중...
    </div>

    <!-- 채팅 목록 컨테이너 -->
    <div v-else class="chat-list">
      <!-- 채팅이 없을 경우 표시되는 메시지 -->
      <div v-if="chats.length === 0" class="no-chats">
        <p>진행 중인 채팅이 없습니다.</p>
      </div>
      
      <!-- 채팅 목록 아이템 반복 -->
      <div 
        v-for="chat in sortedChats" 
        :key="chat.id" 
        class="chat-item"
        @click="openChat(chat.id)"
      >
        <!-- 채팅 상대방 프로필 이미지 -->
        <div class="chat-avatar">
          <img 
            :src="chat.otherUser.profileImage || defaultProfileImage"
            alt="프로필 이미지"
          />
        </div>
        <!-- 채팅 정보 영역 -->
        <div class="chat-info">
          <!-- 채팅방 헤더: 이름과 시간 -->
          <div class="chat-header">
            <span class="chat-name">{{ chat.otherUser.name }}</span>
            <span class="chat-time">{{ chat.lastMessageTime ? formatTime(chat.lastMessageTime) : '' }}</span>
          </div>
          <!-- 채팅 미리보기: 마지막 메시지와 읽지 않은 메시지 수 -->
          <div class="chat-preview">
            <p>{{ chat.lastMessage.startsWith('http') ? '사진' : chat.lastMessage }}</p>
            <span v-if="chat.unreadCount > 0" class="unread-count">
              읽지 않은 메세지 : {{ chat.unreadCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Firebase Firestore 관련 기능들을 가져옴
import { 
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  limit,
  getDocs,
  Timestamp,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getCurrentUserType } from '@/services/AuthService';

export default {
  name: 'ChatList',
  
  data() {
    return {
      chats: [],
      unsubscribe: null,
      currentUserType: null,
      defaultProfileImage: require('@/assets/default-profile.jpg'),
      isLoading: true
    };
  },

  computed: {
    sortedChats() {
      return [...this.chats].sort((a, b) => {
        // lastMessageTime이 Timestamp인지 확인하고 변환
        const timeA = a.lastMessageTime instanceof Timestamp ? a.lastMessageTime.toDate() : new Date(0);
        const timeB = b.lastMessageTime instanceof Timestamp ? b.lastMessageTime.toDate() : new Date(0);
        return timeB - timeA;
      });
    }
  },

  mounted() {
    const auth = getAuth();
    
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userInfo = await getCurrentUserType();
          if (userInfo) {
            this.currentUserType = userInfo.type;
            this.loadChats();
            this.checkAndDeleteOldChats();
          }
        } catch (error) {
          console.error('사용자 타입 확인 중 오류 발생:', error);
          this.$router.push('/login');
        }
      } else {
        this.$router.push('/login');
      }
      this.isLoading = false;
    });
  },

  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },

  methods: {

    async checkAndDeleteOldChats() {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      const chatsQuery = query(
        collection(db, 'chats'),
        where('lastMessageTime', '<', Timestamp.fromDate(thirtyDaysAgo))
      );

      try {
        const snapshot = await getDocs(chatsQuery);
        const batch = writeBatch(db);

        for (const chatDoc of snapshot.docs) {
          // 채팅방의 모든 메시지 삭제
          const messagesSnapshot = await getDocs(collection(db, 'chats', chatDoc.id, 'messages'));
          messagesSnapshot.docs.forEach(messageDoc => {
            batch.delete(doc(db, 'chats', chatDoc.id, 'messages', messageDoc.id));
          });
          
          // 채팅방 자체 삭제
          batch.delete(doc(db, 'chats', chatDoc.id));
        }

        await batch.commit();
        // console.log('30일이 지난 채팅 기록이 삭제되었습니다.');
      } catch (error) {
        console.error('오래된 채팅 삭제 중 오류 발생:', error);
      }
    },

    async loadChats() {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        console.warn('현재 사용자가 로그인되어 있지 않습니다.');
        return;
      }

      try {
        const chatsQuery = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', currentUser.uid),
          orderBy('lastMessageTime', 'desc')
        );

        this.unsubscribe = onSnapshot(chatsQuery, async (snapshot) => {
          const chatsList = [];
          
          for (const docSnapshot of snapshot.docs) {
            const chatData = docSnapshot.data();
            const otherUserId = chatData.participants.find(id => id !== currentUser.uid);
            
            if (!otherUserId) {
              console.warn(`채팅방 ${docSnapshot.id}에 다른 사용자가 없습니다.`);
              continue;
            }
            
            try {
              let otherUserData = {};
              let otherUserType = '';

              // 상대방의 타입에 따라 적절한 컬렉션에서 데이터 조회
              const userDocRef = doc(db, 'users', otherUserId);
              const artistDocRef = doc(db, 'artists', otherUserId);
              
              const userDoc = await getDoc(userDocRef);
              const artistDoc = await getDoc(artistDocRef);

              if (userDoc.exists()) {
                otherUserData = userDoc.data();
                otherUserType = 'user';
              } else if (artistDoc.exists()) {
                otherUserData = artistDoc.data();
                otherUserType = 'artist';
              } else {
                console.warn(`사용자 ID ${otherUserId}를 찾을 수 없습니다.`);
                continue;
              }

              // 마지막 메시지 가져오기
              const messagesRef = collection(db, 'chats', docSnapshot.id, 'messages');
              const lastMessageQuery = query(messagesRef, orderBy('timestamp', 'desc'), limit(1));
              const lastMessageSnapshot = await getDocs(lastMessageQuery);
              
              let lastMessage = '새로운 채팅';
              let lastMessageTime = chatData.lastMessageTime;
              
              if (!lastMessageSnapshot.empty) {
                const lastMessageDoc = lastMessageSnapshot.docs[0];
                const messageData = lastMessageDoc.data();
                lastMessage = messageData.content;
                lastMessageTime = messageData.timestamp;
              }

              chatsList.push({
                id: docSnapshot.id,
                lastMessage: lastMessage,
                lastMessageTime: lastMessageTime,
                unreadCount: chatData.unreadCount?.[currentUser.uid] || 0,
                otherUser: {
                  id: otherUserId,
                  name: otherUserData.nickname || otherUserData.name || '알 수 없음',
                  profileImage: otherUserData.profileImageUrl,
                  type: otherUserType
                }
              });
            } catch (error) {
              console.error(`사용자 데이터를 가져오는 중 오류 발생: ${error}`);
            }
          }
          
          this.chats = chatsList;
        }, (error) => {
          console.error('채팅 목록 로드 중 오류 발생:', error);
        });
      } catch (error) {
        console.error('채팅 목록 쿼리 생성 중 오류 발생:', error);
      }
    },

    openChat(chatId) {
      this.$router.push(`/chat/${chatId}`);
    },

    formatTime(timestamp) {
      if (!timestamp || !(timestamp instanceof Timestamp)) return '';
      
      const date = timestamp.toDate();
      const now = new Date();
      const diff = now - date;
      const minutes = Math.floor(diff / 60000);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (minutes < 1) {
        return '방금 전';
      } else if (minutes < 60) {
        return `${minutes}분 전`;
      } else if (hours < 24) {
        return `${hours}시간 전`;
      } else if (days < 7) {
        return `${days}일 전`;
      } else {
        return date.toLocaleDateString('ko-KR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
    },

    async deleteChat(chatId) {
      try {
        const chatDocRef = doc(db, 'chats', chatId);
        await deleteDoc(chatDocRef);
        alert('채팅이 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('채팅 삭제 실패:', error);
        alert('채팅을 삭제하는 중 오류 발생했습니다.');
      }
    }
  }
};
</script>

<style scoped>
/* 전역 CSS 변수 정의 */
:root {
  --primary-color: #4A90E2;
  --secondary-color: #FFFFFF;
  --background-color: #F5F7FA;
  --text-color: #333333;
  --subtext-color: #888888;
  --hover-bg: #E6F0FF;
  --unread-bg: #FF5A5F;
  --border-color: #E0E0E0;
  --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.chat-list-page {
  max-width: 100%;
  height: 90vh;
  margin: 60px auto 0;
  padding: 20px;
  background: var(--background-color);
  box-sizing: border-box;
}

.page-title {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 600;
  font-family: var(--font-family);
}

.chat-list {
  background: var(--secondary-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-item {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-item:last-child {
  border-bottom: none;
}

.chat-item:hover {
  background-color: var(--hover-bg);
}

.chat-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.chat-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
  font-family: var(--font-family);
}

.chat-time {
  font-size: 12px;
  color: var(--subtext-color);
  font-family: var(--font-family);
}

.chat-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-preview p {
  margin: 0;
  color: var(--subtext-color);
  font-size: 14px;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  font-family: var(--font-family);
}

.unread-count {
  background-color: var(--unread-bg);
  color: var(--secondary-color);
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.no-chats {
  padding: 60px 20px;
  text-align: center;
  color: var(--subtext-color);
  font-size: 16px;
  font-family: var(--font-family);
}

/* 로딩 상태 스타일 추가 */
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--subtext-color);
  font-size: 16px;
  font-family: var(--font-family);
}
</style>
