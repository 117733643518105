import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { ref as dbRef, onDisconnect, set, serverTimestamp, onValue } from 'firebase/database';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, database } from '@/firebaseConfig';
import { eventBus } from '@/eventBus';

/**
 * 현재 사용자의 유형을 Firestore에서 가져오는 함수
 * @returns {Promise<string>} - 'regular' 또는 'artist'를 반환
 */
export async function getCurrentUserType() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('사용자가 인증되지 않았습니다.');
  }

  try {
    // 'users' 컬렉션에서 사용자 문서 조회
    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      //console.log(`[getCurrentUserType] 사용자 유형: regular`);
      return 'regular';
    } else {
      // 'users'에 없으면 'artists' 컬렉션 조회
      const artistDocRef = doc(db, 'artists', user.uid);
      const artistDocSnap = await getDoc(artistDocRef);

      if (artistDocSnap.exists()) {
        const artistData = artistDocSnap.data();
        //console.log(`[getCurrentUserType] 사용자 유형: ${artistData.userType}`);
        return artistData.userType; // 'artist' 또는 'OKartist' 반환
      } else {
        throw new Error('사용자 문서를 찾을 수 없습니다.');
      }
    }
  } catch (error) {
    console.error('사용자 유형 져오기 오류:', error);
    throw error;
  }
}

/**
 * 사용자 Presence 시스템을 초기화하는 함수
 */
export async function initializeUserPresence() {
  const auth = getAuth();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        const userType = await getCurrentUserType();
        const collectionName = userType === 'artist' ? 'artists' : 'users';
        const userStatusDatabaseRef = dbRef(database, `/presence/${user.uid}`);
        const userDocUpdateRef = doc(db, collectionName, user.uid);

        //console.log(`[initializeUserPresence] 초기화 시작: ${collectionName}/${user.uid}`);

        // 온라인 상태로 설정
        await set(userStatusDatabaseRef, {
          state: 'online',
          lastChanged: serverTimestamp(),
        });
        //console.log(`[initializeUserPresence] 온라인 상태로 설정됨`);

        // onDisconnect 설정 - 연결이 끊어질 때 실행될 동작
        const onDisconnectRef = onDisconnect(userStatusDatabaseRef);
        await onDisconnectRef.set({
          state: 'offline',
          lastChanged: serverTimestamp(),
        });
        //console.log(`[initializeUserPresence] onDisconnect 설정 완료`);

        // Presence 데이터 변경 감지
        onValue(userStatusDatabaseRef, async (snapshot) => {
          const presence = snapshot.val();
          //console.log(`[initializeUserPresence] Presence 데이터 변경 감지: `, presence);
          if (presence) {
            try {
              await updateDoc(userDocUpdateRef, {
                isOnline: presence.state === 'online',
                lastLogin: presence.state === 'offline' ? presence.lastChanged : null,
              });
              //console.log(`[initializeUserPresence] Firestore 업데이트 완료: isOnline=${presence.state === 'online'}, lastLogin=${presence.lastChanged}`);
            } catch (error) {
              console.error('Firestore 상태 업데이트 오류:', error);
            }
          }
        });

      } catch (error) {
        console.error('Presence 초기화 오류:', error);
      }
    }
  });
}

/**
 * 로그아웃 시 Presence 상태를 'offline'으로 업데이트하는 함수
 */
export async function logoutUser() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const userStatusDatabaseRef = dbRef(database, `/presence/${user.uid}`);
    try {
      await set(userStatusDatabaseRef, {
        state: 'offline',
        lastChanged: serverTimestamp(),
      });
      //console.log(`[logoutUser] Presence 상태를 'offline'으로 설정`);
    } catch (error) {
      console.error('로그아웃 시 Presence 업데이트 실패:', error);
    }
  } else {
    //console.log('로그아웃 시도 시 사용자가 인증되지 않음');
  }

  try {
    await signOut(auth);
    //console.log(`[logoutUser] 사용자 로그아웃 완료`);
    eventBus.emit('user-logged-out');
  } catch (error) {
    console.error('로그아웃 실패:', error);
  }
}

// 로그아웃 버튼 클릭 시 호출되는 함수
export async function handleLogout() {
  try {
    //console.log('로그아웃 버튼 클릭됨');
    await logoutUser();
    //console.log('로그아웃 함수 호출 완료');
  } catch (error) {
    console.error('로그아웃 함수 호출 중 오류:', error);
  }
}
