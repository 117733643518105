<template>
  <div class="post-list-container">
    <!-- 게시판 섹션 -->
    <div class="board-section">
      <!-- 카테고리 선택 -->
      <div class="category-filter">
        <div class="category-table">
          <div 
            v-for="category in categories" 
            :key="category.value"
            :class="['category-cell', { active: selectedCategory === category.value }]"
            @click="selectedCategory = category.value"
          >
            {{ category.label }}
          </div>
        </div>
      </div>

      <!-- 글쓰기 버튼 -->
       <section class="post-list-header">
        <h3>커뮤니티</h3>
        <div class="write-button-container">
          <button class="write-button" @click="goToCreatePost">
            <i class="fas fa-pen"></i>
            글쓰기
          </button>
        </div>
      </section>

      <!-- 게시글 목록 -->
      <div class="posts-container">
        <div v-if="filteredPosts.length === 0" class="no-posts">
          게시글이 없습니다.
        </div>
        <div v-else v-for="post in filteredPosts" 
             :key="post.id" 
             class="post-item"
             @click="goToPostDetail(post.id)">
          <div class="post-layout">
            <div class="post-content-section">
              <div class="post-title-section">
                <span class="category">[{{ getCategoryLabel(post.category) }}]</span>
                <h3>{{ post.title }}</h3>
              </div>
              <div class="post-meta">
                <span class="author">{{ post.authorNickname || post.authorName }}</span>
                <span class="date">{{ formatDate(post.createdAt) }}</span>
                <span v-if="post.updatedAt" style="font-size: 12px;">({{ formatDate(post.updatedAt) }} 수정)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vue 3의 Composition API와 필요한 함수들을 가져옵니다.
import { ref, onMounted, computed } from 'vue';
// Firebase Authentication을 위한 함수를 가져옵니다.
import { getAuth } from 'firebase/auth';
// Firestore 데이터베이스 작업을 위한 함수들을 가져옵니다.
import { doc, getDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
// Firebase 설정을 가져옵니다.
import { db } from '@/firebaseConfig';
// 사용자 타입을 확인하기 위한 서비스 함수를 가져옵니다.
import { getCurrentUserType } from '@/services/AuthService';
// Vue Router를 사용하기 위한 함수를 가져옵니다.
import { useRouter } from 'vue-router';

export default {
  name: 'PostList',
  setup() {
    const router = useRouter();
    // 사용자 프로필 정보를 저장하는 반응형 변수
    const userProfile = ref(null);
    // 사용자 타입(일반/아티스트)을 저장하는 반응형 변수
    const userType = ref('');
    // 게시글 목록을 저장하는 반응형 변수
    const posts = ref([]);
    // 현재 선택된 카테고리를 저장하는 반응형 변수
    const selectedCategory = ref('all');
    // 카테고리 목록 정의
    const categories = [
      { value: 'all', label: '전체' },
      { value: '스튜디오', label: '스튜디오' },
      { value: '장터', label: '장터' },
      { value: '질문', label: '질문' },
      { value: '정보', label: '정보' }
    ];

    // 사용자 프로필 정보를 로드하는 비동기 함수
    const loadUserProfile = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (user) {
          // 현재 사용자의 타입을 가져옵니다.
          const type = await getCurrentUserType();
          userType.value = type;
          
          // 사용자 타입에 따라 적절한 컬렉션에서 프로필 정보를 가져옵니다.
          const collectionName = type === 'artist' ? 'artists' : 'users';
          const userDoc = await getDoc(doc(db, collectionName, user.uid));
          
          if (userDoc.exists()) {
            userProfile.value = {
              ...userDoc.data(),
              email: user.email
            };
          }
        }
      } catch (error) {
        console.error('프로필 로딩 오류:', error);
      }
    };

    // 게시글 목록을 로드하는 비동기 함수
    const loadPosts = async () => {
      try {
        // 게시글 컬렉션에 대한 쿼리를 생성합니다.
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const postsData = [];
        for (const docSnapshot of querySnapshot.docs) {
          const postData = {
            id: docSnapshot.id,
            ...docSnapshot.data()
          };

          // 각 게시글 작성자의 닉네임 정보를 가져옵니다.
          const authorCollectionName = postData.authorType === 'artist' ? 'artists' : 'users';
          const authorDocRef = doc(db, authorCollectionName, postData.authorId);
          const authorDoc = await getDoc(authorDocRef);
          if (authorDoc.exists()) {
            postData.authorName = authorDoc.data().nickname;
          }

          postsData.push(postData);
        }
        
        posts.value = postsData;
      } catch (error) {
        console.error('게시글 로딩 오류:', error);
      }
    };

    // 글쓰기 페이지로 이동하는 함수
    const goToCreatePost = () => {
      router.push('/create-post');
    };

    // 게시글 상세 페이지로 이동하는 함수
    const goToPostDetail = (postId) => {
      router.push(`/post/${postId}`);
    };

    // 선택된 카테고리에 따라 게시글을 필터링하는 computed 속성
    const filteredPosts = computed(() => {
      if (selectedCategory.value === 'all') {
        return posts.value;
      }
      return posts.value.filter(post => post.category === selectedCategory.value);
    });

    // 타임스탬프를 날짜 문자열로 변환하는 함수
    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
    };

    // 카테고리 값을 라벨로 변환하는 함수
    const getCategoryLabel = (categoryValue) => {
      const category = categories.find(c => c.value === categoryValue);
      return category ? category.label : categoryValue;
    };

    // 사용자 타입이 "OKartist"인 경우 접근을 차단하는 함수
    const restrictOKArtistAccess = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          router.push('/login');
          return;
        }

        // 사용자 유형 확인
        const type = await getCurrentUserType();
        userType.value = type;
        console.log('User Type:', userType.value); // 디버깅용 로그

        if (userType.value === 'OKartist') {
          alert('OKartist인 경우 승인 후 이용 가능합니다.');
          router.push('/'); // 메인 페이지 경로를 확인하세요
          return;
        }
      } catch (error) {
        console.error('사용자 유형 확인 실패:', error);
        router.push('/login');
      }
    };

    // 컴포넌트가 마운트될 때 프로필과 게시글을 로드하고, 접근을 제한합니다.
    onMounted(async () => {
      await loadUserProfile();
      await restrictOKArtistAccess();
      // "OKartist"가 아니라면 게시글을 로드합니다.
      if (userType.value !== 'OKartist') {
        await loadPosts();
      }
    });

    // 템플릿에서 사용할 데이터와 메서드들을 반환합니다.
    return {
      userProfile,
      userType,
      posts,
      selectedCategory,
      categories,
      filteredPosts,
      formatDate,
      goToCreatePost,
      goToPostDetail,
      getCategoryLabel
    };
  }
}
</script>

<style scoped>
.post-list-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.post-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.post-list-header h3 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
}

.write-button {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: #333;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.write-button:hover {
  background: #222;
  transform: translateY(-1px);
}

.category-filter {
  margin-bottom: 24px;
}

.category-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 2px;
}

.category-cell {
  padding: 12px;
  text-align: center;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  font-weight: 500;
}

.category-cell:hover {
  background: #f1f3f5;
}

.category-cell.active {
  background: #333;
  color: white;
}

.post-title-section {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.post-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #f1f3f5;
}

.post-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.post-meta {
  color: #666;
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.post-meta span {
  display: inline-flex;
  align-items: center;
}

.category {
  color: #333;
  font-weight: 500;
}

.author {
  color: #666;
}

.date {
  color: #999;
}

h3 {
  margin: 0;
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

.no-posts {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 12px;
}

</style>
