// 아티스트 정보 페이지

<template>
  <div class="profile-page">
    <!-- 프로필 정보 섹션 -->
    <div class="profile-container">
      <!-- 프로필 헤더 (이미지, 이름, 위치) -->
      <div class="profile-header">
        <div class="profile-image-wrapper">
          <img 
            :src="user.profileImage || require('@/assets/default-profile.jpg')" 
            alt="프로필 이미지" 
            class="profile-image" 
          />
        </div>
        <div class="profile-info">
          <h2>{{ user.name || '이름 없음' }}</h2>
          <div class="profile-location">
            <i class="material-icons">location_on</i>
            <span>{{ shortLocation }}</span>
          </div>
        </div>
      </div>
      <div class="snsbutton">
        <button class="instagram" @click="instagram">인스타그램</button>
        <button class="kakaotalk" @click="kakaotalk">상담하기</button>
      </div>
      <p class="profile-bio">{{ user.introduction || '소개글이 없습니다.' }}</p>

      <!-- 뱃지 섹션 (경력, 샵, 장르, 평점) -->
      <div class="badge-container">
        <div class="badge-wrapper">
          <div class="badge cost-badge" v-if="user.mincost">
            <i class="material-icons">paid</i>
            <span class="badge-text">최소 {{ user.mincost }}만원</span>
          </div>
          <div class="badge cost-badge" v-if="user.hourcost">
            <i class="material-icons">timer</i>
            <span class="badge-text">시간당 {{ user.hourcost }}만원</span>
          </div>
          <div class="badge" v-if="user.career">
            <i class="material-icons">work</i>
            <span class="badge-text">경력 {{ user.career }}년</span>
          </div>
          <div class="badge" v-if="user.shopName">
            <i class="material-icons">store</i>
            <span class="badge-text">{{ user.shopName }}</span>
          </div>
          <div 
            v-for="genre in user.genres" 
            :key="genre" 
            class="badge genre-badge"
          >
            <i class="material-icons">palette</i>
            <span class="badge-text">{{ genre }}</span>
          </div>
          <div class="badge" v-if="user.reviewRating">
            <i class="material-icons">star</i>
            <span class="badge-text">{{ user.reviewRating }} / 5</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 컨텐츠 섹션 (포트폴리오/디자인 탭) -->
    <div class="content-section">
      <!-- 탭 버튼 -->
      <div class="tab-buttons">
        <button 
          class="tab-button" 
          :class="{ active: activeTab === 'portfolio' }"
          @click="activeTab = 'portfolio'"
        >
          <i class="material-icons">grid_on</i>
          포트폴리오
        </button>
        <button 
          class="tab-button" 
          :class="{ active: activeTab === 'design' }"
          @click="activeTab = 'design'"
        >
          <i class="material-icons">palette</i>
          디자인
        </button>
      </div>

      <!-- 포트폴리오 그리드 -->
      <div v-if="activeTab === 'portfolio'" class="grid-container">
        <div 
          v-for="portfolio in userPortfolios" 
          :key="portfolio.id" 
          class="grid-item"
          @click="openPortfolioModal(portfolio)"
        >
          <div class="image-container">
            <img :src="portfolio.imageUrl" :alt="portfolio.description">
          </div>
        </div>
      </div>

      <!-- 디자인 그리드 -->
      <div v-if="activeTab === 'design'" class="grid-container">
        <div 
          v-for="design in userDesigns" 
          :key="design.id" 
          class="grid-item"
          @click="openDesignModal(design)"
        >
          <div class="image-container">
            <img :src="design.imageUrl" :alt="design.description">
          </div>
        </div>
      </div>
    </div>

    <!-- 상세 이미지 모달 -->
    <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <button class="modal-close" @click="closeModal">
          <i class="material-icons">close</i>
        </button>
        <div class="modal-header">
          <div class="artist-info">
            <img :src="user.profileImage" alt="아티스트 프로필" class="artist-avatar" />
            <div class="artist-details">
              <h3>{{ user.name }}</h3>
              <p class="location">{{ shortLocation }}</p>
            </div>
          </div>
        </div>
        <!-- 모달 이미지 섹션 -->
        <div class="modal-image-section">
          <img :src="selectedImage.imageUrl" alt="상세 이미지" />
        </div>
        
        <div class="modal-details">
          <!-- 설명 섹션 -->
          <div class="detail-item description">
            <p>{{ selectedImage.description }}</p>
          </div>

          <!-- 장르 태그 섹션 -->
          <div class="detail-item genres">
            <div class="genre-tags">
              <span class="genre-tag">
                {{ selectedImage.duration }}시간 소요
              </span>
              <span 
                v-for="genre in selectedImage.genres" 
                :key="genre" 
                class="genre-tag"
              >
                {{ genre }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 하단 네비게이션 바 -->
    <div class="bottom-navigation">
      <button 
        v-if="currentUser && currentUser.userType !== 'artist'"
        class="nav-button favorite-button" 
        @click="toggleFavorite"
        :class="{ 'is-favorite': isFavorite }"
      >
        <span class="material-icons">{{ isFavorite ? 'favorite' : 'favorite_border' }}</span>
        즐겨찾기
      </button>
      <button class="nav-button chat-button" @click="startChat">
        <span class="material-icons">chat</span>
        메세지
      </button>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebaseConfig';

export default {
  name: 'ProfilePage',
  props: ['id'],
  data() {
    return {
      user: {
        name: '',
        location: '',
        profileImage: '',
        shopName: '',
        mincost: '',
        hourcost: '',
        career: 0,
        genres: [],
        reviewRating: 0,
        introduction: '',
        instagram: '',
        kakaotalk: ''
      },
      userPortfolios: [],
      userDesigns: [],
      userReviews: [],
      currentUser: null,
      activeTab: 'portfolio',
      selectedImage: null,
      isModalOpen: false,
      isFavorite: false
    };
  },
  computed: {
    isUserLoggedIn() {
      return !!this.currentUser;
    },
    shortLocation() {
      if (typeof this.user.location === 'string') {
        return this.user.location.split(' ').slice(0, 3).join(' ');
      } else if (this.user.location && this.user.location.address) {
        return this.user.location.address.split(' ').slice(0, 3).join(' ');
      } else {
        return '위치 정보 없음';
      }
    }
  },
  methods: {
    // 이미지 모달 관련 메서드
    openPortfolioModal(portfolio) {
      this.selectedImage = {
        ...portfolio,
        type: 'portfolio'
      };
      this.isModalOpen = true;
      // 현재 URL에 상태 추가
      if (!window.history.state?.modalOpen) {
        window.history.pushState({ modalOpen: true }, '');
      }
    },
    openDesignModal(design) {
      this.selectedImage = {
        ...design,
        type: 'design'
      };
      this.isModalOpen = true;
      // 현재 URL에 상태 추가
      if (!window.history.state?.modalOpen) {
        window.history.pushState({ modalOpen: true }, '');
      }
    },
    closeModal() {
      this.selectedImage = null;
      this.isModalOpen = false;
      // 모달이 열려있고 히스토리 상태가 있을 때만 뒤로가기
      if (window.history.state?.modalOpen) {
        window.history.back();
      }
    },
    // 데이터 로드 메서드들
    async loadArtistData() {
      try {
        const artistDoc = await getDoc(doc(db, 'artists', this.id));
        if (artistDoc.exists()) {
          const artistData = artistDoc.data();
          this.user = {
            name: artistData.nickname || '이름 없음',
            location: artistData.location || '위치 정보 없음',
            profileImage: artistData.profileImageUrl || 'default-profile.jpg',
            shopName: artistData.shopName || '샵 정보 없음',
            mincost: (artistData.mincost || 0) / 10000,
            hourcost: (artistData.hourcost || 0) / 10000,
            career: artistData.career || 0,
            genres: artistData.genres || [],
            reviewRating: artistData.reviewRating || 0,
            introduction: artistData.introduction || '',
            instagram: artistData.instagram || '',
            kakaotalk: artistData.kakaotalk || ''
          };
        } else {
          console.error('아티스트 문서가 존재하지 않습니다.');
        }
      } catch (error) {
        console.error('아티스트 정보 로드 실패:', error);
      }
    },
    async loadArtistPortfolios() {
      try {
        const portfoliosQuery = query(
          collection(db, 'portfolios'),
          where('artistId', '==', this.id)
        );
        const portfoliosSnapshot = await getDocs(portfoliosQuery);
        
        this.userPortfolios = portfoliosSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            imageUrl: data.images?.[0] || '',
            description: data.description || '설명이 없습니다.',
            genres: data.genres || [],
            price: data.price || 0,
            duration: data.duration || '정보 없음',
            likes: data.likes || 0,
            comments: data.comments || 0
          };
        });
      } catch (error) {
        console.error('포트폴리오 로드 실패:', error);
      }
    },
    async loadArtistDesigns() {
      try {
        const designsQuery = query(
          collection(db, 'designs'),
          where('artistId', '==', this.id)
        );
        const designsSnapshot = await getDocs(designsQuery);
        
        this.userDesigns = designsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            imageUrl: data.images?.[0] || '',
            description: data.description || '설명이 없습니다.',
            genres: data.genres || [],
            price: data.price || 0,
            likes: data.likes || 0,
            comments: data.comments || 0
          };
        });
      } catch (error) {
        console.error('디자인 로드 실패:', error);
      }
    },
    async loadUserReviews() {
      // 리뷰 데이터를 불러오는 로직 추가 예정
    },
    async checkFavoriteStatus() {
      if (!this.isUserLoggedIn) return;

      try {
        let userDoc = await getDoc(doc(db, 'users', this.currentUser.uid));
        
        if (!userDoc.exists()) {
          userDoc = await getDoc(doc(db, 'artists', this.currentUser.uid));
        }

        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.isFavorite = userData.favorites?.includes(this.id) || false;
        }
      } catch (error) {
        console.error('즐겨찾기 상태 확인 실패:', error);
      }
    },

    async toggleFavorite() {
      if (!this.currentUser) {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/login');
        return;
      }

      try {
        let userRef = doc(db, 'users', this.currentUser.uid);
        let userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          userRef = doc(db, 'artists', this.currentUser.uid);
          userDoc = await getDoc(userRef);
        }

        if (userDoc.exists()) {
          if (this.isFavorite) {
            await updateDoc(userRef, {
              favorites: arrayRemove(this.id)
            });
          } else {
            await updateDoc(userRef, {
              favorites: arrayUnion(this.id)
            });
          }
          
          this.isFavorite = !this.isFavorite;
          alert(this.isFavorite ? '즐겨찾기에 추가되었습니다.' : '즐겨찾기가 해제되었습니다.');
        }
      } catch (error) {
        console.error('즐겨찾기 토글 실패:', error);
        alert('즐겨찾기 처리 중 오류가 발생했습니다.');
      }
    },

    async instagram() {
      window.open(`https://www.instagram.com/${this.user.instagram}/`, '_blank');
    },

    async kakaotalk() {
      window.open(`${this.user.kakaotalk}`, '_blank');
    },

    async startChat() {
      if (!this.currentUser) {
        alert('로그인이 필요한 서비스입니다.');
        this.$router.push('/login');
        return;
      }

      try {
        const chatQuery = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', this.currentUser.uid)
        );
        const querySnapshot = await getDocs(chatQuery);
        let chatId = null;

        querySnapshot.forEach((doc) => {
          const chatData = doc.data();
          if (chatData.participants.includes(this.id)) {
            chatId = doc.id;
          }
        });

        if (!chatId) {
          const newChatRef = doc(collection(db, 'chats'));
          await setDoc(newChatRef, {
            participants: [this.currentUser.uid, this.id],
            createdAt: new Date(),
            lastMessage: '',
            lastMessageTime: new Date()
          });
          chatId = newChatRef.id;
        }

        this.$router.push(`/chat/${chatId}`);
      } catch (error) {
        console.error('채팅방 생성 실패:', error);
        alert('채팅 시작 중 오류가 발생했습니다.');
      }
    },

    // Firebase Auth의 현재 사용자 상태를 확인하는 메서드
    checkAuthState() {
      const auth = getAuth();
      this.currentUser = auth.currentUser;
      
      // Auth 상태 변경 감지
      auth.onAuthStateChanged((user) => {
        this.currentUser = user;
        if (user) {
          this.checkFavoriteStatus();
        }
      });
    },
    handlePopState() {
      if (this.isModalOpen) {
        this.selectedImage = null;
        this.isModalOpen = false;
      }
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  },
  async created() {
    this.checkAuthState(); // Auth 상태 확인
    await this.loadArtistData();
    await this.loadArtistPortfolios();
    await this.loadArtistDesigns();
    await this.loadUserReviews();
  }
};
</script>

<style scoped>
.profile-page {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 10px;
  padding-bottom: 100px;
}

.profile-container {
  background: white;
  border-radius: 16px;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-header {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.profile-image-wrapper {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.profile-info h2{
  margin: 0;
}

.profile-location {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #65676b;
  font-size: 14px;
}

.profile-bio {
  font-size: 1rem;
  line-height: 1.5;
  color: #262626;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  margin: 20px 0;
  white-space: pre-wrap;
}

.badge-container {
  margin-top: 8px;
  padding: 0;
  background: transparent;
  width: 100%;
}

.badge-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  margin-bottom: 4px;
}

.badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.badge i {
  font-size: 16px;
  margin-right: 6px;
  color: #636e72;
}

.badge-text {
  font-size: 13px;
  font-weight: 500;
  color: #2d3436;
  white-space: nowrap;
}

.badge:has(i.material-icons:contains('work')) {
  background-color: #e3f2fd;
}

.badge:has(i.material-icons:contains('store')) {
  background-color: #f3e5f5;
}

.genre-badge {
  background-color: #e8f5e9;
}

.cost-badge {
  background-color: #ffeaa7;
}

.badge:has(i.material-icons:contains('star')) {
  background-color: #fff3e0;
}

.snsbutton {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 5px 0;
  gap: 10px;
}

.instagram, .kakaotalk {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background: #eeeeee;
}

.content-section {
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  overflow: hidden;
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #dbdbdb;
}

.tab-button {
  flex: 1;
  padding: 16px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #65676b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.tab-button i {
  font-size: 20px;
}

.tab-button.active {
  color: #0095f6;
  border-bottom: 2px solid #0095f6;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  background-color: #eeeeee;
  padding: 1px;
}

.grid-item {
  position: relative;
  cursor: pointer;
  background: white;
}

.image-container {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.grid-item:hover .image-container img {
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.modal {
  background-color: #ffffff;
  border-radius: 12px;
  width: 85%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease;
  padding: 10px;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  z-index: 1;
}

.modal-image-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.modal-image-section img {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  border-radius: 8px;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid #dbdbdb;
}

.artist-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.artist-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-details h3 {
  margin: 0;
  font-weight: 600;
}

.location {
  margin: 0;
  font-size: 12px;
  color: #8e8e8e;
}

.modal-details {
  padding: 16px;
  overflow-y: auto;
}

.detail-item {
  margin-bottom: 20px;
}

.description {
  font-size: 15px;
  line-height: 1.5;
  color: #262626;
  padding: 0 4px;
}

.genres {
  margin: 16px 0;
}

.genre-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.genre-tag {
  padding: 6px 12px;
  background-color: #f0f2f5;
  border-radius: 20px;
  font-size: 13px;
  color: #262626;
}

.price-time-info {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 10px 16px 16px 16px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.info-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.info-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.info-value {
  color: #262626;
  font-size: 15px;
  font-weight: 600;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 12px 16px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: #f0f2f5;
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-button:hover {
  background: #e4e6eb;
}

.nav-button .material-icons {
  font-size: 20px;
}

.favorite-button.is-favorite {
  background: #ffebee;
  color: #e53935;
}

.favorite-button.is-favorite:hover {
  background: #ffcdd2;
}

.chat-button {
  background: #e3f2fd;
  color: #1976d2;
}

.chat-button:hover {
  background: #bbdefb;
}

@media (max-width: 480px) {
  .profile-image-wrapper {
    width: 80px;
    height: 80px;
  }

  .profile-container {
    padding: 16px;
  }

  .badge-wrapper {
    gap: 6px;
  }
  .modal-image-section img {
    max-height: 300px;
  }
}
</style> 