// 소개 페이지

<template>
  <div>
    <h1>About 페이지</h1>
    <!-- 내용 추가 -->
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
};
</script>

<style scoped>
/* 스타일 추가 */
</style> 