// 일반사용자 페이지

<template>
  <div class="user-page">
    <div v-if="isLoading" class="loading">
      로딩중...
    </div>
    <div v-else>
      <!-- 상단 프로필 섹션 -->
      <div class="profile-header">
        <h2>{{nickname}}님의 프로필 정보</h2>
        <button class="edit-button" @click="openEditModal">수정</button>
      </div>

      <!-- 프로필 정보 섹션 -->
      <div class="profile-info">
        <div>
          <div v-if="profileImageUrl">
            <img :src="profileImageUrl" alt="프로필 이미지" class="profile-image" />
          </div>
          <div v-else>
            <img src="@/assets/default-profile.jpg" alt="기본 프로필 이미지" class="profile-image" />
          </div>
        </div>
        <div class="profile-details">
          <p class="nickname">{{ nickname }}</p>
        </div>
      </div>

      <!-- 하단 버튼 섹션 -->
      <div class="action-buttons">
        <table class="menu-table">
          <tbody>
            <tr>
              <td class="menu-item" @click="showFavorites">
                <span>즐겨찾기</span>
              </td>
              <td class="menu-item" @click="startChat">
                <span>채팅</span>
                <span v-if="unreadCount" class="unread-badge">{{ unreadCount }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 즐겨찾기 섹션 -->
      <div v-if="activeSection === 'favorites'" class="favorites-section">
        <h3>즐겨찾기 목록</h3>
        <div class="artists-gallery">
          <div
            v-for="artist in favoriteArtists"
            :key="artist.id"
            class="artist-card"
          >
            <!-- 아티스트 정보 -->
            <div class="artist-info">
              <div class="artist-info-left">
                <img :src="artist.profileImage" alt="프로필 이미지" class="artist-profile-image" />
                <div class="artist-details">
                  <h3>{{ artist.nickname }}</h3>
                  <p>{{ artist.location }}</p>
                </div>
              </div>
              <router-link :to="`/profile/${artist.id}`" class="card-profile-button">
                프로필
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- 수정 모달 -->
      <div v-if="isEditModalOpen" class="modal-overlay">
        <div class="modal">
          <h3>프로필 수정</h3>
          <div class="edit-form">
            <div class="profile-image-edit">
              <label>프로필 이미지</label>
              <div class="image-preview">
                <img 
                  :src="previewImage || profileImageUrl || require('@/assets/default-profile.jpg')" 
                  alt="프로필 이미지" 
                  class="preview-profile-image" 
                />
              </div>
              <input 
                type="file" 
                id="profileImage" 
                @change="handleImageChange" 
                accept="image/*"
                class="file-input"
              />
              <button @click="triggerFileInput" class="image-upload-button">이미지 선택</button>
            </div>

            <div class="nickname-edit">
              <label for="editNickname">닉네임</label>
              <input type="text" id="editNickname" v-model="editNickname" />
            </div>

            <div class="modal-buttons">
              <button @click="saveProfile" class="save-button">저장</button>
              <button @click="closeEditModal" class="cancel-button">취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebaseConfig';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export default {
  name: 'UserPage',
  data() {
    return {
      nickname: '',
      email: '',
      profileImageUrl: null,
      favoriteArtists: [],
      activeSection: 'favorites',
      isEditModalOpen: false,
      editNickname: '',
      currentUser: null,
      isLoading: true,
      previewImage: null,
      newImageFile: null,
      unreadCount: 0,
    };
  },
  
  created() {
    const auth = getAuth();
    this.unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.currentUser = user;
        try {
          await this.loadUserData();
          await this.loadFavoriteArtists();
          await this.loadUnreadCount();
        } catch (error) {
          console.error('데이터 로드 실패:', error);
        }
      } else {
        this.$router.push('/login');
      }
      this.isLoading = false;
    });
  },

  mounted() {
    window.addEventListener('popstate', () => {
      if (this.isEditModalOpen) this.closeEditModal();
    });
  },

  methods: {
    async loadUserData() {
      try {
        const userDocRef = doc(db, 'users', this.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.nickname = userData.nickname || '이름 없음';
          this.email = this.currentUser.email;
          this.profileImageUrl = userData.profileImageUrl || null;
        } else {
          this.nickname = '이름 없음';
          this.email = this.currentUser.email;
        }
      } catch (error) {
        console.error('사용자 데이터 로드 실패:', error);
        this.nickname = '이름 없음';
        this.email = this.currentUser?.email || '';
      }
    },

    async loadFavoriteArtists() {
      try {
        const userDocRef = doc(db, 'users', this.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
          this.favoriteArtists = [];
          return;
        }

        const userData = userDoc.data();
        const favorites = userData.favorites || [];
        const validArtists = [];

        for (const artistId of favorites) {
          try {
            const artistDocRef = doc(db, 'artists', artistId);
            const artistDoc = await getDoc(artistDocRef);

            if (artistDoc.exists()) {
              const artistData = artistDoc.data();

              // 포트폴리오 이미지 가져오기
              const portfoliosRef = collection(db, `artists/${artistId}/portfolios`);
              const portfolioSnapshot = await getDocs(portfoliosRef);
              const portfolioImages = [];

              // 모든 포트폴리오 문서의 이미지 수집
              portfolioSnapshot.forEach(doc => {
                const portfolioData = doc.data();
                if (portfolioData.images && portfolioData.images.length > 0) {
                  portfolioImages.push(...portfolioData.images);
                }
              });

              // 드래그 기능을 위한 상태값들과 함께 아티스트 정보 저장
              validArtists.push({
                id: artistId,
                profileImage: artistData.profileImageUrl || require('@/assets/default-profile.jpg'),
                portfolioImages: portfolioImages.length > 0 ? portfolioImages : [require('@/assets/default-profile.jpg')],
                portfolioInfo: portfolioSnapshot.docs.map(doc => ({
                  price: doc.data().price || 0,
                  description: doc.data().description || '설명이 없습니다.',
                  genres: doc.data().genres || '장르 미지정'
                })),
                nickname: artistData.nickname || '이름 없음',
                location: artistData.location?.address?.split(' ').slice(0, -2).join(' ') || '위치 정보 없음',
                currentImageIndex: 0,
                currentTranslate: 0,
                prevTranslate: 0,
                isDragging: false,
                startPos: 0
              });
            }
          } catch (error) {
            console.error(`아티스트 ${artistId} 처리 중 오류:`, error);
          }
        }

        this.favoriteArtists = validArtists;
      } catch (error) {
        console.error('즐겨찾기 아티스트 로드 실패:', error);
        this.favoriteArtists = [];
      }
    },

    showFavorites() {
      this.activeSection = 'favorites';
    },

    startChat() {
      this.$router.push('/chats');
    },

    goToArtistProfile(artistId) {
      this.$router.push(`/profile/${artistId}`);
    },

    openEditModal() {
      this.isEditModalOpen = true;
      this.editNickname = this.nickname;
      history.pushState(null, '', window.location.pathname);
    },
    closeEditModal() {
      this.isEditModalOpen = false;
      this.previewImage = null;
      this.newImageFile = null;
    },
    async saveProfile() {
      try {
        const userDocRef = doc(db, 'users', this.currentUser.uid);
        const updateData = {
          nickname: this.editNickname
        };

        // 새 이미지가 선택된 우
        if (this.newImageFile) {
          const storage = getStorage();
          
          // 기존 이미지 삭제
          if (this.profileImageUrl) {
            try {
              const pathStartIndex = this.profileImageUrl.indexOf('/o/') + 3;
              const pathEndIndex = this.profileImageUrl.indexOf('?');
              const oldImagePath = decodeURIComponent(
                this.profileImageUrl.substring(pathStartIndex, pathEndIndex)
              );
              const oldImageRef = storageRef(storage, oldImagePath);
              await deleteObject(oldImageRef);
            } catch (error) {
              // 기존 이미지 삭제 실패 처리
            }
          }

          // 새 이미지 업로드
          const newImageRef = storageRef(
            storage, 
            `profile-images/${this.currentUser.uid}/${Date.now()}_${this.newImageFile.name}`
          );
          await uploadBytes(newImageRef, this.newImageFile);
          const newImageUrl = await getDownloadURL(newImageRef);
          updateData.profileImageUrl = newImageUrl;
        }

        await updateDoc(userDocRef, updateData);

        // 로컬 상태 업데이트
        this.nickname = this.editNickname;
        if (updateData.profileImageUrl) {
          this.profileImageUrl = updateData.profileImageUrl;
        }

        this.closeEditModal();
      } catch (error) {
        console.error('프로필 저장 실패:', error);
      }
    },

    triggerFileInput() {
      document.getElementById('profileImage').click();
    },

    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.newImageFile = file;
        this.previewImage = URL.createObjectURL(file);
      }
    },

    // 터치 이벤트 핸들러
    touchStart(e, artist) {
      artist.startPos = e.touches[0].clientX;
      artist.isDragging = true;
      artist.currentTranslate = artist.prevTranslate;
    },

    touchMove(e, artist) {
      if (artist.isDragging) {
        const currentPosition = e.touches[0].clientX;
        const diff = currentPosition - artist.startPos;
        const percentMove = (diff / window.innerWidth) * 100;
        artist.currentTranslate = artist.prevTranslate + percentMove;
      }
    },

    touchEnd(artist) {
      artist.isDragging = false;
      const movedBy = artist.currentTranslate - artist.prevTranslate;

      if (Math.abs(movedBy) > 20) {
        if (movedBy < 0 && artist.currentImageIndex < artist.portfolioImages.length - 1) {
          artist.currentImageIndex++;
        } else if (movedBy > 0 && artist.currentImageIndex > 0) {
          artist.currentImageIndex--;
        }
      }

      artist.currentTranslate = artist.currentImageIndex * -100;
      artist.prevTranslate = artist.currentTranslate;
    },

    // 마우스 드래그 이벤트 핸들러
    dragStart(e, artist) {
      artist.startPos = e.clientX;
      artist.isDragging = true;
      artist.currentTranslate = artist.prevTranslate;
    },

    dragMove(e, artist) {
      if (artist.isDragging) {
        const currentPosition = e.clientX;
        const diff = currentPosition - artist.startPos;
        const percentMove = (diff / window.innerWidth) * 100;
        artist.currentTranslate = artist.prevTranslate + percentMove;
      }
    },

    dragEnd(artist) {
      artist.isDragging = false;
      const movedBy = artist.currentTranslate - artist.prevTranslate;

      if (Math.abs(movedBy) > 20) {
        if (movedBy < 0 && artist.currentImageIndex < artist.portfolioImages.length - 1) {
          artist.currentImageIndex++;
        } else if (movedBy > 0 && artist.currentImageIndex > 0) {
          artist.currentImageIndex--;
        }
      }

      artist.currentTranslate = artist.currentImageIndex * -100;
      artist.prevTranslate = artist.currentTranslate;
    },

    async loadUnreadCount() {
      if (!this.currentUser) return;
      
      try {
        const chatsRef = collection(db, 'chats');
        const q = query(
          chatsRef,
          where('participants', 'array-contains', this.currentUser.uid)
        );
        
        const querySnapshot = await getDocs(q);
        let count = 0;
        
        querySnapshot.forEach(doc => {
          const chatData = doc.data();
          if (chatData.unreadCount && chatData.unreadCount[this.currentUser.uid]) {
            count += chatData.unreadCount[this.currentUser.uid];
          }
        });
        
        this.unreadCount = count;
      } catch (error) {
        console.error('Failed to load unread count:', error);
      }
    }
  },
};
</script>

<style scoped>
.user-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.edit-button {
  padding: 8px 16px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 10px;
}

.edit-button:hover {
  background-color: #636e72;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.profile-details {
  text-align: center;
}

.nickname, .location {
  font-size: 18px;
  color: #333333;
  margin: 5px 0;
}

.action-buttons {
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  margin: 20px 0;
}

.menu-table {
  width: 100%;
  border-collapse: collapse;
}

.menu-table tr {
  display: flex;
  justify-content: space-around;
}

.menu-item {
  flex: 1;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
}

.menu-item:hover {
  background-color: #f5f6fa;
}

.menu-item span {
  font-size: 14px;
  font-weight: 500;
}

.favorites-section {
  width: 100%;
  margin-top: 20px;
}

.favorites-section h3 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
}

/* 아티스트 갤러리 그리드 레이아웃 */
.artists-gallery {
  gap: 10px;
  width: 100%;
  max-width: 1200px;
}

/* 아티스트 카드 스타일 */
.artist-card {
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fafafa;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.artist-card:hover {
  transform: scale(1.02);
}
/* 아티스트 정보 스타일 */
.artist-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  width: 100%;
  background-color: #ffffff;
}

.artist-info-left {
  display: flex;
  align-items: center;
}

.artist-details {
  margin-left: 16px;
}

.artist-details h3 {
  margin: 0;
  font-size: 16px;
  color: #333333;
}

.artist-details p {
  margin: 1px 0;
  color: #777777;
  font-size: 12px;
}

/* 프로필 버튼 스타일 */
.card-profile-button {
  padding: 8px 16px;
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.2s;
  margin-right: 30px;
}

.card-profile-button:hover {
  background-color: #1e8449;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-form label {
  font-size: 16px;
  color: #333333;
  margin-bottom: 5px;
}

.edit-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button, .cancel-button {
  padding: 10px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  flex: 1;
  margin: 0 5px;
}

.save-button:hover, .cancel-button:hover {
  background-color: #636e72;
}

.cancel-button {
  background-color: #b2bec3;
}

.cancel-button:hover {
  background-color: #dfe6e9;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #2d3436;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-image-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.image-preview {
  width: 150px;
  height: 150px;
  margin: 10px auto;
}

.preview-profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.file-input {
  display: none;
}

.image-upload-button {
  padding: 8px 16px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.image-upload-button:hover {
  background-color: #636e72;
}

.nickname-edit {
  margin-bottom: 20px;
}

.artist-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-nickname {
  font-size: 16px;
  color: #333333;
  margin: 0;
}

.menu-item {
  position: relative; /* 알림 배지 위치를 위해 추가 */
}

.unread-badge {
  position: absolute;
  top: 5px;
  right: 25%;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 6px 6px;
  font-size: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .unread-badge {
    top: 3px;
    right: 20%;
    font-size: 8px;
    min-width: 16px;
    height: 16px;
  }
}
</style> 