<template>
  <div class="ai-choice-container">
    <div id="map" class="map"></div>
    <!-- 내 위치 버튼 -->
    <button @click="moveToMyLocation" class="my-location-btn">
      <span class="material-icons">my_location</span>
    </button>
    <!-- 장르 필터 버튼 -->
    <div class="genre-filter">
      <button @click="toggleGenreFilter" class="filter-btn">
        <span class="material-icons">filter_list</span>
      </button>
      <div v-if="showGenreFilter" class="genre-list">
        <div class="genre-item">
          <input type="checkbox" id="all" v-model="selectedGenres" value="all" @change="handleAllGenres">
          <label for="all">전체 보기</label>
        </div>
        <div v-for="genre in availableGenres" :key="genre" class="genre-item">
          <input type="checkbox" :id="genre" v-model="selectedGenres" :value="genre" @change="handleGenreChange">
          <label :for="genre">{{ genre }}</label>
        </div>
      </div>
    </div>
    <!-- 로딩 상태 표시 -->
    <div v-if="loading" class="loading">
      타투이스트를 로드 중입니다...
    </div>
    <!-- 에러 메시지 표시 -->
    <div v-if="error" class="error">
      {{ error }}
    </div>
    
    <!-- 현재 보이는 아티스트 리스트 표시 -->
    <div v-if="visibleArtists.length > 0" class="artist-list" :class="{ 'slide-up': isSlideUp }">
      <div class="slide-handle" @click="toggleSlide">
        <span class="handle-text">{{ isSlideUp ? '▼' : '▲' }}</span>
      </div>
      <h2>현재 지도에 표시되는 아티스트</h2>
      <ul>
        <li v-for="artist in visibleArtists" :key="artist.id" class="artist-item">
          <div class="artist-image-container">
            <img :src="artist.profileImageUrl || '/default-profile.png'" alt="프로필 이미지" class="artist-image"/>
            <div class="artist-info">
              <h3 style="display: inline;">{{ artist.nickname }}</h3> <p style="display: inline;">{{ artist.location.address.split(' ').slice(1, 3).join(' ') }}</p>
              <p class="genre">{{ artist.genres ? artist.genres.slice(0,2).join(', ') : '장르 미지정' }}</p>
              <button @click="goToProfile(artist.id)" class="view-profile-button">프로필 보기</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    
    <!-- 보이는 아티스트가 없을 때 표시 -->
    <div v-else-if="!loading && !error" class="no-artists">
      현재 지도에 표시되는 타투이스트가 없습니다.
    </div>
  </div>
</template>

<script>
/* global google */
import { ref, onMounted, onUnmounted } from 'vue';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { useRouter } from 'vue-router';

export default {
  name: 'TattooChoice',
  setup() {
    const router = useRouter();
    const map = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const isSlideUp = ref(false);

    // 마커 배열과 아티스트 매핑
    const markers = ref([]);
    const artists = ref([]);
    
    // 현재 지도에 보이는 아티스트 리스트
    const visibleArtists = ref([]);
    
    // 현재 열려있는 InfoWindow를 추적
    const currentInfoWindow = ref(null);

    // 장르 필터 관련 상태
    const showGenreFilter = ref(false);
    const availableGenres = ref([]);
    const selectedGenres = ref(['all']);

    const toggleGenreFilter = () => {
      showGenreFilter.value = !showGenreFilter.value;
    };

    const handleAllGenres = () => {
      if (selectedGenres.value.includes('all')) {
        // '전체 보기'가 선택되면 다른 모든 장르 선택 해제
        selectedGenres.value = ['all'];
      }
      filterMarkers();
    };

    const handleGenreChange = () => {
      // 장르가 선택되면 '전체 보기' 해제
      if (selectedGenres.value.length > 0 && selectedGenres.value.includes('all')) {
        selectedGenres.value = selectedGenres.value.filter(genre => genre !== 'all');
      }
      // 아무 장르도 선택되지 않으면 '전체 보기' 자동 선택
      if (selectedGenres.value.length === 0) {
        selectedGenres.value = ['all'];
      }
      filterMarkers();
    };

    const filterMarkers = () => {
      if (!markers.value) return;

      const showAll = selectedGenres.value.includes('all');
      
      markers.value.forEach((marker, index) => {
        const artist = artists.value[index];
        const artistGenres = artist.genres || [];
        
        const isVisible = showAll || artistGenres.some(genre => selectedGenres.value.includes(genre));
        marker.setVisible(isVisible);
      });

      updateVisibleArtists();
    };

    const toggleSlide = () => {
      isSlideUp.value = !isSlideUp.value;
    };

    // 내 위치로 이동하는 함수
    const moveToMyLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            
            map.value.setCenter(pos);
            map.value.setZoom(15);
          },
          (error) => {
            console.error('Geolocation error:', error);
            alert('위치 정보를 가져올 수 없습니다.');
          }
        );
      } else {
        alert('이 브라우저에서는 위치 서비스를 지원하지 않습니다.');
      }
    };

    // 유효한 위도 값인지 확인하는 함수
    const isValidLatitude = (lat) => typeof lat === 'number' && lat >= -90 && lat <= 90;

    // 유효한 경도 값인지 확인하는 함수
    const isValidLongitude = (lng) => typeof lng === 'number' && lng >= -180 && lng <= 180;

    // Firestore에서 아티스트 정보를 가져오는 함수
    const loadArtists = async () => {
      try {
        const artistsRef = collection(db, 'artists');
        const querySnapshot = await getDocs(artistsRef);
        const loadedArtists = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // location 정보가 유효한 아티스트만 필터링
        const validArtists = loadedArtists.filter(artist => {
          if (artist.userType === 'OKartist') {
            return false;
          }

          if (!artist.location) {
            return false;
          }

          const { latitude, longitude } = artist.location;

          if (!isValidLatitude(latitude)) {
            console.warn(`아티스트 ${artist.nickname}의 위도(latitude)가 유효하지 않습니다: ${latitude}`);
            return false;
          }

          if (!isValidLongitude(longitude)) {
            console.warn(`아티스트 ${artist.nickname}의 경도(longitude)가 유효하지 않습니다: ${longitude}`);
            return false;
          }

          return true;
        });

        // 사용 가능한 모든 장르 수집
        const genres = new Set();
        validArtists.forEach(artist => {
          if (artist.genres && Array.isArray(artist.genres)) {
            artist.genres.forEach(genre => genres.add(genre));
          }
        });
        availableGenres.value = Array.from(genres);

        artists.value = validArtists;
        return validArtists;
      } catch (err) {
        console.error('Firestore에서 아티스트 정보를 불러오는 중 오류 발생:', err);
        throw new Error('아티스트 정보를 불러오는 중 문제가 발생했습니다.');
      }
    };

    // 현재 지도 뷰포트에 보이는 아티스트를 업데이트하는 함수
    const updateVisibleArtists = () => {
      if (!map.value) return;
      const bounds = map.value.getBounds();
      if (!bounds) return;

      visibleArtists.value = artists.value.filter(artist => {
        // location 필드 존재 여부 확인
        if (!artist.location) {
          console.warn(`아티스트 ${artist.nickname}의 location 정보가 없습니다.`);
          return false;
        }

        const { latitude, longitude } = artist.location;

        // latitude와 longitude의 유효성 검사
        if (!isValidLatitude(latitude)) {
          console.warn(`아티스트 ${artist.nickname}의 위도(latitude)가 유효하지 않습니다: ${latitude}`);
          return false;
        }

        if (!isValidLongitude(longitude)) {
          console.warn(`아티스트 ${artist.nickname}의 경도(longitude)가 유효하지 않습니다: ${longitude}`);
          return false;
        }

        // 위치가 현재 지도 뷰포트 내에 있고, 선택된 장르에 해당하는지 확인
        const position = new google.maps.LatLng(latitude, longitude);
        const isInBounds = bounds.contains(position);
        
        const showAll = selectedGenres.value.includes('all');
        const matchesGenre = showAll || (artist.genres && artist.genres.some(genre => selectedGenres.value.includes(genre)));

        return isInBounds && matchesGenre;
      });
    };

    // 프로필 이동 함수
    const goToProfile = (artistId) => {
      router.push(`/profile/${artistId}`);
    };

    // 구글 맵을 초기화하고 마커를 추가하는 함수
    const initMap = async () => {
      try {
        const loadedArtists = await loadArtists();
        console.log('총 유효한 아티스트 수:', loadedArtists.length);

        // 아티스트가 없으면 오류 처리
        if (loadedArtists.length === 0) {
          throw new Error('등록된 아티스트가 없습니다.');
        }

        // 커스텀 맵 스타일 - 도로를 흐리게 처리한 스타일
        const mapStyles = [
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{"color": "#e2e2e2"}]
          },
          {
            "featureType": "landscape",
            "elementType": "geometry", 
            "stylers": [{"color": "#f5f5f5"}]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {"color": "#ffffff"},
              {"lightness": 100},
              {"visibility": "simplified"}
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [{"visibility": "off"}]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{"color": "#f0f0f0"}]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{"color": "#e5e5e5"}]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [{"visibility": "off"}]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [{"color": "#666666"}]
          },
          {
            "elementType": "labels.icon",
            "stylers": [{"saturation": -100}]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{"visibility": "off"}]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [{"color": "#fefefe"}]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels",
            "stylers": [{"visibility": "off"}]
          }
        ];
        
        // 맵 옵션 설정
        const mapOptions = {
          center: { lat: 37.5665, lng: 126.9780 }, // 서울의 위도와 경도
          zoom: 10,
          styles: mapStyles,
          mapTypeControl: false, // 지도/위성 선택 컨트롤 숨기기
          streetViewControl: false // 거리뷰 컨트롤 숨기기
        };

        // map 요소가 존재하는지 확인
        const mapElement = document.getElementById('map');
        if (!mapElement) {
          throw new Error('지도를 표시할 요소를 찾을 수 없습니다.');
        }

        // 구글 맵 인스턴스 생성
        try {
          map.value = new google.maps.Map(mapElement, mapOptions);
          console.log('구글 맵이 성공적으로 초기화되었습니다.');
        } catch (err) {
          console.error('구글 맵을 초기화하는 중 오류가 발생했습니다:', err);
          throw new Error('지도를 불러오는데 실패했습니다.');
        }

        // 마커 배열 생성
        markers.value = loadedArtists.map(artist => {
          const { latitude, longitude } = artist.location;

          // 마커 생성
          const marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map: map.value,
            title: artist.nickname,
            icon: {
              url: '/custom-marker.png', // public 폴더에 custom-marker.png가 있어야 함
              scaledSize: new google.maps.Size(
                Math.min(Math.max(40, window.innerWidth * 0.03), 40), // 너비: 40px~60px 사이에서 화면 크기에 따라 조절
                Math.min(Math.max(40, window.innerWidth * 0.03), 40)  // 높이: 너비와 동일한 값 사용
              ),
              origin: new google.maps.Point(0, 0), // 이미지의 시작점
              anchor: new google.maps.Point(16, 32), // 마커의 중심점
              labelOrigin: new google.maps.Point(20, 48),
            },
            label: {
              text: artist.nickname,
              color: "#000000", // 검정 텍스트
              fontSize: "12px", // 글자 크기 증가
              fontWeight: "bold",
            }
          });

          // InfoWindow 생성
          const infoWindow = new google.maps.InfoWindow({
            content: `
              <div class="info-window">
                <div class="artist-header">
                  <img src="${artist.profileImageUrl || '/default-profile.png'}" alt="프로필 이미지" class="info-profile-image"/>
                  <div class="artist-basic-info">
                    <strong>${artist.nickname}</strong>
                    <p class="genre">${artist.genres ? artist.genres.join(', ') : '장르 미지정'}</p>
                  </div>
                </div>
                  
                <button class="view-profile-button" data-id="${artist.id}">프로필 보기</button>
              </div>
            `,
          });

          // 마커 클릭 시 InfoWindow 열기
          marker.addListener('click', () => {
            // 이전에 열려있던 InfoWindow가 있다면 닫기
            if (currentInfoWindow.value) {
              currentInfoWindow.value.close();
            }
            // 새로운 InfoWindow 열기
            infoWindow.open(map.value, marker);
            // 현재 열린 InfoWindow 업데이트
            currentInfoWindow.value = infoWindow;

            // 지도 클릭 시 InfoWindow 닫기 이벤트 추가
            const mapClickListener = google.maps.event.addListener(map.value, 'click', () => {
              infoWindow.close();
              currentInfoWindow.value = null;
              // 이벤트 리스너 제거
              google.maps.event.removeListener(mapClickListener);
            });
          });

          // InfoWindow DOM이 준비된 후 버튼 클릭 이벤트 추가
          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const viewProfileButton = document.querySelector(`.view-profile-button[data-id="${artist.id}"]`);
            if (viewProfileButton) {
              viewProfileButton.addEventListener('click', () => {
                router.push(`/profile/${artist.id}`);
              });
            }
          });

          return marker;
        });

        // 초기 visibleArtists 설정
        updateVisibleArtists();

        // 지도 뷰포트가 변경될 때마다 visibleArtists 업데이트
        const idleListener = google.maps.event.addListener(map.value, 'idle', () => {
          updateVisibleArtists();
        });

        // 컴포넌트 언마운트 시 리스너 제거
        onUnmounted(() => {
          google.maps.event.removeListener(idleListener);
          // 열려있는 InfoWindow가 있다면 닫기
          if (currentInfoWindow.value) {
            currentInfoWindow.value.close();
          }
        });

      } catch (err) {
        console.error(err);
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    // 구글 맵 스크립트를 동적으로 로드하는 함수
    const loadGoogleMapsScript = () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    onMounted(async () => {
      try {
        await loadGoogleMapsScript();
        await initMap();
      } catch (err) {
        console.error('Google Maps 스크립트 로드 오류:', err);
        error.value = '구글 맵을 불러오는 데 실패했습니다.';
        loading.value = false;
      }
    });

    return {
      loading,
      error,
      visibleArtists,
      goToProfile,
      isSlideUp,
      toggleSlide,
      moveToMyLocation,
      showGenreFilter,
      toggleGenreFilter,
      availableGenres,
      selectedGenres,
      handleAllGenres,
      handleGenreChange
    };
  },
};
</script>

<style scoped>
/* 전체 컨테이너 스타일 */
.ai-choice-container {
  position: relative;
  height: 80vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  overflow-y: hidden;
  margin-top: 60px;
}

/* 지도 스타일 */
.map {
  width: 100%; /* 너비 100% 차지 */
  height: calc(100% - 200px);
}

/* 내 위치 버튼 스타일 */
.my-location-btn {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  top: 100px; /* 상단에서 100px 아래 */
  right: 20px; /* 오른쪽에서 20px 왼쪽으로 */
  z-index: 1; /* 다른 요소들 위에 표시 */
  background-color: white; /* 배경색 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 50%; /* 원형 버튼 */
  width: 40px; /* 너비 40px */
  height: 40px; /* 높이 40px */
  box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* 그림자 효과 */
  cursor: pointer; /* 커서 모양 변경 */
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
}

/* 장르 필터 컨테이너 스타일 */
.genre-filter {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  top: 150px; /* 상단에서 150px 아래 */
  right: 20px; /* 오른쪽에서 20px 왼쪽으로 */
  z-index: 11; /* 다른 요소들 위에 표시 */
}

/* 필터 버튼 스타일 */
.filter-btn {
  background-color: white; /* 배경색 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 50%; /* 원형 버튼 */
  width: 40px; /* 너비 40px */
  height: 40px; /* 높이 40px */
  box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* 그림자 효과 */
  cursor: pointer; /* 커서 모양 변경 */
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
}

/* 장르 리스트 스타일 */
.genre-list {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  top: 50px; /* 상단에서 50px 아래 */
  right: 0; /* 오른쪽 정렬 */
  background-color: white; /* 배경색 설정 */
  border-radius: 8px; /* 모서리 둥글게 설정 */
  box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* 그림자 효과 */
  padding: 10px; /* 내부 여백 설정 */
  width: 200px; /* 너비 200px */
}

/* 개별 장르 아이템 스타일 */
.genre-item {
  margin: 8px 0; /* 상하 여백 설정 */
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
}

/* 체크박스 스타일 */
.genre-item input[type="checkbox"] {
  margin-right: 8px; /* 오른쪽 여백 설정 */
}

/* 장르 라벨 스타일 */
.genre-item label {
  cursor: pointer; /* 커서 모양 변경 */
}

/* 버튼 호버 스타일 */
.my-location-btn:hover, .filter-btn:hover {
  background-color: #f5f5f5; /* 배경색 변경 */
}

/* 아이콘 스타일 */
.material-icons {
  font-size: 24px; /* 폰트 크기 설정 */
  color: #666; /* 폰트 색상 설정 */
}

/* 로딩 상태 스타일 */
.loading {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  top: 50%; /* 상단에서 50% 아래 */
  left: 50%; /* 왼쪽에서 50% 오른쪽으로 */
  transform: translate(-50%, -50%); /* 중앙 정렬 */
  font-size: 1.2rem; /* 폰트 크기 설정 */
  color: #4a90e2; /* 폰트 색상 설정 */
  background-color: rgba(255, 255, 255, 0.9); /* 배경색 설정 (투명도 0.9) */
  padding: 20px; /* 내부 여백 설정 */
  border-radius: 8px; /* 모서리 둥글게 설정 */
}

/* 에러 메시지 스타일 */
.error {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  top: 50%; /* 상단에서 50% 아래 */
  left: 50%; /* 왼쪽에서 50% 오른쪽으로 */
  transform: translate(-50%, -50%); /* 중앙 정렬 */
  color: #e74c3c; /* 폰트 색상 설정 */
  background-color: rgba(255, 255, 255, 0.9); /* 배경색 설정 (투명도 0.9) */
  padding: 20px; /* 내부 여백 설정 */
  border-radius: 8px; /* 모서리 둥글게 설정 */
  font-size: 1.2rem; /* 폰트 크기 설정 */
}

/* 아티스트 리스트를 화면 하단에 슬라이드 형태로 표시하는 스타일 */
.artist-list {
  position: fixed; /* 화면에 고정 */
  bottom: 0; /* 하단에 배치 */
  left: 0; /* 왼쪽 정렬 */
  right: 0; /* 오른쪽 정렬 */
  background-color: white; /* 배경색 설정 */
  height: 400px; /* 화면 높이의 70% 차지 */
  transform: translateY(calc(100% - 200px)); /* 핸들만 보이도록 초기 위치 설정 */
  transition: transform 0.3s ease-in-out; /* 부드러운 슬라이드 효과 */
  border-radius: 20px 20px 0 0; /* 상단 모서리만 둥글게 */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* 상단 그림자 효과 */
  z-index: 10; /* 다른 요소들 위에 표시 */
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 가능 */
}

/* 슬라이드가 올라온 상태일 때의 스타일 */
.artist-list.slide-up {
  transform: translateY(calc(100% - 400px)); /* 리스트의 절반만큼 위로 이동 */
}

/* 슬라이드를 조작하는 핸들 스타일 */
.slide-handle {
  height: 40px; /* 높이 40px */
  display: flex; /* 플렉스 박스 사용 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  cursor: pointer; /* 커서 모양 변경 */
  background-color: #f8f9fa; /* 배경색 설정 */
  border-radius: 20px 20px 0 0; /* 상단 모서리만 둥글게 */
}

/* 핸들 내부 화살표 텍스트 스타일 */
.handle-text {
  color: #666; /* 폰트 색상 설정 */
  font-size: 20px; /* 폰트 크기 설정 */
}

/* 아티스트 리스트 제목 스타일 */
.artist-list h2 {
  padding: 15px; /* 내부 여백 설정 */
  margin: 0; /* 외부 여백 제거 */
  font-size: 20px; /* 폰트 크기 설정 */
  color: #2c3e50; /* 폰트 색상 설정 */
  border-bottom: 1px solid #eee; /* 하단 구분선 */
}

/* 아티스트 목록 컨테이너 스타일 */
.artist-list ul {
  list-style: none; /* 리스트 스타일 제거 */
  padding: 15px; /* 내부 여백 설정 */
  margin: 0; /* 외부 여백 제거 */
}

/* 개별 아티스트 아이템 스타일 */
.artist-item {
  background-color: #ffffff; /* 배경색 설정 */
  padding: 1rem; /* 내부 여백 설정 */
  margin-bottom: 1rem; /* 하단 여백 설정 */
  border-radius: 8px; /* 모서리 둥글게 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 카드 형태의 그림자 효과 */
}

/* 아티스트 이미지와 정보를 담는 컨테이너 스타일 */
.artist-image-container {
  display: flex; /* 플렉스 박스 사용 */
  flex-direction: row; /* 가로 방향 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
}

/* 아티스트 프로필 이미지 스타일 */
.artist-image {
  width: 60px; /* 너비 60px */
  height: 60px; /* 높이 60px */
  border-radius: 50%; /* 원형 이미지 */
  object-fit: cover; /* 이미지 비율 유지하며 채우기 */
  margin-right: 1.5rem; /* 오른쪽 여백 설정 */
}

/* 아티스트 텍스트 정보 컨테이너 스타일 */
.artist-info {
  flex: 1; /* 남은 공간 모두 차지 */
}

/* 아티스트 이름 스타일 */
.artist-info h3 {
  margin: 0; /* 외부 여백 제거 */
  font-size: 1.4rem; /* 폰트 크기 설정 */
  color: #333333; /* 폰트 색상 설정 */
}

/* 아티스트 부가 정보 스타일 */
.artist-info p {
  margin: 0.5rem 0; /* 상하 여백 설정 */
  color: #555555; /* 폰트 색상 설정 */
}

/* 프로필 보기 버튼 스타일 */
:deep(.view-profile-button) {
  display: block; /* 블록 요소로 설정 */
  width: 100%; /* 너비 100% 차지 */
  margin-top: 8px; /* 상단 여백 설정 */
  padding: 8px 12px; /* 내부 여백 설정 */
  background-color: #4a90e2; /* 배경색 설정 */
  color: #fff; /* 폰트 색상 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 4px; /* 모서리 둥글게 설정 */
  cursor: pointer; /* 커서 모양 변경 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

/* 프로필 보기 버튼 호버 스타일 */
:deep(.view-profile-button:hover) {
  background-color: #357ab8; /* 배경색 변경 */
}

/* InfoWindow 내부 스타일 */
:deep(.info-window) {
  padding: 15px; /* 내부 여백 설정 */
  max-width: 300px; /* 최대 너비 300px */
  font-family: 'Noto Sans KR', sans-serif; /* 폰트 설정 */
}

/* 아티스트 헤더 스타일 */
:deep(.artist-header) {
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  margin-bottom: 10px; /* 하단 여백 설정 */
}

/* 프로필 이미지 스타일 */
:deep(.info-profile-image) {
  width: 60px; /* 너비 60px */
  height: 60px; /* 높이 60px */
  border-radius: 50%; /* 원형 이미지 */
  object-fit: cover; /* 이미지 비율 유지하며 채우기 */
  margin-right: 10px; /* 오른쪽 여백 설정 */
}

/* 아티스트 기본 정보 스타일 */
:deep(.artist-basic-info) {
  flex: 1; /* 남은 공간 모두 차지 */
}

/* 장르 텍스트 스타일 */
:deep(.genre) {
  font-size: 12px; /* 폰트 크기 설정 */
  color: #666; /* 폰트 색상 설정 */
  margin: 5px 0; /* 상하 여백 설정 */
}

/* 보이는 아티스트가 없을 때 메시지 스타일 */
.no-artists {
  position: absolute; /* 부모 요소를 기준으로 위치 설정 */
  bottom: 20px; /* 하단에서 20px 위 */
  left: 50%; /* 왼쪽에서 50% 오른쪽으로 */
  transform: translateX(-50%); /* X축 기준으로 중앙 정렬 */
  background-color: rgba(255, 255, 255, 0.9); /* 배경색 설정 (투명도 0.9) */
  padding: 15px 30px; /* 내부 여백 설정 */
  border-radius: 8px; /* 모서리 둥글게 설정 */
  color: #555; /* 폰트 색상 설정 */
  font-size: 0.8rem; /* 폰트 크기 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
  margin-bottom: 100px;
}

.artistlabel {
  padding-bottom: 100px;
  margin-bottom: 100px;
}

/* 반응형 디자인: 화면 너비가 768px 이하일 때 적용 */
@media (max-width: 768px) {
  .title {
    font-size: 1.5rem; /* 폰트 크기 설정 */
    padding: 8px 16px; /* 내부 여백 설정 */
  }

  .my-location-btn, .filter-btn {
    width: 35px; /* 너비 35px */
    height: 35px; /* 높이 35px */
  }

  .genre-list {
    width: 180px; /* 너비 180px */
  }

  .artist-image {
    width: 50px; /* 너비 50px */
    height: 50px; /* 높이 50px */
    margin-right: 1rem; /* 오른쪽 여백 설정 */
  }

  .artist-info h3 {
    font-size: 1.2rem; /* 폰트 크기 설정 */
  }

  .artist-info p {
    font-size: 0.9rem; /* 폰트 크기 설정 */
  }

  :deep(.view-profile-button) {
    padding: 6px 10px; /* 내부 여백 설정 */
  }

  :deep(.info-window) {
    padding: 10px; /* 내부 여백 설정 */
  }

  :deep(.info-profile-image) {
    width: 50px; /* 너비 50px */
    height: 50px; /* 높이 50px */
  }
}
</style>