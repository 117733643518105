// 아티스트 찾기 페이지

<template>
  <div class="find-artist-page">
    <section class="hero-section">
      <div class="hero-content">
        <h1>Find an Artist</h1>
        <p>당신에게 맞는 타투이스트를 찾아보세요</p>
        <!-- 검색 버튼 그룹 -->
        <div class="search-container">
          <div class="button-group">
            <button class="action-button" @click="openNearbyModal">
              <span class="material-icons">place</span>
              <span class="button-text">{{ selectedLocationText }}</span>
              <span class="arrow">›</span>
            </button>
            <button class="action-button" @click.prevent="openFilterModal">
              <i class="material-icons">filter_list</i>
              <span class="button-text">필터</span>
              <span class="arrow">›</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- 아티스트 목록을 그리드 형태로 표시하는 갤러리 -->
    <div class="artists-gallery">
      <!-- v-for로 각 아티스트 카드 렌더링 -->
      <div
        v-for="(artist) in displayedArtists"
        :key="artist.id"
        class="artist-card"
        @click="openArtistModal(artist)"
      >
        <div class="artist-info">
          <div class="artist-info-left">
            <!-- 아티스트 프로필 이미지 -->
            <img :src="artist.profileImage" alt="프로필 이미지" class="artist-profile-image" />
            <!-- 아티스트 상세 정보 -->
            <div class="artist-details">
              <h3>{{ artist.nickname }}</h3>
              <p>{{ artist.location.address.split(' ').slice(0, -2).join(' ') }}</p>
              <p>{{ formatGenres(artist.genres) }}</p>
            </div>
          </div>
          <!-- 거리 뱃지와 프로필 페이지로 이동하는 링크 버튼을 위아래로 나열 -->
          <div class="artist-info-right">
            <div v-if="artist.distance" class="distance-badge">
              <span class="material-icons">place</span>
              {{ artist.distance }}km
            </div>
            <router-link :to="`/profile/${artist.id}`" class="card-profile-button">
              프로필
            </router-link>
          </div>
        </div>
        <!-- 아티스트의 대표 포트폴리오 이미지 -->
        <div class="portfolio-gallery">
          <div 
            class="gallery-slider"
            @touchstart="(e) => touchStart(e, artist)"
            @touchmove="(e) => touchMove(e, artist)"
            @touchend="() => touchEnd(artist)"
            @mousedown="(e) => dragStart(e, artist)"
            @mousemove="(e) => dragMove(e, artist)"
            @mouseup="() => dragEnd(artist)"
            @mouseleave="() => dragEnd(artist)"
          >
            <div 
              class="slider-container"
              :style="{ 
                transform: `translateX(${artist.currentTranslate}%)`, 
                transition: artist.isDragging ? 'none' : 'transform 0.3s'
              }"
            >
              <div 
                v-for="(image, imageIndex) in artist.portfolioImages" 
                :key="imageIndex"
                class="slide">
                <div class="image-wrapper">
                  <img
                    :src="image"
                    :alt="`포트폴리오 이미지 ${imageIndex + 1}`"
                    class="gallery-image"
                    draggable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 포트폴리오 정보 박스 추가 -->
          <div class="portfolio-info-box" v-if="artist.portfolioInfo && artist.portfolioInfo[artist.currentImageIndex]">
            <div class="portfolio-description">
              <p>{{ artist.portfolioInfo[artist.currentImageIndex].description }}</p>
            </div>
            <table class="portfolio-price-table">
              <tbody>
                <tr>
                  <th>장르</th>
                  <td>
                    <p>
                    {{ 
                      Array.isArray(artist.portfolioInfo[artist.currentImageIndex].genres) 
                        ? artist.portfolioInfo[artist.currentImageIndex].genres.join(', ') 
                        : artist.portfolioInfo[artist.currentImageIndex].genres 
                    }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>소요시간</th>
                  <td>{{ artist.portfolioInfo[artist.currentImageIndex].duration }}시간</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- 장르 필터 모달 -->
    <transition name="fade">
      <div v-show="isFilterModalOpen" class="modal-overlay">
        <div class="filter-modal" @click.stop>
          <div class="modal-header">
            <h3 class="modal-title">장르 선택</h3>
            <button @click="resetFilter" class="reset-button">초기화</button>
          </div>
          <div class="genre-buttons">
            <button
              v-for="genre in genres"
              :key="genre"
              :class="{'active': selectedGenres.includes(genre)}"
              @click="toggleGenre(genre)"
              class="genre-button"
            >
              {{ genre }}
            </button>
          </div>
          <div class="modal-footer">
            <button @click="closeFilterModal" class="cancel-button">취소</button>
            <button @click="applyFilter" class="apply-button">적용</button>
          </div>
        </div>
      </div>
    </transition>

    <!-- 위치 선택을 위한 지도 ���달 -->
    <div v-if="isNearbyModalOpen" class="modal-overlay">
      <div class="find-tattoo-map">
        <FindTattooMap
          :apiKey="googleMapsApiKey" 
          @location-selected="handleLocationSelected"
          @close="closeNearbyModal" 
        />
      </div>
    </div>

    <!-- 로딩 인디케이터 -->
    <div v-if="isLoading" class="loading-indicator">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
// Firebase 관련 import
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where } from "firebase/firestore";
// 컴포넌트 및 유틸리티 import
import FindTattooMap from './FindTattooMap.vue';
import { getDistanceFromLatLonInKm } from '@/utils/distance';

export default {
  name: 'FindArtist',
  // 사용할 컴포넌트 등록
  components: {
    FindTattooMap,
  },
  // 컴포넌트의 데이터 정의
  data() {
    return {
      artists: [], // 전체 아티스트 목록
      pageSize: 10, // 한 페이지당 아이템 수
      currentPage: 1, // 현재 페이지
      isLoading: false, // 로딩 상태
      selectedArtist: null, // 선택된 아티스트
      isArtistModalOpen: false, // 아티스트 모달 표시 여부
      isFilterModalOpen: false, // 필터 모달 표시 여부
      isNearbyModalOpen: false, // 위치 선택 모달 표시 여부
      // 타투 장르 목록
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화'],
      selectedGenres: [],
      tempSelectedGenres: [], // 임시 저장된 선택 장르들
      userLocation: null, // 사용자 위치
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY || '', // Google Maps API 키
      selectedAddress: null, // 선택된 주소
      currentUser: null,
    };
  },
  // 계산된 속성 정의
  computed: {
    // 선택된 위치 텍스트 표시 형식 계산
    selectedLocationText() {
      if (this.selectedAddress) {
        const addressParts = this.selectedAddress.split(' ');
        const relevantParts = addressParts.filter(part => 
          part.includes('시') || 
          part.includes('구')
        );
        if (relevantParts.length > 0) {
          return `내 주변 (${relevantParts.join(' ')})`;
        }
      }
      return '내 주변';
    },
    // 필터링된 아티스트 목록 계산
    filteredArtists() {
      let filtered = this.artists;

      // 장르 필터 적용
      if (this.selectedGenres.length > 0) {
        filtered = filtered.filter(artist =>
          artist.genres?.some(genre => this.selectedGenres.includes(genre))
        );
      }

      return filtered;
    },
    formatGenres() {
      return (genres) => {
        if (!genres || genres.length === 0) return '장르 정보 없음';
        if (genres.length <= 2) return genres.join(', ');
        return `${genres.slice(0, 2).join(', ')} 외 ${genres.length - 2}개`;
      };
    },
    displayedArtists() {
      return this.filteredArtists.slice(0, this.currentPage * this.pageSize);
    },
    hasMoreArtists() {
      return this.displayedArtists.length < this.filteredArtists.length;
    }
  },
  // 컴포넌트 생성 시 실행
  created() {
    this.loadArtists();
  },
  watch: {
    userLocation(newLocation) {
      if (newLocation) {
        this.artists.forEach(artist => {
          if (artist.location && artist.location.latitude && artist.location.longitude) {
            const distance = getDistanceFromLatLonInKm(
              newLocation.lat,
              newLocation.lng,
              artist.location.latitude,
              artist.location.longitude
            );
            artist.distance = distance.toFixed(1); // distance 속성 추가 또는 업데이트
          } else {
            artist.distance = null; // 위치 정보가 없는 경우 처리
          }
        });

        // 거리순으로 아티스트 정렬
        this.artists.sort((a, b) => {
          if (a.distance === null) return 1;
          if (b.distance === null) return -1;
          return parseFloat(a.distance) - parseFloat(b.distance);
        });
      }
    }
  },
  // 메서드 정의
  methods: {
    // Firestore에서 아티스트 데이터 로드
    async loadArtists() {
      try {
        const artistsQuery = query(collection(db, "artists"), where("userType", "==", "artist"));
        const usersSnapshot = await getDocs(artistsQuery);

        const artistsPromises = usersSnapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();
          // 포폴폴리오 컬렉션에서 모든 이미지와 정보 가져오기
          const portfoliosCollection = collection(db, "artists", userDoc.id, "portfolios");
          const portfoliosSnapshot = await getDocs(portfoliosCollection);
          let portfolioImages = [];
          let portfolioInfo = [];

          if (!portfoliosSnapshot.empty) {
            portfoliosSnapshot.docs.forEach(doc => {
              const data = doc.data();
              if (data.images) {
                portfolioImages.push(...data.images);
                // 각 이미지에 대한 정보 추가
                data.images.forEach(() => {
                  portfolioInfo.push({
                    price: data.price || 0,
                    description: data.description || '설명이 없습니다.',
                    genres: data.genres || '장르 미지정',
                    duration: data.duration || '없음' // duration 추가
                  });
                });
              }
            });
          }

          return {
            id: userDoc.id,
            portfolioImages: portfolioImages.length > 0 ? portfolioImages : ['https://via.placeholder.com/300x400?text=No+Image'],
            portfolioInfo: portfolioInfo,
            currentImageIndex: 0,
            currentTranslate: 0,
            prevTranslate: 0,
            isDragging: false,
            distance: null, // distance 초기화
            profileImage: userData.profileImageUrl || 'https://via.placeholder.com/100x100?text=No+Profile',
            nickname: userData.nickname || '닉네임 없음',
            location: userData.location || { address: '위치 정보 없음' },
            genres: userData.genres || []
          };
        });

        // 모든 아티스트 데이터 로드 완료 후 저장
        const resolvedArtists = await Promise.all(artistsPromises);
        this.artists = resolvedArtists;
      } catch (error) {
        console.error('아티스트 로드 실패:', error);
      }
    },
    // 아티스트 모달 관련 메서드
    openArtistModal(artist) {
      this.selectedArtist = artist;
      this.isArtistModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    // 필터 모달 관련 메서드
    openFilterModal() {
      console.log('필터 모달 열기');
      document.body.style.overflow = 'hidden'; // 배경 스크롤 방지
      this.isFilterModalOpen = true;
      this.tempSelectedGenres = [...this.selectedGenres];
    },
    closeFilterModal() {
      document.body.style.overflow = '';
      this.isFilterModalOpen = false;
      this.selectedGenres = [...this.tempSelectedGenres];
    },
    // 장르 토글 메서드
    toggleGenre(genre) {
      const index = this.selectedGenres.indexOf(genre);
      if (index > -1) {
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(genre);
      }
    },
    // 필터 적용 메서드
    applyFilter() {
      this.tempSelectedGenres = [...this.selectedGenres];
      this.closeFilterModal();
    },
    // 필터 초기화 메서드
    resetFilter() {
      this.selectedGenres = [];
      this.tempSelectedGenres = [];
    },
    // 위치 모달 관련 메서드
    openNearbyModal() {
      this.isNearbyModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    closeNearbyModal() {
      this.isNearbyModalOpen = false;
    },
    // 위치 선택 처리 메서드
    handleLocationSelected(location) {
      this.userLocation = location.coordinates;
      this.selectedAddress = location.address;
    },
    // 터치 ���작
    touchStart(e, artist) {
      artist.startPos = e.touches[0].clientX;
      artist.isDragging = true;
      artist.currentTranslate = artist.prevTranslate;
    },

    // 터치 이동
    touchMove(e, artist) {
      if (artist.isDragging) {
        const currentPosition = e.touches[0].clientX;
        const diff = currentPosition - artist.startPos;
        // 이동 거리를 화면 너비에 대한 백분율로 변환
        const percentMove = (diff / window.innerWidth) * 100;
        artist.currentTranslate = artist.prevTranslate + percentMove;
      }
    },

    // 터치 종료
    touchEnd(artist) {
      artist.isDragging = false;
      const movedBy = artist.currentTranslate - artist.prevTranslate;

      // 최소 이동 거리를 백분율 기준으로 변경 (20%로 설정)
      if (Math.abs(movedBy) > 20) {
        if (movedBy < 0 && artist.currentImageIndex < artist.portfolioImages.length - 1) {
          artist.currentImageIndex++;
        } else if (movedBy > 0 && artist.currentImageIndex > 0) {
          artist.currentImageIndex--;
        }
      }

      artist.currentTranslate = artist.currentImageIndex * -85;
      artist.prevTranslate = artist.currentTranslate;
    },

    // 마우스 드래그 시작
    dragStart(e, artist) {
      artist.startPos = e.clientX;
      artist.isDragging = true;
      artist.currentTranslate = artist.prevTranslate;
    },

    // 마우스 드래그 이동
    dragMove(e, artist) {
      if (artist.isDragging) {
        const currentPosition = e.clientX;
        const diff = currentPosition - artist.startPos;
        // 이동 거리를 화면 너비에 대한 백분율로 변환
        const percentMove = (diff / window.innerWidth) * 100;
        artist.currentTranslate = artist.prevTranslate + percentMove;
      }
    },

    // 마우스 드래그 종료
    dragEnd(artist) {
      artist.isDragging = false;
      const movedBy = artist.currentTranslate - artist.prevTranslate;

      // 최소 이동 거리를 백분율 기준으로 변경 (20%로 설정)
      if (Math.abs(movedBy) > 20) {
        if (movedBy < 0 && artist.currentImageIndex < artist.portfolioImages.length - 1) {
          artist.currentImageIndex++;
        } else if (movedBy > 0 && artist.currentImageIndex > 0) {
          artist.currentImageIndex--;
        }
      }

      artist.currentTranslate = artist.currentImageIndex * -100;
      artist.prevTranslate = artist.currentTranslate;
    },
    // 슬라이드 진행률 계산 함수 제거 (게이지 세그먼트 사용으로 대체)
    // progressPercentage(current, total) {
    //   if (total === 0) return 0;
    //   return ((current + 1) / total) * 100;
    // }
    // 스크롤 이벤트 핸들러
    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomOfPage = document.documentElement.offsetHeight - 100;

      if (scrollPosition >= bottomOfPage) {
        this.loadMoreArtists();
      }
    },
    // 추가 아티스트 로드
    async loadMoreArtists() {
      if (this.isLoading || !this.hasMoreArtists) return;

      this.isLoading = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 500)); // 로딩 시뮬레이션
        this.currentPage++;
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    window.addEventListener('popstate', () => {
      if (this.isFilterModalOpen) this.closeFilterModal();
      if (this.isNearbyModalOpen) this.closeNearbyModal();
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  // beforeDestroy를 beforeUnmount로 변경
  beforeUnmount() {
    document.body.style.overflow = '';
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
/* 아티스트 찾기 페이지의 전체 레이아웃을 정의하는 클래스 */
.find-artist-page {
  background-color: #f8f9fa;
  min-height: 100vh;
  overflow-x: hidden;
}

.hero-section {
  background: linear-gradient(135deg, #000000 0%, #333333 100%);
  color: white;
  padding: 60px 20px;
  padding-top: 30px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
  opacity: 0.9;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* 버튼 그룹 컨테이너 스타일 */
.button-group {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

/* 액션 버튼 스타일 */
.action-button {
  flex: 1;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #2d3436;
  font-size: 1em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.action-button .material-icons {
  font-size: 20px;
  color: #2d3436;
}

.button-text {
  flex: 1;
  text-align: left;
}

.arrow {
  font-size: 20px;
  opacity: 0.5;
}

/* 아티스트 갤러리 그리드 레이아웃 */
.artists-gallery {
  display: grid; /* 그리드 레이아웃 사용 */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* 반응형 그리드 열 설정 */
  gap: 15px; /* 그리드 아이템 간격 */
  width: 100%; /* 전체 너비 */
  max-width: 1200px; /* 최대 너비 제한 */
  box-sizing: border-box;
  padding: 0 20px; /* 좌우 패딩 */
}

/* 아티스트 카드 스타일 */
.artist-card {
  position: relative; /* 상대 위치 설정 */
  width: 100%; /* 전체 너비 */
  cursor: pointer; /* 마우스 커서 변경 */
  overflow: hidden; /* 내용 넘침 숨김 */
  border-radius: 10px; /* 모서리 둥글게 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  background-color: #fafafa; /* 배경색 */
  transition: transform 0.2s; /* 변환 효과 */
  display: flex; /* Flexbox 레이아웃 */
  flex-direction: column; /* 세로 방향 배치 */
  margin-bottom: 20px;
}

/* 아티스트 카드 호버 효과 */
.artist-card:hover {
  transform: scale(1.02); /* 크기 확대 효과 */
}
/* 아티스트 포트폴리오 이미지 스타일 */
.artist-portfolio-image {
  width: 100%; /* 전체 너비 */
  height: 100%; /* 너비와 동일한 높이 */
  aspect-ratio: 1; /* 1:1 비율 강제 */
  object-fit: cover; /* 이미지 비율 유지 */
  display: block; /* 블록 벨 소로 표시 */
}

/* 아티스트 정보 컨테이너 스타일 */
.artist-info {
  display: flex;
  justify-content: space-between; /* 좌우 정렬 */
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  background-color: #ffffff;
}

/* 아티스트 정보 왼쪽 부분 */
.artist-info-left {
  display: flex;
  align-items: center;
}

/* 아티스트 정보 오른쪽 부분 */
.artist-info-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* 아티스트 프로필 이미지 스타일 */
.artist-profile-image {
  width: 60px; /* 너비 */
  height: 60px; /* 높이 */
  border-radius: 50%; /* 원형으로 만들기 */
  object-fit: cover; /* 이미지 비율 유지 */
  margin-right: 16px; /* 우측 여 */
}

/* 아티스트 상세 정보 컨테이너 스타일 */
.artist-details {
  display: flex; /* Flexbox 레이아웃 */
  flex-direction: column; /* 세로 방향 배치 */
}

/* 아티스트 이름 스타일 */
.artist-details h3 {
  margin: 0; /* 여백 제거 */
  font-size: 16px; /* 글자 크기 */
  color: #333333; /* 글자색 */
}

/* 아티스트 부가 정보 스타일 */
.artist-details p {
  margin: 1px 0; /* 상하 여백 */
  color: #777777; /* 글자색 */
  font-size: 12px; /* 글자 크기 */
}

/* 모달 오버레이 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.filter-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  z-index: 10000;
  position: relative;
}

/* 페이드 애니메이션 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 장르 버튼 그룹 스타일 */
.genre-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
  padding: 15px;
  margin: 20px 0;
  align-items: center;
  justify-items: left;
}

/* 장르 버튼 스타일 */
.genre-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #2d3436;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

/* 활성화된 장르 버튼 스타일 */
.genre-button.active {
  background-color: #2d3436;
  color: #ffffff;
}

/* 페이드인 애니메이션 정의 */
@keyframes fadeIn {
  from {
    opacity: 0; /* 시작시 투명 */
  }
  to {
    opacity: 1; /* 종료시 불투명 */
  }
}
/* 카드 내 프로필 버튼 스타일 */
.card-profile-button {
  padding: 8px 16px;
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.2s;
  margin-right: 30px;
}

.card-profile-button:hover {
  background-color: #1e8449;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  padding: 0 20px;
}

.modal-title {
  font-size: 20px;
  color: #2d3436;
  font-weight: 600;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
}

.apply-button, .cancel-button {
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.apply-button {
  background-color: #2d3436;
  color: #ffffff;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #2d3436;
}

.reset-button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  background-color: #f0f0f0;
  color: #636e72;
  transition: background-color 0.2s;
  margin-left: auto;
}

.reset-button:hover {
  background-color: #e0e0e0;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {

  .hero-content h1 {
    font-size: 2em;
  }

  .button-group {
    flex-direction: column;
    padding: 0 20px;
  }

  .action-button {
    width: 100%;
  }
  /* 갤러리 그리드 조정 */
  .artists-gallery {
    grid-template-columns: 1fr; /* 단일 열 그리드로 변경 */
    gap: 10px; /* 간격 축소 */
    padding: 0 5px; /* 패딩 축소 */
  }

  /* 아티스트 정보 레이아웃 조정 */
  .artist-info {
    flex-direction: row; /* 가로 방향 배치 */
    align-items: center; /* 세로 중앙 정렬 */
    text-align: left; /* 텍스트 왼쪽 정렬 */
    padding-left: 12px; /* 좌측 패딩 */
  }

  /* 프로필 이미지 조정 */
  .artist-profile-image {
    margin-right: 12px; /* 우측 여백 */
    margin-bottom: 0; /* 하단 여백 제거 */
    width: 60px; /* 너비 유지 */
    height: 60px; /* 높이 유지 */
  }

  /* 아티스트 상세 정보 레이아웃 조정 */
  .artist-details {
    margin-left: 0; /* 좌측 여백 제거 */
  }

  /* 아티스트 상세 정보 텍스트 크기 조정 */
  .artist-details h3 {
    font-size: 16px; /* 제목 크기 */
  }

  .artist-details p {
    font-size: 12px; /* 본문 크기 */
  }

  .genre-button {
    padding: 10px 16px;
    font-size: 1em;
  }

  .apply-button, .cancel-button {
    padding: 10px 25px;
    font-size: 13px;
  }

  .reset-button {
    padding: 8px 16px;
    font-size: 13px;
    margin-left: auto;
  }

  .filter-modal {
    width: 95%;
    margin: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }
}

/* 포트폴리오 갤러리 스타일 */
.portfolio-gallery {
  width: 100%;
  overflow: hidden;
  margin: 15px 0;
}

.gallery-slider {
  width: 100%;
  overflow: hidden;
  touch-action: pan-y pinch-zoom;
}

.slider-container {
  display: flex;
  width: 100%;
}

.slide {
  min-width: 85%;
  flex-shrink: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.image-wrapper {
  width: 100%;
  padding-bottom: 100%; /* 정사각형 비율 유지 */
  position: relative;
}

.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  pointer-events: none;
}

/* 페이지 인디케이터 스타일 */
.page-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.indicator-dots {
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  transition: all 0.2s;
}

.dot.active {
  background-color: #2d3436;
}

/* UserPage.vue에서 가져온 포트폴리오 정보 스타일 */
.portfolio-info-box {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.portfolio-price {
  font-size: 14px;
  color: #636e72;
  line-height: 1.4;
  max-height: 60px;
  overflow-y: auto;
}

.portfolio-description {
  font-size: 16px;
  font-weight: bold;
  color: #2d3436;
  margin-bottom: 8px;
  margin-left: 10px;
}

.portfolio-description p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: …;
}

.portfolio-price-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}

.portfolio-price-table th,
.portfolio-price-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.portfolio-price-table th {
  width: 30%;
  background-color: #f8f9fa;
  color: #2d3436;
  font-weight: bold;
  font-size: 14px;
}

.portfolio-price-table td {
  color: #636e72;
  font-size: 14px;
}

.portfolio-price-table p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: …;
}

.distance-badge {
  display: inline-flex;
  background-color: #2d3436;
  color: #f8f9fa;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: auto; /* 텍스트에 맞게 줄어들도록 설정 */
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  margin-right: 32px;
  margin-bottom: 6px;
}

.distance-badge .material-icons {
  font-size: 16px;
  margin-right: 4px;
}

.find-tattoo-map {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
}

/* 포트폴리오 이미지 스타일 수정: 1대1 정사각형 */
.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  display: block; /* 이미지의 블록 레벨 표시 */
}

/* 추가적인 공간 확보를 위해 슬라이더와 인디케이터 간 간격 조정 */
.portfolio-gallery {
  position: relative; /* 인디케이터 위 조정을 위해 relative 설정 */
}

/* 모달 오버레이 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center; /* 모달 중앙 정렬 */
  align-items: center; /* 모달 수직 중앙 정렬 */
}

/* 모달 콘텐츠 스타일 */
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px; /* 모달 최대 너비 조정 */
  width: 90%;
  position: relative; /* 닫기 버튼 위치를 위한 relative 설정 */
}

/* 닫기 버튼 스타일 */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

/* 기타 스타일 유지 */

/* 반응형 스타일 */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2em;
  }

  .button-group {
    flex-direction: column;
    padding: 0 20px;
  }

  .action-button {
    width: 100%;
  }

  .genre-button {
    padding: 10px 16px;
    font-size: 1em;
  }

  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }

  .modal {
    width: 95%;
    padding: 15px;
    margin: 10px;
  }

  .portfolio-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }

  .artist-profile-image {
    width: 60px;
    height: 60px;
  }

  .artist-name {
    font-size: 20px;
  }
}

/* 로딩 인디케이터 스타일 */
.loading-indicator {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style> 