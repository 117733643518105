<template>
  <div class="create-post-container">
    <h2>게시글 작성</h2>
    <form @submit.prevent="submitPost" class="post-form">
      <div class="form-group">
        <input 
          type="text" 
          id="title"
          v-model="postData.title" 
          required
          placeholder="제목을 입력하세요"
          class="modern-input"
        >
      </div>

      <div class="form-group category-group">
        <select 
          id="category"
          v-model="postData.category" 
          required
          class="modern-select"
        >
          <option value="" disabled selected>카테고리 선택</option>
          <option value="스튜디오">스튜디오</option>
          <option value="장터">장터</option>
          <option value="질문">질문</option>
          <option value="정보">정보</option>
        </select>
      </div>

      <div class="form-group">
        <textarea 
          id="content"
          v-model="postData.content" 
          rows="10" 
          required
          placeholder="내용을 입력하세요"
          class="modern-textarea"
        ></textarea>
      </div>

      <div class="form-group image-upload-group">
        <label for="images" class="image-upload-label">
          <span class="material-icons">add_photo_alternate</span>
          이미지 추가
        </label>
        <input 
          type="file"
          id="images"
          @change="handleImageUpload"
          accept="image/*"
          multiple
          class="file-input"
        >
        <div class="image-preview" v-if="imagePreviewUrls.length">
          <div v-for="(url, index) in imagePreviewUrls" :key="index" class="preview-item">
            <img :src="url" alt="Preview" />
            <button type="button" @click="removeImage(index)" class="remove-image">
              <span class="material-icons">close</span>
            </button>
          </div>
        </div>
      </div>

      <button type="submit" :disabled="isSubmitting" class="submit-button">
        <span class="material-icons">{{ isSubmitting ? 'hourglass_empty' : 'send' }}</span>
        {{ isSubmitting ? '게시 중...' : '게시하기' }}
      </button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebaseConfig';
import { getCurrentUserType } from '@/services/AuthService';
import { useRouter } from 'vue-router';

export default {
  name: 'CreatePost',
  setup() {
    const router = useRouter();
    const postData = ref({
      title: '',
      content: '',
      category: '일반'
    });
    const isSubmitting = ref(false);
    const userType = ref(null);
    const userProfile = ref(null);
    const selectedImages = ref([]);
    const imagePreviewUrls = ref([]);

    const loadUserProfile = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (user) {
          const type = await getCurrentUserType();
          userType.value = type;
          
          const collectionName = type === 'artist' ? 'artists' : 'users';
          const userDoc = await getDoc(doc(db, collectionName, user.uid));
          
          if (userDoc.exists()) {
            userProfile.value = {
              ...userDoc.data(),
              email: user.email
            };
          }
        } else {
          router.push('/login');
        }
      } catch (error) {
        console.error('프로필 로딩 오류:', error);
        router.push('/login');
      }
    };

    const handleImageUpload = (event) => {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          selectedImages.value.push(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            imagePreviewUrls.value.push(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      });
    };

    const removeImage = (index) => {
      selectedImages.value.splice(index, 1);
      imagePreviewUrls.value.splice(index, 1);
    };

    const uploadImages = async () => {
      const storage = getStorage();
      const imageUrls = [];

      for (const image of selectedImages.value) {
        const imageName = `posts/${Date.now()}_${image.name}`;
        const imageRef = storageRef(storage, imageName);
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        imageUrls.push(url);
      }

      return imageUrls;
    };

    const submitPost = async () => {
      if (!userProfile.value) {
        alert('로그인이 필요합니다.');
        router.push('/login');
        return;
      }

      try {
        isSubmitting.value = true;
        const auth = getAuth();
        const user = auth.currentUser;

        // 이미지 업로드 및 URL 가져오기
        const imageUrls = await uploadImages();

        const postToSubmit = {
          ...postData.value,
          authorId: user.uid,
          authorName: userProfile.value.name || user.email,
          authorNickname: userProfile.value.nickname,
          authorType: userType.value,
          createdAt: serverTimestamp(),
          likes: 0,
          comments: [],
          images: imageUrls
        };

        const docRef = await addDoc(collection(db, 'posts'), postToSubmit);
        console.log('게시글이 성공적으로 저장되었습니다. Document ID:', docRef.id);
        
        router.push('/posts');
      } catch (error) {
        console.error('게시글 작성 오류:', error);
        alert('게시글 작성 중 오류가 발생했습니다.');
      } finally {
        isSubmitting.value = false;
      }
    };

    onMounted(() => {
      loadUserProfile();
    });

    return {
      postData,
      isSubmitting,
      submitPost,
      handleImageUpload,
      removeImage,
      imagePreviewUrls
    };
  }
}
</script>

<style scoped>
.create-post-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 32px;
}

.post-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  width: 90%;
  position: relative;
}

.modern-input,
.modern-select,
.modern-textarea {
  width: 100%;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.modern-input:focus,
.modern-select:focus,
.modern-textarea:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.1);
  background: #ffffff;
}

.modern-select {
  width: 105%;
}

.modern-textarea {
  min-height: 200px;
  resize: vertical;
  line-height: 1.6;
}

.image-upload-group {
  margin-top: 16px;
}

.image-upload-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: #f8f9fa;
  border: 1px dashed #ccc;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.image-upload-label:hover {
  background: #f1f3f5;
  border-color: #666;
}

.file-input {
  display: none;
}

.image-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.preview-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease;
}

.remove-image:hover {
  background: rgba(0, 0, 0, 0.7);
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 32px;
  background: #333;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background: #222;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .create-post-container {
    padding: 20px;
    margin: 20px;
  }
  
  .modern-input,
  .modern-select,
  .modern-textarea {
    padding: 12px;
  }

  .modern-select {
    width: 110%;
  }
}
</style>
