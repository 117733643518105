<template>
  <div class="chat-room">
    <!-- 채팅 헤더 -->
    <div class="chat-header">
      <div class="user-info">
        <img :src="otherUser.profileImageUrl" 
             alt="User Avatar" 
             class="user-avatar"
             @click="goToProfile" />
        <div class="user-details">
          <h3 @click="goToProfile">{{ otherUser.nickname }}</h3>
          <span :class="['online-status', otherUser.isOnline ? 'is-online' : 'is-offline']">
            {{ otherUser.isOnline ? '온라인' : `마지막 접속: ${formatLastSeen(otherUser.lastLogin)}` }}
          </span>
        </div>
      </div>
      <div class="header-actions">
        <button @click="goBack">뒤로가기</button>
      </div>
    </div>

    <!-- 메시지 컨테이너 -->
    <div class="messages-container" ref="messagesContainer">
      <div v-for="(message, index) in messages" 
           :key="message.id" 
           class="message-wrapper"
           :class="message.senderId === currentUserId ? 'sent' : 'received'">
        <!-- 발신자 이름 - 이전 메시지와 다른 발신자일 때만 표시 -->
        <span v-if="message.senderId !== currentUserId && 
                    (index === 0 || messages[index - 1].senderId !== message.senderId)" 
              class="message-sender">
          {{ otherUser.nickname }}
        </span>
        <!-- 메시지 내용과 시간을 감싸는 컨테이너 -->
        <div class="message-content-wrapper">
          <!-- 메시지 내용 -->
          <div class="message-bubble">
            <div v-if="message.type === 'text'" class="message-content">{{ message.content }}</div>
            <img v-else-if="message.type === 'image'" 
                 :src="message.content" 
                 alt="Sent image" 
                 class="message-image"
                 @click="openImageModal(message.content)" />
          </div>
          <!-- 시간 -->
          <span class="message-time">{{ formatTime(message.timestamp) }}</span>
        </div>
      </div>
    </div>

    <!-- 이미지 모달 -->
    <div v-if="selectedImage" class="image-modal" @click="selectedImage = null">
      <img :src="selectedImage" alt="Full size image" class="modal-image" />
    </div>

    <!-- 메시지 입력 창 -->
    <div class="message-input">
      <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="메시지를 입력하세요..." />
      <label for="image-upload" class="image-upload-label">
        <i class="material-icons">photo_library</i>
        <input 
          type="file" 
          id="image-upload" 
          accept="image/*" 
          @change="handleImageUpload" 
          style="display: none" />
      </label>
      <button @click="sendMessage">전송</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { db, storage } from '@/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { 
  collection, addDoc, query, orderBy, onSnapshot,
  serverTimestamp, doc, getDoc, Timestamp, updateDoc, increment
} from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useRouter } from 'vue-router';

export default {
  name: 'ChatRoom',
  props: {
    chatId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const messages = ref([]);
    const newMessage = ref('');
    const currentUserId = ref('');
    const otherUser = ref({});
    const messagesContainer = ref(null);
    const selectedImage = ref(null);
    const auth = getAuth();
    const router = useRouter();
    let unsubscribe = null;
    let userStatusUnsubscribe = null;

    // 프로필 페이지로 이동하는 함수
    const goToProfile = async () => {
      if (!auth.currentUser) return;
      
      try {
        // 상대방이 아티스트인 경우에만 프로필 페이지로 이동
        if (otherUser.value.type === 'artist') {
          router.push(`/profile/${otherUser.value.id}`);
        }
      } catch (error) {
        console.error('프로필 이동 실패:', error);
      }
    };

    // 이미지 업로드 처리
    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      try {
        const imageRef = storageRef(storage, `chat-images/${props.chatId}/${Date.now()}_${file.name}`);
        await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(imageRef);
        
        // 이미지 메시지 저장
        const messageData = {
          content: downloadURL,
          senderId: currentUserId.value,
          timestamp: serverTimestamp(),
          type: 'image'
        };

        await addDoc(collection(db, `chats/${props.chatId}/messages`), messageData);
        
        // 상대방의 unreadCount 업데이트
        await updateDoc(doc(db, 'chats', props.chatId), {
          [`unreadCount.${otherUser.value.id}`]: increment(1)
        });
      } catch (error) {
        console.error('이미지 업로드 실패:', error);
        alert('이미지 업로드에 실패했습니다.');
      }
    };

    // 이미지 모달 열기
    const openImageModal = (imageUrl) => {
      selectedImage.value = imageUrl;
    };

    // 사용자 상태 업데이트 함수
    const updateUserStatus = async (isOnline) => {
      if (!auth.currentUser) return;

      const userRef = doc(db, 'users', auth.currentUser.uid);
      const artistRef = doc(db, 'artists', auth.currentUser.uid);

      try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          await updateDoc(userRef, {
            isOnline,
            lastLogin: isOnline ? null : serverTimestamp()
          });
        } else {
          const artistDoc = await getDoc(artistRef);
          if (artistDoc.exists()) {
            await updateDoc(artistRef, {
              isOnline,
              lastLogin: isOnline ? null : serverTimestamp()
            });
          }
        }
      } catch (error) {
        console.error('상태 업데이트 실패:', error);
      }
    };

    // 채팅방 데이터 로드 함수
    const loadChatData = async () => {
      try {
        const chatDoc = await getDoc(doc(db, 'chats', props.chatId));
        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          currentUserId.value = auth.currentUser.uid;
          
          // 상대방 정보 가져오기
          const otherUserId = chatData.participants.find(id => id !== currentUserId.value);
          if (otherUserId) {
            // users와 artists 컬렉션 모두에서 상대방 정보 확인
            let userDoc = await getDoc(doc(db, 'users', otherUserId));
            if (userDoc.exists()) {
              otherUser.value = {
                ...userDoc.data(),
                id: otherUserId,
                type: 'regular',
                isOnline: userDoc.data().isOnline || false,
                lastLogin: userDoc.data().lastLogin || null,
                profileImageUrl: userDoc.data().profileImageUrl || require('@/assets/default-profile.jpg'),
                nickname: userDoc.data().nickname || '사용자'
              };
              
              // 상대방의 실시간 상태 변경 감지
              userStatusUnsubscribe = onSnapshot(doc(db, 'users', otherUserId), (doc) => {
                if (doc.exists()) {
                  const data = doc.data();
                  otherUser.value = {
                    ...otherUser.value,
                    isOnline: data.isOnline || false,
                    lastLogin: data.lastLogin || null,
                    profileImageUrl: data.profileImageUrl || require('@/assets/default-profile.jpg'),
                    nickname: data.nickname || '사용자'
                  };
                }
              });
            } else {
              // users에 없다면 artists 컬렉션 확인
              userDoc = await getDoc(doc(db, 'artists', otherUserId));
              if (userDoc.exists()) {
                otherUser.value = {
                  ...userDoc.data(),
                  id: otherUserId,
                  type: 'artist',
                  isOnline: userDoc.data().isOnline || false,
                  lastLogin: userDoc.data().lastLogin || null,
                  profileImageUrl: userDoc.data().profileImageUrl || require('@/assets/default-profile.jpg'),
                  nickname: userDoc.data().nickname || '타투이스트'
                };
                
                // 상대방의 실시간 상태 변경 감지
                userStatusUnsubscribe = onSnapshot(doc(db, 'artists', otherUserId), (doc) => {
                  if (doc.exists()) {
                    const data = doc.data();
                    otherUser.value = {
                      ...otherUser.value,
                      isOnline: data.isOnline || false,
                      lastLogin: data.lastLogin || null,
                      profileImageUrl: data.profileImageUrl || require('@/assets/default-profile.jpg'),
                      nickname: data.nickname || '타투이스트'
                    };
                  }
                });
              }
            }
          }
        }
      } catch (error) {
        console.error('채팅 데이터 로드 실패:', error);
      }
    };

    // 메시지 전송 함수
    const sendMessage = async () => {
      if (!newMessage.value.trim()) return;

      try {
        // 메시지 저장
        const messageData = {
          content: newMessage.value,
          senderId: currentUserId.value,
          timestamp: serverTimestamp(),
          type: 'text'
        };

        await addDoc(collection(db, `chats/${props.chatId}/messages`), messageData);
        newMessage.value = '';

        // 상대방의 unreadCount 업데이트
        await updateDoc(doc(db, 'chats', props.chatId), {
          [`unreadCount.${otherUser.value.id}`]: increment(1)
        });

        // 메시지 전송 후 입력 필드에 포커스 유지
        nextTick(() => {
          const inputElement = document.querySelector('.message-input input');
          if (inputElement) {
            inputElement.focus();
          }
        });
      } catch (error) {
        console.error('메시지 전송 실패:', error);
      }
    };

    // 타임스탬프 포맷팅 함수
    const formatTime = (timestamp) => {
      if (!timestamp) return '';
      
      if (!(timestamp instanceof Timestamp)) {
        if (timestamp instanceof Date) {
          return timestamp.toLocaleTimeString('ko-KR', {
            hour: '2-digit',
            minute: '2-digit'
          });
        }
        if (typeof timestamp === 'number') {
          return new Date(timestamp).toLocaleTimeString('ko-KR', {
            hour: '2-digit',
            minute: '2-digit'
          });
        }
        return '';
      }
      
      return timestamp.toDate().toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    // 마지막 접속 시간 포맷팅 함수
    const formatLastSeen = (timestamp) => {
      if (!timestamp) return '알 수 없음';
      
      let date;
      
      if (timestamp instanceof Timestamp) {
        date = timestamp.toDate();
      } else if (timestamp instanceof Date) {
        date = timestamp;
      } else if (typeof timestamp === 'number') {
        date = new Date(timestamp);
      } else {
        return '알 수 없음';
      }
      
      return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2,'0')}`;
    };

    // 뒤로가기 함수
    const goBack = () => {
      window.history.back();
    };

    const resetUnreadCount = async () => {
      try {
        await updateDoc(doc(db, 'chats', props.chatId), {
          [`unreadCount.${currentUserId.value}`]: 0
        });
      } catch (error) {
        console.error('unreadCount 초기화 실패:', error);
      }
    };

    onMounted(async () => {
      // 채팅 데이터 로드
      await loadChatData();

      // 실시간 메시지 업데이트 리스너 설정
      const messagesQuery = query(
        collection(db, `chats/${props.chatId}/messages`),
        orderBy('timestamp', 'asc')
      );

      unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const newMsg = change.doc.data();
            messages.value.push({
              id: change.doc.id,
              ...newMsg
            });
          }
        });
        // 새 메시지 도착시 자동 스크롤
        setTimeout(() => {
          if (messagesContainer.value) {
            messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
          }
        }, 100);
      });
      
      // 온라인 상태로 업데이트
      await updateUserStatus(true);

      // unreadCount 초기화
      await resetUnreadCount();
    });

    // 컴포넌트 언마운트 시 리스너 해제
    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (userStatusUnsubscribe) {
        userStatusUnsubscribe();
      }
      // 오프라인 상태로 업데이트
      updateUserStatus(false);
    });

    return {
      messages,
      newMessage,
      currentUserId,
      otherUser,
      messagesContainer,
      selectedImage,
      goBack,
      goToProfile,
      sendMessage,
      formatTime,
      formatLastSeen,
      handleImageUpload,
      openImageModal
    };
  }
};
</script>

<style scoped>
.chat-room {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e1e1e1;
  transition: transform 0.2s ease;
}

.user-avatar:hover {
  transform: scale(1.05);
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-details h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.online-status {
  font-size: 13px;
  font-weight: 500;
  color: #9e9e9e;
}

.online-status.is-online {
  color: #00c853;
}

.header-actions button {
  padding: 10px 20px;
  border: none;
  background: #f5f5f5;
  color: #333;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-actions button:hover {
  background: #eeeeee;
  transform: translateY(-1px);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  scroll-behavior: smooth;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  gap: 4px;
}

.message-wrapper.sent {
  align-self: flex-end;
}

.message-wrapper.received {
  align-self: flex-start;
}

.message-sender {
  font-size: 13px;
  color: #424242;
  margin-left: 12px;
  margin-bottom: 2px;
}

.message-content-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.message-wrapper.sent .message-content-wrapper {
  flex-direction: row-reverse;
}

.message-bubble {
  padding: 12px 16px;
  background: #f8f9fa;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.3s ease;
  transition: transform 0.2s ease;
}

.message-wrapper.sent .message-bubble {
  background: #2196f3;
  color: white;
}

.message-wrapper.received .message-bubble {
  background: #f8f9fa;
  color: #333;
}

.message-time {
  font-size: 12px;
  color: #757575;
  min-width: 45px;
}

.message-content {
  font-size: 15px;
  line-height: 1.5;
}

.message-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.message:hover {
  transform: translateY(-1px);
}

.message-input {
  display: flex;
  gap: 12px;
  padding: 10px;
  background: #ffffff;
  border-top: 1px solid #eaeaea;
  align-items: center;
}

.message-input input {
  flex: 1;
  padding: 12px 20px;
  border: 2px solid #eaeaea;
  border-radius: 24px;
  font-size: 15px;
  transition: all 0.2s ease;
  background: #f8f9fa;
}

.message-input input:focus {
  border-color: #2196f3;
  background: #ffffff;
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
}

.material-icons {
  font-size: 18px;
}

.image-upload-label {
  padding: 12px;
  background: #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-upload-label:hover {
  background: #e0e0e0;
  transform: translateY(-1px);
}

.message-input button {
  padding: 12px 24px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.message-input button:hover {
  background: #1976d2;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-input button:active {
  transform: translateY(0);
  box-shadow: none;
}

/* 스크롤바 스타일링 */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

@media (max-width: 480px) {
  .message-input input {
    width: 50%;
  }
}
</style>
