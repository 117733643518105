<template>
  <div class="post-detail-container">
    <div v-if="post" class="post-content">
      <div class="post-header">
        <h2>{{ post.title }}</h2>
        <div class="post-meta">
          <div class="author-info">
            <img 
              :src="post.authorProfileImage || require('@/assets/default-profile.jpg')" 
              class="author-profile-image"
              @click="goToProfile(post.authorId)"
              style="cursor: pointer"
            />
            <div class="author-details">
              <span>작성자: <span 
                @click="goToProfile(post.authorId)" 
                style="cursor: pointer; text-decoration: underline"
              >{{ post.authorNickname }}</span></span><br>
              <span>작성일: {{ formatDate(post.createdAt) }}</span>
              <span v-if="post.updatedAt" style="font-size: 12px;">({{ formatDate(post.updatedAt) }} 수정)</span><br>
            </div>
          </div>
        </div>
      </div>

      <div class="post-body">
        <p>{{ post.content }}</p>
        <!-- 이미지 갤러리 추가 -->
        <div v-if="post.images && post.images.length" class="image-gallery">
          <div v-for="(image, index) in post.images" :key="index" class="image-item">
            <img :src="image" :alt="`게시글 이미지 ${index + 1}`">
          </div>
        </div>
      </div>

      <div class="post-actions">
        <button @click="goBack" class="back-button">
          <i class="fas fa-arrow-left"></i> 목록으로
        </button>
        <!-- 작성자만 볼 수 있는 관리 버튼들 -->
        <div v-if="isAuthor" class="author-actions">
          <button @click="editPost" class="edit-button">
            <i class="fas fa-edit"></i> 수정
          </button>
          <button @click="confirmDelete" class="delete-button">
            <i class="fas fa-trash"></i> 삭제
          </button>
        </div>
      </div>

      <!-- 댓글 섹션 -->
      <div class="comments-section">
        <div class="comment-main-header">
          <h3>댓글</h3>
          <button @click="addComment" class="comment-submit">댓글 작성</button>
        </div>
        <!-- 댓글 작성 폼 -->
        <div v-if="auth.currentUser" class="comment-form">
          <textarea 
            v-model="newComment" 
            placeholder="댓글을 작성해주세요"
            rows="3"
          ></textarea>
        </div>
        <div v-else class="login-required">
          <p>댓글을 작성하려면 로그인이 필요합니다.</p>
        </div>

        <!-- 댓글 목록 -->
        <div class="comments-list">
          <div v-for="comment in comments" :key="comment.id" class="comment-item">
            <div class="comment-header">
              <div class="comment-user-info">
                <img 
                  :src="comment.authorProfileImage || require('@/assets/default-profile.jpg')" 
                  class="comment-profile-image"
                  @click="goToProfile(comment.authorId)"
                  style="cursor: pointer"
                />
                <div class="comment-user-details">
                  <span class="comment-author" 
                    @click="goToProfile(comment.authorId)"
                    style="cursor: pointer; text-decoration: underline"
                  >{{ comment.authorName }}</span>
                  
                </div>
              </div>
              <span class="comment-date">{{ formatDate(comment.createdAt) }}</span>
            </div>
            <p class="comment-content">{{ comment.content }}</p>
            
            <!-- 댓글 관리 버튼 -->
            <div class="comment-actions">
              <button v-if="auth.currentUser" @click="toggleReplyForm(comment.id)" class="reply-button">
                답글
              </button>
              <div v-if="comment.authorId === auth.currentUser?.uid">
                <button @click="deleteComment(comment.id)" class="delete-button-small">
                  삭제
                </button>
              </div>
            </div>

            <!-- 답글 작성 폼 -->
            <div v-if="activeReplyId === comment.id" class="reply-form">
              <div class="reply-form-container">
                <textarea 
                  v-model="newReply" 
                  placeholder="답글을 작성해주세요"
                  rows="2"
                  class="reply-textarea"
                ></textarea>
                <div class="reply-form-actions">
                  <button @click="toggleReplyForm(comment.id)" class="reply-cancel-button">
                    취소
                  </button>
                  <button @click="addReply(comment.id)" class="reply-submit-button">
                    답글 작성
                  </button>
                </div>
              </div>
            </div>

            <!-- 답글 목록 -->
            <div class="replies-list">
              <div v-for="reply in comment.replies" :key="reply.id" class="reply-item">
                <div class="reply-header">
                  <div class="reply-user-info">
                    <img 
                      :src="reply.authorProfileImage || require('@/assets/default-profile.jpg')" 
                      class="reply-profile-image"
                      @click="goToProfile(reply.authorId)"
                      style="cursor: pointer"
                    />
                    <div class="reply-user-details">
                      <span class="reply-author"
                        @click="goToProfile(reply.authorId)"
                        style="cursor: pointer; text-decoration: underline"
                      >{{ reply.authorName }}</span>
                      
                    </div>
                  </div>
                  <span class="reply-date">{{ formatDate(reply.createdAt) }}</span>
                </div>
                <p class="reply-content">{{ reply.content }}</p>
                <!-- 답글 삭제 버튼 -->
                <div v-if="reply.authorId === auth.currentUser?.uid" class="reply-actions">
                  <button @click="deleteReply(comment.id, reply.id)" class="delete-button-small">
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 모달 -->
    <div v-if="selectedImage" class="image-modal" @click="closeImage">
      <img :src="selectedImage" alt="확대된 이미지">
    </div>

    <div v-else class="loading">
    
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { doc, getDoc, deleteDoc, collection, addDoc, getDocs, query, where, orderBy} from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, deleteObject } from 'firebase/storage';
import { getCurrentUserType } from '@/services/AuthService';

export default {
  name: 'PostDetail',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const post = ref(null);
    const selectedImage = ref(null);
    const auth = getAuth();
    const storage = getStorage();
    const comments = ref([]);
    const newComment = ref('');
    const newReply = ref('');
    const activeReplyId = ref(null);
    const userType = ref('');

    const isAuthor = computed(() => {
      if (!post.value || !auth.currentUser) return false;
      return post.value.authorId === auth.currentUser.uid;
    });

    const loadPost = async () => {
      try {
        const postId = route.params.id;
        const postDoc = await getDoc(doc(db, 'posts', postId));
        
        if (postDoc.exists()) {
          const postData = {
            id: postDoc.id,
            ...postDoc.data()
          };

          // 작성자의 닉네임과 프로필 이미지 가져오기
          const authorCollectionName = postData.authorType === 'artist' ? 'artists' : 'users';
          const authorDocRef = doc(db, authorCollectionName, postData.authorId);
          const authorDoc = await getDoc(authorDocRef);
          
          if (authorDoc.exists()) {
            postData.authorNickname = authorDoc.data().nickname;
            postData.authorProfileImage = authorDoc.data().profileImageUrl;
          }

          post.value = postData;
          await loadComments();
        } else {
          router.push('/posts');
        }
      } catch (error) {
        console.error('게시글 로딩 오류:', error);
        router.push('/posts');
      }
    };

    const loadComments = async () => {
      try {
        const commentsQuery = query(
          collection(db, 'comments'),
          where('postId', '==', post.value.id),
          orderBy('createdAt', 'desc')
        );
        const commentsSnapshot = await getDocs(commentsQuery);
        
        const commentsData = [];
        for (const docSnapshot of commentsSnapshot.docs) {
          const comment = {
            id: docSnapshot.id,
            ...docSnapshot.data()
          };
          
          // 댓글 작성자 정보 가져오기
          const userDocRef = doc(db, 'users', comment.authorId);
          const artistDocRef = doc(db, 'artists', comment.authorId);
          
          const [userDoc, artistDoc] = await Promise.all([
            getDoc(userDocRef),
            getDoc(artistDocRef)
          ]);

          if (userDoc.exists()) {
            comment.authorProfileImage = userDoc.data().profileImageUrl;
            comment.authorType = 'user';
          } else if (artistDoc.exists()) {
            comment.authorProfileImage = artistDoc.data().profileImageUrl;
            comment.authorType = 'artist';
          }
          
          comment.replies = [];
          const repliesQuery = query(
            collection(db, 'replies'),
            where('commentId', '==', docSnapshot.id),
            orderBy('createdAt', 'asc')
          );
          
          try {
            const repliesSnapshot = await getDocs(repliesQuery);
            for (const replyDoc of repliesSnapshot.docs) {
              const reply = {
                id: replyDoc.id,
                ...replyDoc.data()
              };
              
              // 답글 작성자 정보 가져오기
              const replyUserDocRef = doc(db, 'users', reply.authorId);
              const replyArtistDocRef = doc(db, 'artists', reply.authorId);
              
              const [replyUserDoc, replyArtistDoc] = await Promise.all([
                getDoc(replyUserDocRef),
                getDoc(replyArtistDocRef)
              ]);

              if (replyUserDoc.exists()) {
                reply.authorProfileImage = replyUserDoc.data().profileImageUrl;
                reply.authorType = 'user';
              } else if (replyArtistDoc.exists()) {
                reply.authorProfileImage = replyArtistDoc.data().profileImageUrl;
                reply.authorType = 'artist';
              }
              
              comment.replies.push(reply);
            }
          } catch (error) {
            console.error('답글 로딩 오류:', error);
            comment.replies = [];
          }
          
          commentsData.push(comment);
        }
        comments.value = commentsData;
      } catch (error) {
        console.error('댓글 로딩 오류:', error);
        comments.value = [];
      }
    };

    const addComment = async () => {
      if (!auth.currentUser) {
        alert('로그인이 필요합니다.');
        return;
      }
      if (!newComment.value.trim()) return;

      try {
        userType.value = await getCurrentUserType();
        const userCollection = userType.value === 'artist' ? 'artists' : 'users';
        const userDocRef = doc(db, userCollection, auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : null;
        
        const commentData = {
          postId: post.value.id,
          content: newComment.value,
          authorId: auth.currentUser.uid,
          authorName: userData?.nickname || auth.currentUser.displayName,
          createdAt: new Date()
        };
        
        await addDoc(collection(db, 'comments'), commentData);
        newComment.value = '';
        await loadComments();
      } catch (error) {
        console.error('댓글 작성 오류:', error);
        alert('댓글 작성 중 오류가 발생했습니다.');
      }
    };

    const toggleReplyForm = (commentId) => {
      if (!auth.currentUser) {
        alert('로그인이 필요합니다.');
        return;
      }
      activeReplyId.value = activeReplyId.value === commentId ? null : commentId;
      newReply.value = '';
    };

    const addReply = async (commentId) => {
      if (!auth.currentUser) {
        alert('로그인이 필요합니다.');
        return;
      }
      if (!newReply.value.trim()) return;

      try {
        userType.value = await getCurrentUserType();
        const userDoc = await getDoc(doc(db, userType.value === 'artist' ? 'artists' : 'users', auth.currentUser.uid));
        const userData = userDoc.exists() ? userDoc.data() : null;
        
        const replyData = {
          commentId: commentId,
          content: newReply.value,
          authorId: auth.currentUser.uid,
          authorName: userData?.nickname || auth.currentUser.displayName,
          createdAt: new Date()
        };
        
        await addDoc(collection(db, 'replies'), replyData);
        newReply.value = '';
        activeReplyId.value = null;
        await loadComments();
      } catch (error) {
        console.error('답글 작성 오류:', error);
        alert('답글 작성 중 오류가 발생했습니다.');
      }
    };

    const deleteComment = async (commentId) => {
      if (confirm('댓글을 삭제하시겠습니까?')) {
        try {
          await deleteDoc(doc(db, 'comments', commentId));
          await loadComments();
        } catch (error) {
          console.error('댓글 삭제 오류:', error);
          alert('댓글 삭제 중 오류가 발생했습니다.');
        }
      }
    };

    const deleteReply = async (commentId, replyId) => {
      if (confirm('답글을 삭제하시겠습니까?')) {
        try {
          await deleteDoc(doc(db, 'replies', replyId));
          await loadComments();
        } catch (error) {
          console.error('답글 삭제 오류:', error);
          alert('답글 삭제 중 오류가 발생했습니다.');
        }
      }
    };

    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
    };

    const goBack = () => {
      router.push('/posts');
    };

    const editPost = () => {
      router.push(`/edit-post/${post.value.id}`);
    };

    const deleteImages = async (images) => {
      for (const imageUrl of images) {
        try {
          const imagePath = decodeURIComponent(imageUrl.split('/o/')[1].split('?')[0]);
          const imageRef = storageRef(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {
          console.error('이미지 삭제 오류:', error);
        }
      }
    };

    const confirmDelete = async () => {
      if (confirm('정말로 이 게시글을 삭제하시겠습니까?')) {
        try {
          if (post.value.images && post.value.images.length > 0) {
            await deleteImages(post.value.images);
          }
          await deleteDoc(doc(db, 'posts', post.value.id));
          router.push('/posts');
        } catch (error) {
          console.error('게시글 삭제 오류:', error);
          alert('게시글 삭제 중 오류가 발생했습니다.');
        }
      }
    };

    const openImage = (image) => {
      selectedImage.value = image;
    };

    const closeImage = () => {
      selectedImage.value = null;
    };

    const goToProfile = (authorId) => {
      router.push(`/profile/${authorId}`);
    };

    onMounted(() => {
      loadPost();
    });

    return {
      post,
      formatDate,
      goBack,
      selectedImage,
      openImage,
      closeImage,
      isAuthor,
      editPost,
      confirmDelete,
      comments,
      newComment,
      newReply,
      activeReplyId,
      addComment,
      addReply,
      toggleReplyForm,
      deleteComment,
      deleteReply,
      auth,
      goToProfile
    };
  }
}
</script>

<style scoped>
.post-detail-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
}

.post-content {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.post-header {
  margin-bottom: 20px;
}

.post-header h2 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.post-meta {
  color: #666;
  font-size: 0.9em;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.author-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.author-details {
  flex-direction: column;
}

.post-meta span {
  margin-right: 8px;
}

.post-body {
  margin: 20px 0;
  line-height: 1.6;
}

.post-body p {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.image-gallery {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.image-item {
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;
}

.image-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-item img:hover {
  transform: scale(1.02);
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.image-modal img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.post-actions {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-actions {
  display: flex;
  gap: 10px;
}

.back-button, .edit-button, .delete-button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.back-button {
  background-color: #333;
  color: white;
}

.back-button:hover {
  background-color: #222;
  transform: translateY(-1px);
}

.edit-button {
  background-color: #333;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

/* 댓글 스타일 */
.comments-section {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.comment-main-header {
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
}

.comment-form {
  margin-bottom: 30px;
}

.comment-form textarea {
  width: 93%;
  min-height: 80px;
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  resize: vertical;
  margin-bottom: 12px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.comment-form textarea:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.comment-submit, .reply-button {
  background-color: #333;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  margin: 15px 0;
}

.comment-submit:hover, .reply-button:hover {
  background-color: #222;
  transform: translateY(-1px);
}

.comment-item {
  margin-bottom: 25px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.comment-user-info, .reply-user-info {
  display: flex;
  gap: 10px;
}

.comment-author {
  font-weight: bold;
  font-size: 15px;
}

.comment-date {
  color: #666;
  font-size: 13px;
}

.comment-content {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.5;
}

.comment-actions {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

.reply-button {
  background-color: #333;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.delete-button-small {
  background-color: #f44336;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.3s ease;
  margin: 15px 0;
}

.reply-form {
  margin: 15px 0;
  padding-left: 40px;
  position: relative;
}

.reply-form::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #e0e0e0;
  border-radius: 2px;
}

.reply-form-container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.reply-textarea {
  width: 90%;
  min-height: 60px;
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
  transition: all 0.3s ease;
}

.reply-textarea:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.reply-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.reply-submit-button, .reply-cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reply-submit-button {
  background-color: #333;
  color: white;
}

.reply-cancel-button {
  background-color: #f1f3f5;
  color: #333;
}

.replies-list {
  margin-top: 15px;
  padding-left: 8px;
}

.comment-profile-image, .reply-profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.reply-item {
  margin-top: 12px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.reply-author {
  font-weight: bold;
  font-size: 14px;
}

.reply-date {
  color: #666;
  font-size: 12px;
}

.reply-content {
  font-size: 14px;
  line-height: 1.4;
}

.reply-actions {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
