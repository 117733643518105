//타투 찾기 페이지

<template>
  <div class="find-tattoo-page">
    <!-- 관리자 페이지 버튼 추가 -->
    <div v-if="isAdmin" class="admin-button-container">
      <button class="admin-button" @click="goToAdmin">
        <i class="fas fa-cog"></i>
        관리자 페이지
      </button>
    </div>

    <!-- 히어로 섹션 -->
    <section class="hero-section">
      <div class="hero-content">
        <h1>Find a Tattoo</h1>
        <p>당신에게 맞는 타투를 찾아보세요</p>
        <!-- 검색 버튼 그룹 -->
        <div class="search-container">
          <div class="button-group">
            <button class="action-button" @click="openNearbyModal">
              <span class="material-icons">place</span>
              <span class="button-text">{{ selectedLocationText }}</span>
              <span class="arrow">›</span>
            </button>
            <button class="action-button" @click.prevent="openFilterModal">
              <i class="material-icons">filter_list</i>
              <span class="button-text">필터</span>
              <span class="arrow">›</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- 포트폴리오 이미지 갤러리 -->
    <div class="gallery">
      <div
        v-for="(portfolio, index) in displayedPortfolios"
        :key="portfolio.id"
        class="gallery-item"
        @click="openModal(portfolio)"
      >
        <img
          :src="portfolio.images?.[0] || 'default-portfolio.jpg'"
          :alt="`포트폴리오 이미지 ${index + 1}`"
          class="gallery-image"
        />
        <div v-if="portfolio.distance" class="distance-badge">
          {{ Math.floor(portfolio.distance) }}km
        </div>
      </div>
    </div>

    <!-- 로딩 인디케이터 추가 -->
    <div v-if="isLoading" class="loading-indicator">
      <div class="spinner"></div>
    </div>

    <!-- 포트폴리오 모달 -->
    <div v-if="isModalOpen && selectedPortfolio" class="modal-overlay" @click.self="closeModal">
      <div class="modal">
        <button class="close-button" @click="closeModal">&times;</button>
        <!-- 아티스트 정보 -->
        <div class="artist-info">
          <img 
            :src="selectedPortfolio.artist?.profileImageUrl || 'default-profile.jpg'" 
            alt="아티스트 프로필" 
            class="artist-profile-image" 
          />
          <div class="artist-details">
            <h2 class="artist-name">{{ selectedPortfolio.artist?.nickname || '이름 없음' }}</h2>
            <p class="location">{{ selectedPortfolio.artist?.location?.address.split(' ').slice(0, 3).join(' ') || '위치 정보 없음' }}</p>
          </div>
        </div>
        <!-- 포트폴리오 이미지 -->
        <div class="portfolio-main-image-container">
          <img
            :src="selectedPortfolio.images?.[0] || 'default-portfolio.jpg'"
            alt="포트폴리오 이미지"
            class="portfolio-main-image"
          />
        </div>
        <!-- 포트폴리오 정보 -->
        <div class="portfolio-info">
          <div class="info-row">
            <span class="info-label">장르:</span>
            <span class="info-value">{{ selectedPortfolio.genres?.join(', ') || '장르 정보 없음' }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">시간:</span>
            <span class="info-value">{{ selectedPortfolio.duration || 0 }}시간</span>
          </div>
          <div class="description-box">
            <p>{{ selectedPortfolio.description || '설명이 없습니다.' }}</p>
          </div>
        </div>
        <!-- 하단 버튼 그룹 ��가 -->
        <div class="modal-bottom-buttons">
          <button class="close-modal-button" @click="closeModal">닫기</button>
          <button class="profile-button" @click="contactArtist">프로필</button>
        </div>
      </div>
    </div>

    <!-- 필터 모달 -->
    <div v-if="isFilterModalOpen" class="modal-overlay" @click.self="closeFilterModal">
      <div class="filter-modal">
        <div class="modal-header">
          <h3 class="modal-title">장르 선택</h3>
          <button @click="resetFilter" class="reset-button">초기화</button>
        </div>
        <div class="genre-buttons">
          <button
            v-for="genre in genres"
            :key="genre"
            :class="{'active': selectedGenres.includes(genre)}"
            @click="toggleGenre(genre)"
            class="genre-button"
          >
            {{ genre }}
          </button>
        </div>
        <div class="modal-footer">
          <button @click="closeFilterModal" class="cancel-button">취소</button>
          <button @click="applyFilter" class="apply-button">적용</button>
        </div>
      </div>
    </div>

    <!-- 내 주변 모달 -->
    <div v-if="isNearbyModalOpen" class="modal-overlay" @click.self="closeNearbyModal">
      <div>
        <FindTattooMap 
          :apiKey="googleMapsApiKey" 
          @location-selected="handleLocationSelected" 
          @close="closeNearbyModal" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getDistanceFromLatLonInKm } from '@/utils/distance';
import FindTattooMap from './FindTattooMap.vue';
import { getAuth } from 'firebase/auth';

export default {
  name: 'HomePage',
  components: {
    FindTattooMap,
  },
  data() {
    return {
      portfolios: [], // 포트폴리오 목록
      selectedPortfolio: null, // 선택된 포폴리오
      isModalOpen: false, // 포트폴리오 모달 표시 여부
      isFilterModalOpen: false, // 필터 모달 표시 여부
      isNearbyModalOpen: false, // 주변 검색 모달 표시 여부
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화'], // 타투 장르 목록
      selectedGenres: [], // 선택된 장르 목록
      tempSelectedGenres: [], // 임시 선택 저장용
      userLocation: null, // 사용���의 현재 위치 (위도, 경도)
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY || '', // 환경 변수에서 API 키 가져오기
      isAdmin: false,
      selectedAddress: null, // 선택한 주소 저장
      selectedCoordinates: null,
      pageSize: 10,
      currentPage: 1,
      isLoading: false,
      allPortfolios: [], // 전체 포트폴리오 데이터
    };
  },
  computed: {
    // 선택된 장르에 따라 포트폴리오 필터링
    filteredPortfolios() {
      let filtered = this.portfolios || [];

      // 선택된 장르가 있는 경우에만 필터링 수행
      if (this.selectedGenres.length > 0) {
        filtered = filtered.filter(portfolio =>
          portfolio.genres && portfolio.genres.some(genre => 
            this.selectedGenres.includes(genre)
          )
        );
      }

      // 사용자 위치 정보가 있는 경우 거리 계산 수행
      if (this.userLocation) {
        const userLoc = {
          lat: Number(this.userLocation.lat), // users/{uid}/tattoolocation/coordinates/lat
          lng: Number(this.userLocation.lng)  // users/{uid}/tattoolocation/coordinates/lng
        };

        filtered = filtered.map(portfolio => {
          // artists/{artistId}/locationCoordinates와 비교하여 거리 계산
          if (portfolio.locationCoordinates) {
            const artistLoc = {
              lat: Number(portfolio.locationCoordinates.lat), // artists/{artistId}/locationCoordinates/lat
              lng: Number(portfolio.locationCoordinates.lng)  // artists/{artistId}/locationCoordinates/lng
            };

            // 사용자 위치와 타투이스트 위치 간의 거리 계산
            const distance = getDistanceFromLatLonInKm(
              userLoc.lat,
              userLoc.lng,
              artistLoc.lat,
              artistLoc.lng
            );
            
            // 유효한 거리값인 경우에만 포트폴리오에 거리 정보 추가
            if (!isNaN(distance) && isFinite(distance)) {
              const formattedDistance = Number(distance).toFixed(1);
             
              return {
                ...portfolio,
                distance: formattedDistance
              };
            }
          }
          return portfolio;
        });

        // 거리 정보가 있는 항목을 앞쪽으로 정렬
        filtered.sort((a, b) => {
          if (a.distance && b.distance) {
            return Number(a.distance) - Number(b.distance);
          }
          if (a.distance) return -1;
          if (b.distance) return 1;
          return 0;
        });
      }

      return filtered;
    },
    selectedLocationText() {
      if (this.selectedAddress) {
        // 한국어 주소 형식으로 가공
        const addressParts = this.selectedAddress.split(' ');
        // 시/구/동 정도만 표시
        const relevantParts = addressParts
          .filter(part => 
            part.includes('시') || 
            part.includes('구') || 
            part.includes('동')
          )
          .slice(-2); // 마지막 2개 부분만 사용

        if (relevantParts.length > 0) {
          return `내 주변 (${relevantParts.join(' ')})`;
        }
      }
      return '내 주변';
    },
    displayedPortfolios() {
      const filtered = this.filteredPortfolios || [];
      return filtered.slice(0, this.currentPage * this.pageSize);
    },
    hasMorePortfolios() {
      const filtered = this.filteredPortfolios || [];
      const displayed = this.displayedPortfolios || [];
      return displayed.length < filtered.length;
    }
  },
  methods: {
    // 포트폴리오 데이터 로드
    async loadPortfolios() {
      try {
        const portfoliosSnapshot = await getDocs(collection(db, 'portfolios'));
        const portfolios = [];

        // 각 포트폴리오 문서에 대해
        for (const portfolioDoc of portfoliosSnapshot.docs) {
          const portfolioData = portfolioDoc.data();
          const artistId = portfolioData.artistId;

          if (!artistId) continue;

          // 아티스트 문서 가져오기
          const artistDoc = await getDoc(doc(db, 'artists', artistId));
          let artistData = {};

          if (artistDoc.exists() && artistDoc.data().userType === 'artist') {
            artistData = artistDoc.data();
          } else {
            continue;
          }

          // 위치 정보 처리
          let coordinates = null;
          if (artistData.location?.latitude && artistData.location?.longitude) {
            coordinates = {
              lat: artistData.location.latitude,
              lng: artistData.location.longitude
            };
          }

          // 포트폴리오 객체 생성
          const portfolio = {
            id: portfolioDoc.id,
            ...portfolioData,
            artist: {
              id: artistId,
              nickname: artistData.nickname || '이름 없음',
              profileImageUrl: artistData.profileImageUrl || 'default-profile.jpg',
              shopName: artistData.shopName || '샵 정보 없음',
              location: artistData.location || {},
              career: artistData.career || 0,
              genres: artistData.genres || []
            },
            locationCoordinates: coordinates
          };

          portfolios.push(portfolio);
        }

        this.portfolios = portfolios;
      } catch (error) {
        console.error('포트폴리오 로드 실패:', error);
        this.portfolios = [];
      }
    },
    // 포트폴리오 모달 열기
    openModal(portfolio) {
      if (portfolio) {
        this.selectedPortfolio = portfolio;
        this.isModalOpen = true;
        document.body.style.overflow = 'hidden'; // 배경 스크롤 방지
        
      }
      if (!window.history.state?.modalOpen) {
        window.history.pushState({ modalOpen: true }, '');
      }
    },
    // 포트폴리오 모달 닫기
    closeModal() {
      this.isModalOpen = false;
      this.selectedPortfolio = null;
      // 모달이 열려있고 히스토리 상태가 있을 때만 뒤로가기
      if (window.history.state?.modalOpen) {
        window.history.back();
      }
    },
    // 포트폴리오 모달 닫기
    closeModal2() {
      this.isModalOpen = false;
      this.selectedPortfolio = null;
    },
    // 필터 모달 열기 수정
    openFilterModal() {
      this.isFilterModalOpen = true;
      this.tempSelectedGenres = [...this.selectedGenres];
      history.replaceState({ ...history.state }, '', window.location.pathname);
    },
    // 필터 모달 닫기
    closeFilterModal() {
      this.isFilterModalOpen = false;
      // 취소 시 이전 선택 상태로 복원
      this.selectedGenres = [...this.tempSelectedGenres];
    },
    // 장르 토글
    toggleGenre(genre) {
      const index = this.selectedGenres.indexOf(genre);
      if (index > -1) {
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(genre);
      }
    },
    // 필터 적용
    applyFilter() {
      // 현재 선택을 임시 배열에도 저장
      this.tempSelectedGenres = [...this.selectedGenres];
      this.closeFilterModal();
    },
    // 주변 검색 모달 열기 수정
    openNearbyModal() {
      this.isNearbyModalOpen = true;
      history.pushState(null, '', window.location.pathname);
    },
    // 주변 검색 모달 닫기
    closeNearbyModal() {
      this.isNearbyModalOpen = false;
    },
    // 상담하기 버튼 클릭 시 실행
    contactArtist() {
      if (this.selectedPortfolio && this.selectedPortfolio.artist?.id) {
        const artistId = this.selectedPortfolio.artist.id;
        this.closeModal2();
        this.$router.push(`/profile/${artistId}`);
      } else {
        console.error('아티스트 정보를 찾을 수 없습니다.');
      }
    },
    // 위치 선택 후 처리
    async handleLocationSelected(location) {
      try {
        if (location.coordinates) {
          // 좌표 정보 업데이트
          this.selectedCoordinates = {
            lat: location.coordinates.lat,
            lng: location.coordinates.lng
          };
          this.userLocation = this.selectedCoordinates;

          // 주소 정보 업데이트
          if (location.addressComponents) {
            // 한국어 주소 형식으로 변환
            const koreanAddress = this.formatKoreanAddress(location.addressComponents);
            this.selectedAddress = koreanAddress;
          } else {
            this.selectedAddress = location.address;
          }

          // 포트폴리오 목록 새로고침
          await this.loadPortfolios();
        }
      } catch (error) {
        console.error('위치 정보 처리 중 오류:', error);
      }
    },
    resetFilter() {
      this.selectedGenres = [];
      this.tempSelectedGenres = [];
    },
    // 관리자 권한 체크
    async checkAdminStatus() {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          this.isAdmin = userDoc.data().role === 'admin';
        }
      }
    },
    // 관리자 페이지로 이동
    goToAdmin() {
      this.$router.push('/admin');
    },
    // 컴포넌트 마운트 시 저장된 위치 정보 불러오기
    async loadSavedLocation() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          
          if (userData?.tattoolocation) {
            this.selectedCoordinates = userData.tattoolocation.coordinates;
            this.userLocation = this.selectedCoordinates;
            this.selectedAddress = userData.tattoolocation.address;
          }
        }
      } catch (error) {
        console.error('저장된 위치 정보 로드 실패:', error);
      }
    },
    // 구글맵스 주소 컴포넌트를 한국어 주소 형식으로 변환
    formatKoreanAddress(addressComponents) {
      const addressParts = [];
      const componentTypes = {
        'administrative_area_level_1': '', // 시/도
        'sublocality_level_1': '',        // 구
        'sublocality_level_2': '',        // 동
        'sublocality_level_3': '',        // 상세 주소
      };

      // 주소 컴포넌트 분류
      addressComponents.forEach(component => {
        component.types.forEach(type => {
          if (type in componentTypes) {
            componentTypes[type] = component.long_name;
          }
        });
      });

      // 한국어 주소 조합
      if (componentTypes['administrative_area_level_1']) {
        addressParts.push(componentTypes['administrative_area_level_1']);
      }
      if (componentTypes['sublocality_level_1']) {
        addressParts.push(componentTypes['sublocality_level_1']);
      }
      if (componentTypes['sublocality_level_2']) {
        addressParts.push(componentTypes['sublocality_level_2']);
      }
      if (componentTypes['sublocality_level_3']) {
        addressParts.push(componentTypes['sublocality_level_3']);
      }

      return addressParts.join(' ');
    },
    // 뒤로가기 이벤트 핸들러 추가
    handlePopState() {
      if (this.isModalOpen) this.closeModal();
      if (this.isFilterModalOpen) this.closeFilterModal();
      if (this.isNearbyModalOpen) this.closeNearbyModal();
    },
    // 스크롤 핸들러 추가
    async handleScroll() {
      if (this.isLoading || !this.hasMorePortfolios) return;

      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.offsetHeight;
      
      // 페이지 하단에 도달했는지 확인 (100px 여유)
      if (scrollPosition >= documentHeight - 100) {
        await this.loadMorePortfolios();
      }
    },
    // 추가 포트폴리오 로드
    async loadMorePortfolios() {
      if (this.isLoading || !this.hasMorePortfolios) return;

      this.isLoading = true;
      
      try {
        // 다음 페이지로 이동
        this.currentPage++;
        
        // 로딩 시뮬레이션 (선택사항)
        await new Promise(resolve => setTimeout(resolve, 500));
      } catch (error) {
        console.error('추가 포트폴리오 로드 실패:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    await this.loadPortfolios();
    await this.checkAdminStatus();
  },
  async mounted() {
    await this.loadSavedLocation();
    await this.loadPortfolios();
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
.find-tattoo-page {
  background-color: #f8f9fa;
  min-height: 100vh;
  width: 100%;
}

.hero-section {
  background: linear-gradient(135deg, #000000 0%, #333333 100%);
  color: white;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  margin-top: 60px;
  margin-bottom: 20px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700;
  margin: 0;
}

.hero-content p {
  font-size: 20px;
  margin-bottom: 30px;
  opacity: 0.9;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.button-group {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

.action-button {
  flex: 1;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #2d3436;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.action-button .material-icons {
  font-size: 16px;
  color: #2d3436;
}

.button-text {
  flex: 1;
  text-align: left;
}

.arrow {
  font-size: 20px;
  opacity: 0.5;
}

/* 갤러리 스타일 수정 */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  box-sizing: border-box;
}

/* 갤러리 아이템 스타일 */
.gallery-item {
  position: relative;
  width: 100%;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 갤러리 이미지 스타일 */
.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
  display: block;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.05);
}

/* 모달 오버레이 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 더 어둡고 투명한 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 다른 요소들보다 위에 표시 */
  animation: fadeIn 0.3s ease;
}

/* 모달 콘텐츠 스타일 */
.modal {
  background-color: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease;
  padding: 20px;
}

/* 반응형 이미지 컨테이너 */
.portfolio-main-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.portfolio-main-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

/* 아티스트 정보 스타일 */
.artist-info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.artist-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.artist-details {
  flex: 1;
}

.artist-name {
  font-size: 1.5em;
  margin: 0;
  color: #2d3436;
}

.location {
  color: #636e72;
  margin: 5px 0;
}

/* 포트폴리오 정보 스타일 */
.portfolio-info {
  margin-top: 15px;
}

.info-row {
  display: flex;
  margin-bottom: 8px;
}

.info-label {
  width: 50px;
  font-weight: bold;
  color: #2d3436;
}

.info-value {
  flex: 1;
  color: #636e72;
}

/* 설명 박스 스타일 */
.description-box {
  margin-top: 10px;
}

.description-box p {
  color: #2d3436;
  line-height: 1.6;
}

/* 하단 버튼 그룹 스타일 */
.modal-bottom-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.close-modal-button,
.profile-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.close-modal-button {
  background-color: #dfe6e9;
  color: #2d3436;
}

.close-modal-button:hover {
  background-color: #b2bec3;
}

.profile-button {
  background-color: #00b894;
  color: #ffffff;
}

.profile-button:hover {
  background-color: #55efc4;
}

/* 닫기 버튼 스타일 */
.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2em;
  color: #636e72;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #2d3436;
}

/* 애니메이션 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .artist-profile-image {
    width: 60px;
    height: 60px;
  }

  .artist-name {
    font-size: 1.3em;
  }

  .info-label {
    width: 50px;
    font-size: 0.95em;
  }

  .close-button {
    font-size: 1.5em;
    top: 10px;
    right: 15px;
  }

  .close-modal-button,
  .profile-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

/* 거리 정보 스타일 추가 */
.distance {
  font-size: 14px;
  color: #2d3436;
  margin-left: 10px;
}

/* 필터 모달 푸터 스타일 수정 */
.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
}

/* 초기화 버튼 스타일 추가 */
.reset-button {
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: #f0f0f0;
  color: #636e72;
  transition: background-color 0.2s;
}

/* 모달 더 스타일 수정 */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  padding: 0 20px;
}

/* 모달 타이틀 스타일 수정 */
.modal-title {
  font-size: 20px;
  color: #2d3436;
  font-weight: 600;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* 초기화 버튼 스타일 수정 */
.reset-button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  background-color: #f0f0f0;
  color: #636e72;
  transition: background-color 0.2s;
  margin-left: auto;
}

.reset-button:hover {
  background-color: #e0e0e0;
}

/* 관리자 버튼 스타일 추가 */
.admin-button-container {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 100;
}

.admin-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-button:hover {
  background-color: #34495e;
}

.admin-button i {
  font-size: 16px;
}

.distance-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

/* 하단 버튼 그룹 스타일 추가 */
.modal-bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px;
}

/* 프로필 버튼과 닫기 버튼 스타일 수정 */
.profile-button, .close-modal-button {
  padding: 10px 20px;
  width: 48%;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.close-modal-button {
  background-color: #e74c3c;
  color: #ffffff;
}

.profile-button {
  background-color: #27ae60;
  color: #ffffff;
}

@media (max-width: 768px) {

  .button-group {
    flex-direction: column;
    padding: 0 20px;
  }

  .button-group {
    flex-direction: column;
    padding: 0 20px;
  }

  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }

  .modal {
    width: 95%;
    padding: 15px;
    margin: 10px;
  }

  .portfolio-image {
    width: 250px;
    height: 250px;
  }

  .artist-profile-image {
    width: 60px;
    height: 60px;
  }

  .artist-name {
    font-size: 20px;
  }
  
  .filter-modal {
    width: 95%;
    margin: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }
}

/* 필터 모달 오버레이 스타일 */
.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 더 어둡고 투명한 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* 다른 요소보다 위에 위치 */
  animation: fadeIn 0.3s ease;
}

/* 필터 모달 콘텐츠 스타일 */
.filter-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  z-index: 10000;
  position: relative;
}

/* 필터 모달 닫기 버튼 스타일 */
.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #636e72;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #2d3436;
}

/* 필터 모달 헤더 스타일 */
.modal-header {
  text-align: center;
}

.modal-header h3 {
  font-size: 1.8em;
  color: #2d3436;
  margin: 0;
}

/* 필터 모달 장르 버튼 스타일 */
.genre-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 12px;
  padding: 15px;
  margin: 20px 0;
  align-items: center;
  justify-items: left;
}

.genre-button {
  width: 100%;
  padding: 10px 10px;
  background-color: #f0f0f0;
  color: #2d3436;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.genre-button.active {
  background-color: #2d3436;
  color: #ffffff;
}


/* 필터 모달 푸터 버튼 스타일 */
.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
}

.apply-button, .cancel-button {
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.apply-button {
  background-color: #2d3436;
  color: #ffffff;
}

/* 애니메이션 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity:1; }
}

/* 로딩 인디케이터 스타일 추가 */
.loading-indicator {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style> 