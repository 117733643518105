// 타투이스트가 보이는 견적 페이지

<template>
  <div class="tattoo-request-page">
    <!-- 페이지 제목 -->
    <h2 class="title">견적 요청 목록</h2>
        
    <!-- 필터링 옵션 -->
    <div class="filter-options">
      <!-- 정렬 옵션 -->
      <div class="option-group">
        <i class="fas fa-sort"></i>
        <select id="sort-order" v-model="sortOrder" class="custom-select">
          <option value="latest">최신견적순</option>
          <option value="endingSoon">마감임박순</option>
          <option value="preferredTime">희망일자순</option>
        </select>
      </div>

      <!-- 지역 필터 -->
      <div class="filter-section">
        <div class="filter-title">지역</div>
        <div class="filter-content">
          <div class="option-group">
            <i class="fas fa-map-marker-alt"></i>
            <select v-model="filterCity" class="custom-select">
              <option value="">시/도 선택</option>
              <option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
            </select>
          </div>

          <div v-if="filterCity" class="option-group">
            <i class="fas fa-map-pin"></i>
            <select v-model="filterDistrict" class="custom-select">
              <option value="">구/군 선택</option>
              <option v-for="district in districts" :key="district" :value="district">{{ district }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- 장르 필터 -->
      <div class="filter-section">
        <div class="filter-title">장르</div>
        <div class="filter-content">
          <div class="option-group">
            <i class="fas fa-paint-brush"></i>
            <select v-model="filterGenre" class="custom-select">
              <option value="">장르 선택</option>
              <option v-for="genre in genres" :key="genre" :value="genre">{{ genre }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
        
    <!-- 로딩 상태 표시 -->
    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
      로딩 중...
    </div>
        
    <!-- 로딩이 끝난 후의 콘텐츠 -->
    <div v-else>
      <!-- 견적 요청이 없을 때 표시 -->
      <div v-if="!filteredRequests || filteredRequests.length === 0" class="no-requests">
        <i class="far fa-folder-open"></i>
        <p>현재 진행 중인 견적 요청이 없습니다.</p>
      </div>
          
      <!-- 견적 요청이 있을 때 표시 -->
      <div v-else class="requests-grid">
        <!-- 각 견적 요청을 카드 형태로 반복 렌더링 -->
        <div 
          v-for="request in filteredRequests" 
          :key="request.id" 
          class="request-card"
        >
          <!-- 요청의 헤더 부분 -->
          <div class="request-header">
            <h3>마감까지 {{ getRemainingTime(request.createdAt) }}</h3>
            <span class="count-badge">{{ request.quotes.length }}/10</span>
          </div>

          <!-- 요청의 본문 부분 -->
          <div class="request-body">
            <!-- 요청 정보 테이블 -->
            <table class="info-table">
              <tbody>
                <tr>
                  <th><i class="fas fa-map"></i> 희망지역</th>
                  <td>{{ request.city }} {{ request.district }}</td>
                </tr>
                <tr>
                  <th><i class="far fa-calendar"></i> 희망일자</th>
                  <td>{{ formatDate(request.preferredDate) }}</td>
                </tr>
                <tr>
                  <th><i class="fas fa-clock"></i> 희망시간</th>
                  <td>{{ request.preferredTime }}</td>
                </tr>
                <tr>
                  <th><i class="fas fa-image"></i> 참고이미지</th>
                  <td>
                    <div class="image-gallery">
                      <img v-for="(img, index) in request.referenceImages" 
                           :key="index" 
                           :src="img" 
                           alt="참고 이미지"
                           class="reference-image" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th><i class="far fa-clock"></i> 장르</th>
                  <td>{{ request.tattooType }}</td>
                </tr>
                <tr>
                  <th><i class="fas fa-map-marker-alt"></i> 타투 부위</th>
                  <td>{{ request.location }}</td>
                </tr>
                <tr v-if="request.quotes">
                  <th><i class="fas fa-file-invoice-dollar"></i> 사이즈</th>
                  <td>{{ request.size }}</td>
                </tr>
                <tr>
                  <th><i class="fas fa-won-sign"></i> 예산</th>
                  <td>{{ request.budget }}</td>
                </tr>
                <tr>
                  <th><i class="fas fa-comment"></i> 상세설명</th>
                  <td>{{ request.description }}</td>
                </tr>
                <tr v-if="hasSubmittedQuote(request)">
                  <th><i class="fas fa-info-circle"></i> 견적상태</th>
                  <td>
                    <div class="status-badge" :class="getQuoteViewStatus(request)">
                      {{ getQuoteViewStatus(request) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- 견적 작성 버튼 -->
            <button 
              @click="openQuoteModal(request)" 
              class="quote-button"
              :class="{ 'disabled': isExpired(request.createdAt) || (request.quotes && request.quotes.length >= 10) || hasSubmittedQuote(request) }"
              :disabled="isExpired(request.createdAt) || (request.quotes && request.quotes.length >= 10) || hasSubmittedQuote(request)"
            >
              <i class="fas fa-pen"></i>
              {{ getQuoteButtonText(request) }}
            </button>
          </div>
        </div>
      </div>
    </div>
        
    <!-- 견적 작성 모달 -->
    <div v-if="isQuoteModalOpen" class="modal-overlay" @click.self="closeQuoteModal">
      <div class="modal">
        <div class="modal-header">
          <h3>견적서 작성</h3>
          <button class="close-button" @click="closeQuoteModal">
            <i class="fas fa-times"></i>
          </button>
        </div>
    
        <!-- 견적 작성 폼 -->
        <form @submit.prevent="submitQuote" class="quote-form">
          <!-- 견적 메시지 입력 -->
          <div class="form-group">
            <label for="quoteMessage">
              <i class="fas fa-comment-alt"></i>
              견적 메시지
            </label>
            <textarea 
              id="quoteMessage" 
              v-model="currentQuote.quoteMessage" 
              required 
              rows="4" 
              placeholder="견적 메시지를 입력하세요."
            ></textarea>
          </div>

          <!-- 견적 가격 입력 -->
          <div class="form-group">
            <label for="quotePrice">
              <i class="fas fa-dollar-sign"></i>
              견적 가격
            </label>
            <input 
              type="number" 
              id="quotePrice" 
              v-model="currentQuote.quotePrice" 
              required 
              min="0" 
              placeholder="예: 500000"
            />
          </div>

          <!-- 예상 소요시간 입력 -->
          <div class="form-group">
            <label for="estimatedTime">
              <i class="fas fa-hourglass-half"></i>
              예상 소요시간 (시간 단위)
            </label>
            <input 
              type="number" 
              id="estimatedTime" 
              v-model="currentQuote.estimatedTime" 
              required 
              min="1" 
              placeholder="예: 5"
            />
          </div>

          <!-- 제출 및 취소 버튼 -->
          <div class="button-group">
            <button type="submit" class="submit-button">
              제출
            </button>
            <button type="button" class="cancel-button" @click="closeQuoteModal">
              취소
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// 필요한 Firebase 및 서비스 모듈 import
import { getCurrentUserType } from '@/services/AuthService';
import { getAuth } from 'firebase/auth';
import { collection, query, where, onSnapshot, serverTimestamp, updateDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '@/firebaseConfig';

export default {
  name: 'TattooRequestPage',

  // 컴포넌트의 반응형 데이터 정의
  data() {
    return {
      userType: null, // 사용자 유형(artist/customer)
      tattooRequests: [], // 타투 견적 요청 목록
      loading: true, // 로딩 상태
      isQuoteModalOpen: false, // 견적 작성 모달 표시 여부
      currentQuote: { // 현재 작성 중인 견적 정보
        requestId: '',
        quoteMessage: '',
        quotePrice: '',
        estimatedTime: '', // 예상 소요시간 추가
      },
      submittedQuotes: 0, // 제출한 견적 수
      unsubscribe: null, // Firestore 리스너 해제 함수
      intervalId: null, // 실시간 업데이트를 위한 인터벌 ID
      filterCategory: '', // 필터링 카테고리
      filterGenre: '', // 필터링 장르
      filterCity: '', // 필터링 시/도
      filterDistrict: '', // 필터링 구/군
      sortOrder: 'latest', // 정렬 순서
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화'], // 장르 목록
      cities: ['서울', '경기', '강원', '경남', '경북', '광주', '대구', '대전', '부산', '세종', '울산', '인천', '전남', '전북', '제주', '충남', '충북'], // 시/도 목록
      districts: [], // 구/군 목록 (시/도 선택에 따라 동적으로 변경)
    };
  },

  computed: {
    // 필터링 및 정렬된 요청 목록
    filteredRequests() {
      let requests = [...this.tattooRequests];

      // 필터링 적용
      if (this.filterCity) {
        requests = requests.filter(request => request.city === this.filterCity);
      }

      if (this.filterDistrict) {
        requests = requests.filter(request => request.district === this.filterDistrict);
      }

      if (this.filterGenre) {
        requests = requests.filter(request => request.tattooType === this.filterGenre);
      }

      // 상태가 'open'이고 마감되지 않은 요청만 필터링
      requests = requests.filter(request => {
        if (request.status !== 'open') return false;
        if (!request.createdAt || !request.createdAt.toDate) return false;

        const now = new Date();
        const createdTime = request.createdAt.toDate();
        const diff = now - createdTime;
        const totalHours = diff / (1000 * 60 * 60); // 밀리초를 시간으로 변환

        return totalHours < 72;
      });

      // 정렬 적용
      switch (this.sortOrder) {
        case 'latest':
          requests.sort((a, b) => {
            const remainingA = this.getRemainingTimeInSeconds(a.createdAt);
            const remainingB = this.getRemainingTimeInSeconds(b.createdAt);
            return remainingB - remainingA;
          });
          break;

        case 'endingSoon':
          requests.sort((a, b) => {
            const remainingA = this.getRemainingTimeInSeconds(a.createdAt);
            const remainingB = this.getRemainingTimeInSeconds(b.createdAt);
            return remainingA - remainingB;
          });
          break;

        case 'preferredTime':
          requests.sort((a, b) => {
            const dateA = new Date(a.preferredDate);
            const dateB = new Date(b.preferredDate);
            return dateA - dateB;
          });
          break;
      }

      return requests;
    }
  },

  watch: {
    // 시/도가 변경될 때 구/군 목록 업데이트
    filterCity(newCity) {
      this.filterDistrict = ''; // 구/군 선택 초기화
      this.districts = this.getDistricts(newCity);
    }
  },

  // 컴포넌트 생성 시 실행
  async created() {
    // Firebase Auth 초기화 대기
    const auth = getAuth();
    await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe(); // 리스너 해제
        resolve(user);
      });
    });

    await this.checkUserType(); // 사용자 유형 확인
    if (this.userType === 'artist') {
      this.startRealTimeUpdate(); // 실시간 업데이트 시작
    }
  },

  // 컴포넌트 제거 전 실행
  beforeUnmount() {
    // Firestore 리스너가 있다면 해제
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    // 실시간 업데이트 인터벌 해제
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    // 시/도에 따른 구/군 목록을 반환하는 메서드
    getDistricts(city) {
      const districts = {
        서울: ['강남구', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구',
          '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구',
          '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'],
        강원: ['강릉시', '고성군', '동해시', '삼척시', '속초시', '양구군', '양양군', '영월군',
          '원주시', '인제군', '정선군', '철원군', '춘천시', '태백시', '평창군', '홍천군',
          '화천군', '횡성군'],
        경기: ['가평군', '고양시', '과천시', '광명시', '광주시', '구리시', '군포시', '김포시',
          '남양주시', '동두천시', '부천시', '성남시', '수원시', '시흥시', '안산시', '안성시',
          '안양시', '양주시', '양평군', '여주시', '연천군', '오산시', '용인시', '의왕시',
          '의정부시', '이천시', '파주시', '평택시', '포천시', '하남시', '화성시'],
        경남: ['거제시', '거창군', '고성군', '김해시', '남해군', '밀양시', '사천시', '산청군',
          '양산시', '의령군', '진주시', '창원시', '통영시', '하동군', '함안군',
          '함양군', '합천군'],
        경북: ['경산시', '경주시', '고령군', '구미시', '군위군', '김천시', '문경시', '봉화군',
          '상주시', '성주군', '안동시', '영덕군', '영양군', '영주시', '영천시', '예천군',
          '울릉군', '울진군', '의성군', '청도군', '청송군', '칠곡군', '포항시'],
        광주: ['광산구', '남구', '동구', '북구', '서구'],
        대구: ['남구', '달서구', '동구', '북구', '서구', '수성구', '중구'],
        대전: ['대덕구', '동구', '서구', '유성구', '중구'],
        부산: ['강서구', '금정구', '남구', '동구', '동래구', '부산진구', '북구', '사상구',
          '사하구', '서구', '수영구', '연제구', '영도구', '중구', '해운대구'],
        세종: ['세종시'],
        울산: ['남구', '동구', '북구', '중구', '울주군'],
        인천: ['계양구', '남동구', '동구', '미추홀구', '부평구', '서구', '연수구', '중구'],
        전남: ['강진군', '고흥군', '곡성군', '광양시', '구례군', '나주시', '담양군', '목포시',
          '무안군', '보성군', '순천시', '신안군', '여수시', '영광군', '영암군', '완도군',
          '장성군', '장흥군', '진도군', '함평군', '해남군', '화순군'],
        전북: ['고창군', '군산시', '김제시', '남원시', '무주군', '부안군', '순창군', '완주군',
          '익산시', '임실군', '장수군', '전주시', '정읍시', '진안군'],
        제주: ['서귀포시', '제주시'],
        충남: ['계룡시', '공주시', '금산군', '논산시', '당진시', '보령시', '부여군', '서산시',
          '서천군', '아산시', '예산군', '천안시', '청양군', '태안군', '홍성군'],
        충북: ['괴산군', '단양군', '보은군', '영동군', '옥천군', '음성군', '제천시', '진천군',
          '청주시', '충주시', '증평군']
      };
      return districts[city] || [];
    },

    // 사용자 유형을 확인하고 필요한 데이터를 로드하는 메서드
    async checkUserType() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          this.$router.push('/login');
          return;
        }

          // 사용자 유형 확인
      this.userType = await getCurrentUserType();
      console.log('User Type:', this.userType); // 디버깅용 로그

      if (this.userType === 'OKartist') {
        alert('OKartist인 경우 승인 후 이용 가능합니다.');
        this.$router.push('/'); // 메인 페이지 경로를 확인하세요
        return;
      }

      if (this.userType !== 'artist') {
        alert('타투이스트만 접근할 수 있는 페이지입니다.');
        this.$router.push('/');
        return;
      }

        // 타투 요청 목록과 제출한 견적 수 조회
        await this.fetchTattooRequests();
        await this.countSubmittedQuotes();
      } catch (error) {
        console.error('사용자 유형 확인 실패:', error);
        this.$router.push('/login');
      }
    },

    // Firestore에서 타투 요청 목록을 실시간으로 가져오는 메서드
    async fetchTattooRequests() {
      try {
        const requestsRef = collection(db, 'tattooRequests');
        // status가 'open'인 요청만 필터링
        const q = query(requestsRef, where('status', '==', 'open'));

        // 실시간 리스너 설정
        this.unsubscribe = onSnapshot(q, (snapshot) => {
          const requests = [];
          snapshot.forEach((docSnap) => {
            requests.push({ id: docSnap.id, ...docSnap.data() });
          });
          this.tattooRequests = requests;
          this.loading = false;
        }, (error) => {
          console.error('견적 요청 불러오기 오류:', error);
          this.loading = false;
        });
      } catch (error) {
        console.error('견적 요청 불러오기 실패:', error);
        this.loading = false;
      }
    },

    // Firestore에서 현재 사용자가 제출한 견적 수를 계산하는 메서드
    async countSubmittedQuotes() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('사용자가 인증되지 않았습니다.');
        }

        // 사용자가 제출한 견적이 포함된 요청을 조회
        const requestsRef = collection(db, 'tattooRequests');
        const q = query(requestsRef, where('quotes.artistId', '==', user.uid));
        const querySnapshot = await getDocs(q);

        // 견적 수 계산
        let count = 0;
        querySnapshot.forEach(docSnap => {
          const quotes = docSnap.data().quotes || [];
          quotes.forEach(quote => {
            if (quote.artistId === user.uid) {
              count += 1;
            }
          });
        });

        this.submittedQuotes = count;
      } catch (error) {
        console.error('견적서 수 조회 실패:', error);
      }
    },

    // 견적 요청의 남은 시간을 초 단위로 계산하는 메서드
    getRemainingTimeInSeconds(createdAt) {
      if (!createdAt || !createdAt.toDate) return 0;
      const now = new Date();
      const createdTime = createdAt.toDate();
      const diff = now - createdTime;
      const totalSeconds = Math.floor(diff / 1000);
      const remainingSeconds = 72 * 60 * 60 - totalSeconds;
      return remainingSeconds > 0 ? remainingSeconds : 0;
    },

    // 견적 요청의 남은 시간을 문자열로 반환하는 메서드
    getRemainingTime(createdAt) {
      if (!createdAt || !createdAt.toDate) return 'N/A';
      try {
        const now = new Date();
        const createdTime = createdAt.toDate();
        const diff = now - createdTime;
        const totalSeconds = Math.floor(diff / 1000);
        const remainingSeconds = 72 * 60 * 60 - totalSeconds;
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        const seconds = remainingSeconds % 60;
        return `${hours}시간 ${minutes}분 ${seconds}초`;
      } catch (error) {
        console.error('시간 계산 오류:', error);
        return 'N/A';
      }
    },

    // 견적 버튼의 텍스트를 결정하는 메서드
    getQuoteButtonText(request) {
      if (this.isExpired(request.createdAt)) {
        return '마감됨';
      }
      if (request.quotes && request.quotes.length >= 10) {
        return '견적 수 한도 도달';
      }
      return '견적 작성';
    },

    // 견적 요청이 만료되었는지 확인하는 메서드
    isExpired(createdAt) {
      if (!createdAt) return false;
      const now = new Date();
      const createdTime = createdAt.toDate();
      const diff = now - createdTime;
      const maxDuration = 72 * 60 * 60 * 1000; // 72시간
      return diff > maxDuration;
    },

    // 사용자가 이미 견적을 제출했는지 확인하는 메서드
    hasSubmittedQuote(request) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user || !request.quotes) return false;
      return request.quotes.some(quote => quote.artistId === user.uid);
    },

    // 견적서 확인 상태를 가져오는 메서드
    getQuoteViewStatus(request) {
      const userId = getAuth().currentUser.uid;
      const userQuote = request.quotes.find(quote => quote.artistId === userId);
      return userQuote?.viewed ? '견적서 확인 완료' : '견적서 확인 대기';
    },

    // 견적 작성 모달을 여는 메서드
    openQuoteModal(request) {
      this.currentQuote = {
        requestId: request.id,
        quoteMessage: '',
        quotePrice: '',
        estimatedTime: '', // 예상 소요시간 초기화
      };
      this.isQuoteModalOpen = true;
    },

    // 견적 작성 모달을 닫는 메서드
    closeQuoteModal() {
      this.isQuoteModalOpen = false;
      this.resetQuoteForm();
    },

    // 견적 폼을 초기화하는 메서드
    resetQuoteForm() {
      this.currentQuote = {
        requestId: '',
        quoteMessage: '',
        quotePrice: '',
        estimatedTime: '', // 예상 소요시간 초기화
      };
    },

    // 견적을 제출하는 메서드
    async submitQuote() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('사용자가 인증되지 않았습니다.');
        }

        const { requestId, quoteMessage, quotePrice, estimatedTime } = this.currentQuote;

        // 타투이스트의 위치 정보 가져오기
        const artistRef = doc(db, 'artists', user.uid);
        const artistDoc = await getDoc(artistRef);

        if (!artistDoc.exists()) {
          throw new Error('타투이스트 정보를 찾을 수 없습니다.');
        }

        const artistData = artistDoc.data();
        const artistAddress = artistData.location?.address || '주소 정보 없음';
        const dongAddress = artistAddress.split(' ').slice(0, 3).join(' '); // ~~동까지의 주소

        // 견적 데이터 구성
        const quoteData = {
          artistId: user.uid,
          quoteMessage,
          quotePrice: parseInt(quotePrice, 10),
          estimatedTime: parseInt(estimatedTime, 10),
          createdAt: new Date(),
          viewed: false,
          artistAddress: dongAddress // 타투이스트의 위치 정보 추가
        };

        // 견적 요청 문서 참조
        const requestRef = doc(db, 'tattooRequests', requestId);
        const requestSnap = await getDoc(requestRef);
        if (!requestSnap.exists()) {
          throw new Error('견적 요청을 찾을 수 없습니다.');
        }

        const existingQuotes = requestSnap.data().quotes || [];

        // 견적 수 제한 확인
        if (existingQuotes.length >= 10) {
          throw new Error('견적 수 한도를 초과했습니다.');
        }

        // 중복 제출 확인
        if (existingQuotes.some(quote => quote.artistId === user.uid)) {
          throw new Error('이미 이 견적 요청에 대해 견적서를 제출했습니다.');
        }

        // 견적 추가 및 문서 업데이트
        await updateDoc(requestRef, {
          quotes: [...existingQuotes, quoteData],
          lastUpdated: serverTimestamp()
        });

        alert('견적서를 성공적으로 제출했습니다.');
        this.closeQuoteModal();
      } catch (error) {
        console.error('견적서 제출 실패:', error);
        alert('견적서를 제출하는 중 오류가 발생했습니다.');
      }
    },

    // 날짜 포맷팅 헬퍼 메서드
    formatDate(date) {
      if (!date) return '';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      };
      return new Date(date).toLocaleDateString('ko-KR', options);
    },

    // 실시간 업데이트를 시작하는 메서드
    startRealTimeUpdate() {
      this.intervalId = setInterval(() => {
        this.$forceUpdate();
      }, 1000); // 1초마다 업데이트
    }
  },
};
</script>

<style scoped>
.tattoo-request-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #2d3436;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.place-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.filter-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #f1f1f1;
}

.option-group {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.option-group i {
  color: #666666;
  font-size: 16px;
  position: absolute;
  left: 12px;
  pointer-events: none;
}

.custom-select {
  padding: 12px 16px 12px 36px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  background-color: #f8f9fa;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  min-width: 140px;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  transition: all 0.2s ease;
}

.custom-select:hover {
  background-color: #f1f1f1;
  border-color: #ddd;
}

.custom-select:focus {
  outline: none;
  border-color: #666666;
  box-shadow: 0 0 0 3px rgba(102, 102, 102, 0.1);
}

.place-filter {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .filter-options {
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
  }

  .place-filter {
    flex-direction: column;
    width: 100%;
  }

  .option-group {
    width: 100%;
  }

  .custom-select {
    width: 100%;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #636e72;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #666666;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-requests {
  text-align: center;
  padding: 48px;
  background: #f8f9fa;
  border-radius: 16px;
  color: #636e72;
}

.no-requests i {
  font-size: 48px;
  margin-bottom: 16px;
}

.requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.request-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease;
}

.request-header {
  padding: 15px 20px;
  background: #666666;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-header h3 {
  margin: 0;
  font-size: 20px;
}

.count-badge {
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.request-body {
  padding: 0;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
}

.info-table th,
.info-table td {
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
  text-align: left;
}

.info-table th {
  width: 35%;
  color: #2d3436;
  font-weight: 600;
  background: #f8f9fa;
}

.info-table td {
  color: #636e72;
}

.info-table th i {
  margin-right: 8px;
  color: #666666;
}

.description {
  color: #2d3436;
  line-height: 1.6;
  margin-bottom: 16px;
}

.image-gallery {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.reference-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.reference-image:hover {
  transform: scale(1.05);
}

.status-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.quote-status {
  margin-bottom: 16px;
}

.status-badge {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
}

.status-badge.pending {
  background: #e0e0e0;
  color: #666666;
}

.status-badge.accepted {
  background: #cccccc;
  color: #444444;
}

.status-badge.rejected {
  background: #999999;
  color: #333333;
}

.quote-button {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 0 0 8px 8px;
  background: #666666;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.quote-button:hover:not(.disabled) {
  background: #555555;
}

.quote-button.disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 600px;
  overflow: hidden;
}

.modal-header {
  padding: 18px 24px;
  background: #666666;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 20px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.filter-form,
.quote-form {
  padding: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: #2d3436;
  font-weight: 600;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 90%;
  padding: 12px;
  border: 2px solid #dfe6e9;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #666666;
}

.button-group {
  display: flex;
  gap: 16px;
}

.submit-button,
.cancel-button {
  flex: 1;
  padding: 16px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.submit-button {
  background: #444444;
  color: white;
}

.cancel-button {
  background: #aaaaaa;
  color: white;
}
</style>
