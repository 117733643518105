// 타투 견적 요청하는 설문

<template>
  <div class="tattoo-request">
    <h2 class="title">타투 견적 요청</h2>

    <!-- 진행 상태 표시 -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: `${(currentStep / totalSteps) * 100}%` }"></div>
    </div>

    <!-- 스텝별 질문 폼 -->
    <div class="step-container">
      <!-- 장르 선택 스텝 -->
      <div v-if="currentStep === 1" class="step">
        <h3>어떤 스타일의 타투를 원하시나요? (최대 1개)</h3>
        <div class="genre-buttons">
          <button
            v-for="genre in genres"
            :key="genre"
            type="button"
            :class="['genre-button', { active: selectedGenres.includes(genre) }]"
            @click="toggleGenre(genre)"
            :disabled="selectedGenres.length >= 1 && !selectedGenres.includes(genre)"
          >
            {{ genre }}
          </button>
        </div>
      </div>

      <!-- 사이즈 선택 스텝 -->
      <div v-if="currentStep === 2" class="step">
        <h3>타투 사이즈를 선택해주세요</h3>
        <div class="size-buttons">
          <button
            v-for="size in ['신용카드크기', '주먹크기', '손바닥크기', '반팔크기', '그이상']"
            :key="size"
            type="button"
            :class="['size-button', { active: form.size === size }]"
            @click="selectSize(size)"
          >
            {{ size }}
          </button>
        </div>
      </div>

      <!-- 신체 부위 선택 스텝 -->
      <div v-if="currentStep === 3" class="step">
        <h3>어느 부위에 타투를 하고 싶으신가요?</h3>
        <div class="body-map">
          <div class="body-tabs">
            <button 
              :class="['tab-button', { active: activeTab === 'front' }]"
              @click="activeTab = 'front'"
            >
              앞면
            </button>
            <button 
              :class="['tab-button', { active: activeTab === 'back' }]"
              @click="activeTab = 'back'"
            >
              뒷면
            </button>
          </div>
          <div class="body-image">
            <!-- 앞면 -->
            <div v-show="activeTab === 'front'" class="body-front">
              <svg viewBox="0 0 226 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('얼굴')" :data-selected="selectedBodyParts.includes('얼굴')"
                d="M137.63,93.19c7.08-5.27,7.81-20.71,7.81-20.71,0,0,.55,2.72,3.27.91,2.72-1.81,3.64-20.16,2.91-21.8s-3.82-1.09-3.82-1.09c0,0,4.35-23.98-3.09-36.33C137.26,1.81,119.64,0,112.73,0s-24.52,1.81-31.97,14.17c-7.44,12.36-3.09,36.33-3.09,36.33,0,0-3.09-.55-3.82,1.09-.73,1.63.18,19.98,2.91,21.8,2.72,1.81,3.27-.91,3.27-.91,0,0,.73,15.45,7.81,20.71,0,0,1.4,17.65-.72,25.51h51.22c-2.12-7.87-.72-25.51-.72-25.51h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('쇄골')" :data-selected="selectedBodyParts.includes('쇄골')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 팔목')" :data-selected="selectedBodyParts.includes('오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 손')" :data-selected="selectedBodyParts.includes('오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽  팔목')" :data-selected="selectedBodyParts.includes('왼쪽  팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 손')" :data-selected="selectedBodyParts.includes('왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('가슴')" :data-selected="selectedBodyParts.includes('가슴')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('배')" :data-selected="selectedBodyParts.includes('배')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 종아리')" :data-selected="selectedBodyParts.includes('왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('왼쪽 발')" :data-selected="selectedBodyParts.includes('왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 종아리')" :data-selected="selectedBodyParts.includes('오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('오른쪽 발')" :data-selected="selectedBodyParts.includes('오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
            <!-- 뒷면 -->
            <div v-show="activeTab === 'back'" class="body-back">
              <svg viewBox="-85 0 395 790" xmlns="http://www.w3.org/2000/svg">
                <path @click="selectBodyPart('(뒤)어깨')" :data-selected="selectedBodyParts.includes('(뒤)어깨')"
                d="M169.24,135.15s-26.16-8.54-29.79-13.8c-.44-.64-.81-1.55-1.09-2.64h-51.22c-.3,1.09-.65,2.01-1.09,2.64-3.64,5.27-29.79,13.8-29.79,13.8l56.49,13.7,56.49-13.7h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔뚝')"
                d="M221.92,265.59c1.46-8.17,3.09-49.6-1.45-60.49-4.54-10.9.91-31.06-11.26-50.86-12.17-19.8-39.97-19.07-39.97-19.07l6.36,87.74c2.72,3.64,5.63,18.34,5.81,29.43.18,11.08,3.64,26.53,6.54,33.07l37.06,5.98c-2.36-5.27-4.54-17.62-3.09-25.8h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 팔목')"
                d="M225.01,291.37l-37.06-5.98c2.91,6.54-2.54,40.15.36,57.41,2.91,17.26,11.81,48.5,11.81,54.5l21.07-2.54c-1.09-11.81,6.18-98.1,3.82-103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 손')"
                d="M200.13,397.29c0,6-3.27,7.44-6.18,13.07-2.91,5.63.73,18.17.73,18.17-2.54,16.89-.55,16.89,2.54,16.71,3.09-.18,7.44-14.53,7.44-14.53l4.54-2.18s3.64,4,.91,10.17c-2.72,6.18-18.52,14.53-16.71,16.71s7.27-.18,7.27-.18c0,0-10.9,10.53-9.63,11.81,1.28,1.28,3.27.91,3.27.91,0,0-3.09,3.64-.55,5.27,2.54,1.63,9.81-3.64,9.81-3.64,10-1.46,19.62-25.8,21.08-28.52,1.46-2.72-2.36-34.52-3.45-46.33l-21.07,2.54h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔뚝')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔뚝')"
                d="M16.29,154.23c-12.17,19.8-6.73,39.97-11.26,50.86s-2.91,52.32-1.45,60.49c1.45,8.17-.73,20.53-3.09,25.8l37.06-5.98c2.91-6.54,6.35-21.99,6.54-33.07.18-11.08,3.09-25.8,5.81-29.43l6.36-87.74s-27.8-.72-39.97,19.07h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 팔목')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 팔목')"
                d="M4.29,394.74l21.07,2.54c0-6,8.9-37.24,11.81-54.5,2.91-17.26-2.54-50.86.36-57.41L.47,291.36c-2.36,5.27,4.9,91.56,3.82,103.37h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 손')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 손')"
                d="M.84,441.06c1.46,2.72,11.08,27.07,21.08,28.52,0,0,7.27,5.27,9.8,3.64,2.54-1.63-.55-5.27-.55-5.27,0,0,1.99.36,3.27-.91,1.28-1.27-9.63-11.81-9.63-11.81,0,0,5.45,2.36,7.27.18,1.81-2.18-13.99-10.53-16.71-16.71s.91-10.17.91-10.17l4.54,2.18s4.37,14.35,7.44,14.53c3.09.18,5.08.18,2.54-16.71,0,0,3.64-12.54.73-18.17s-6.18-7.08-6.18-13.07l-21.07-2.54c-1.09,11.81-4.9,43.6-3.45,46.33h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)윗등')" :data-selected="selectedBodyParts.includes('(뒤)윗등')"
                d="M170.75,259.77c6.05-15.8,2.13-40.51,4.86-36.88l-6.36-87.74-56.49,13.7h0l-56.49-13.7-6.36,87.74c2.72-3.64-1.2,21.08,4.86,36.88,2.69,7.03,3.36,16.38,3.01,25.61h109.99c-.34-9.24.32-18.58,3.01-25.61h-.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)아랫등')" :data-selected="selectedBodyParts.includes('(뒤)아랫등')"
                d="M112.74,418.87l2.18.93,68.82-21.61c-3.03-29.72-9.64-73.95-11.96-83.38-1.62-6.55-3.61-17.91-4.05-29.42H57.74c-.43,11.51-2.43,22.87-4.05,29.42-2.3,9.35-8.81,52.8-11.87,82.51l68.74,22.49,2.18-.93h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 허벅지')"
                d="M92.4,553.32c4.9-15.8,7.44-54.13,7.44-71.03s5.63-28.7,8.36-38.51c2.72-9.81,2.36-23.98,2.36-23.98l-68.74-22.49c-.81,7.83-1.38,14.72-1.57,19.77-.91,24.15,6,113.72,5.08,126.61-.64,9.09-3.89,20.78-5.76,39.84h44.35c-1.04-9.7,4.34-16.96,8.46-30.21h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 종아리')"
                d="M85.85,651.6c4.72-19.44,4-49.05-.55-62.12-.73-2.11-1.17-4.07-1.37-5.94h-44.35c-.79,8.01-1.33,17.32-1.33,28.29,0,37.06,6.18,40.15,11.44,55.58,3.8,11.13,7.59,35.07,9.21,50.38h26.3c-.52-1.12-.9-2.14-1-3.15-.36-3.45-3.09-43.6,1.64-63.04h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)왼쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)왼쪽 발')"
                d="M59.7,730.44s-3.2,5.27-3.33,8.36c-.13,3.09.06,6.91-1.04,10.72-1.09,3.82-12.17,30.7-8.9,34.52,3.27,3.82,22.52,4.18,29.25,3.64,0,0,1.63,3.09,8.36,1.99s5.81-24.71,3.82-37.06,3.82-15.43,1.99-23.25c-1.29-5.52-3.39-8.86-4.63-11.56h-26.3c.63,5.94.93,10.57.78,12.65h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 허벅지')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 허벅지')"
                d="M117.28,443.79c2.72,9.81,8.36,21.61,8.36,38.51s2.54,55.23,7.44,71.03c4.12,13.25,9.5,20.51,8.46,30.21h44.35c-1.87-19.06-5.12-30.75-5.76-39.84-.91-12.89,6-102.45,5.08-126.61-.18-4.86-.72-11.41-1.48-18.89l-68.82,21.61s-.36,14.17,2.36,23.98h0Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 종아리')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 종아리')"
                d="M175.78,667.4c5.27-15.45,11.44-18.52,11.44-55.58,0-10.97-.55-20.28-1.33-28.29h-44.35c-.21,1.87-.64,3.83-1.37,5.94-4.54,13.07-5.27,42.69-.55,62.12,4.72,19.44,1.99,59.58,1.64,63.04-.1,1.01-.48,2.03-1,3.15h26.3c1.62-15.31,5.41-39.26,9.21-50.38h.01Z"
                class="body-part" />

                <path @click="selectBodyPart('(뒤)오른쪽 발')" :data-selected="selectedBodyParts.includes('(뒤)오른쪽 발')"
                d="M140.26,717.79c-1.25,2.7-3.35,6.04-4.63,11.56-1.81,7.81,4,10.9,1.99,23.25-1.99,12.35-2.91,35.98,3.82,37.06,6.73,1.09,8.36-1.99,8.36-1.99,6.73.55,25.98.18,29.25-3.64s-7.81-30.7-8.9-34.52c-1.09-3.82-.91-7.63-1.04-10.72-.13-3.09-3.33-8.36-3.33-8.36-.15-2.09.15-6.71.78-12.65h-26.3,0Z"
                class="body-part" />
              </svg>
            </div>
          </div>
          <p class="selected-part">선택된 부위: {{ form.location || '선택되지 않음' }}</p>
        </div>
      </div>

      <!-- 예산 입력 스텝 -->
      <div v-if="currentStep === 4" class="step">
        <h3>예산은 어느 정도로 생각하시나요?</h3>
        <div class="budget-slider-container">
          <input 
            type="range" 
            v-model="form.budget"
            class="budget-slider"
            min="50000"
            max="3000000"
            step="50000"
            @input="validateBudget"
          />
          <div class="selected-budget">
            선택된 예산: {{ Number(form.budget).toLocaleString() }}원
          </div>
        </div>
      </div>

      <!-- 시술 지역 및 희망일시 선택 스텝 -->
      <div v-if="currentStep === 5" class="step">
        <h3>시술 받고 싶은 지역과 희망 일시를 선택해주세요</h3>
        <div class="region-selects">
          <select v-model="form.city" class="input-field" @change="form.district = ''">
            <option value="">시/도를 선택해주세요</option>
            <option value="서울">서울특별시</option>
            <option value="부산">부산광역시</option>
            <option value="대구">대구광역시</option>
            <option value="인천">인천광역시</option>
            <option value="광주">광주광역시</option>
            <option value="대전">대전광역시</option>
            <option value="울산">울산광역시</option>
            <option value="세종">세종특별자치시</option>
            <option value="경기">경기도</option>
            <option value="강원">강원도</option>
            <option value="충북">충청북도</option>
            <option value="충남">충청남도</option>
            <option value="전북">전라북도</option>
            <option value="전남">전라남도</option>
            <option value="경북">경상북도</option>
            <option value="경남">경상남도</option>
            <option value="제주">제주특별자치도</option>
          </select>

          <select v-model="form.district" class="input-field">
            <option value="">구/군을 선택해주세요</option>
            <option v-for="district in districts[form.city]" 
                    :key="district" 
                    :value="district">
              {{ district }}
            </option>
          </select>
        </div>
        
        <input 
          type="date" 
          v-model="form.preferredDate"
          class="input-field-day"
          :min="minDate"
        />
        
        <select v-model="form.preferredTime" class="input-field">
          <option value="">희망 시간을 선택해주세요</option>
          <option value="새벽(00:00 ~ 06:00)">새벽 (00:00 ~ 06:00)</option>
          <option value="아침(06:00 ~ 09:00) ">아침 (06:00 ~ 09:00)</option>
          <option value="오전(09:00 ~ 12:00)">오전 (09:00 ~ 12:00)</option>
          <option value="이른 오후(12:00 ~ 15:00)">이른 오후 (12:00 ~ 15:00)</option>
          <option value="늦은 오후(15:00 ~ 18:00)">늦은 오후 (15:00 ~ 18:00)</option>
          <option value="저녁(18:00 ~ 21:00)">저녁 (18:00 ~ 21:00)</option>
          <option value="밤(21:00 ~ 24:00)">밤 (21:00 ~ 24:00)</option>
        </select>
      </div>

      <!-- 상세 설명 스텝 -->
      <div v-if="currentStep === 6" class="step">
        <h3>원하시는 타투에 대해 자세히 설명해주세요</h3>
        <textarea 
          v-model="form.description" 
          placeholder="타투에 대한 상세한 설명을 입력해주세요" 
          rows="4" 
          class="textarea-field"
        ></textarea>
      </div>

      <!-- 이미지 업로드 스텝 -->
      <div v-if="currentStep === 7" class="step">
        <h3>참고하실 이미지가 있다면 업로드해주세요</h3>
        <div class="file-upload-container">
          <input type="file" ref="fileInput" @change="handleFileUpload" multiple accept="image/*" class="file-input" />
          <div class="upload-icon">
            <i class="fas fa-cloud-upload-alt"></i>
          </div>
        </div>
        <div v-if="uploadedImages.length > 0" class="uploaded-images">
          <div v-for="(image, index) in uploadedImages" :key="index" class="image-preview-container">
            <img :src="image.url" alt="참고 이미지" class="uploaded-image" />
            <button @click="removeImage(index)" class="remove-image-btn">×</button>
          </div>
        </div>
      </div>

      <!-- 최종 확인 스텝 -->
      <div v-if="currentStep === 8" class="step">
        <h3>입력하신 내용을 확인해주세요</h3>
        <div class="summary">
          <p><strong>타투 스타일:</strong> {{ form.tattooType }}</p>
          <p><strong>사이즈:</strong> {{ form.size }}</p>
          <p><strong>신체 부위:</strong> {{ form.location }}</p>
          <p><strong>예산:</strong> {{ Number(form.budget).toLocaleString() }}원</p>
          <p><strong>시술 희망 지역:</strong> {{ form.city }} {{ form.district }}</p>
          <p><strong>시술 희망 일시:</strong> {{ form.preferredDate }} {{ form.preferredTime }}</p>
          <p><strong>상세 설명:</strong> {{ form.description }}</p>
        </div>
      </div>
    </div>

    <!-- 네비게이션 버튼 -->
    <div class="navigation-buttons">
      <button 
        v-if="currentStep === 1" 
        @click="startStep" 
        class="nav-button prev"
      >
        처음
      </button>
      <button 
        v-if="currentStep > 1" 
        @click="prevStep" 
        class="nav-button prev"
      >
        이전
      </button>
      <button 
        v-if="currentStep < totalSteps" 
        @click="nextStep" 
        class="nav-button next"
        :disabled="!canProceed"
      >
        다음
      </button>
      <button 
        v-if="currentStep === totalSteps" 
        @click="submitRequest" 
        class="nav-button submit"
      >
        의뢰 요청
      </button>
    </div>

    <!-- 성공 메시지 모달 -->
    <div v-if="isSuccessModalOpen" class="modal-overlay" @click.self="closeSuccessModal">
      <div class="modal success-modal">
        <div class="modal-icon">✓</div>
        <h3>견적 요청 완료!</h3>
        <p>타투이스트들의 견적을 곧 받아보실 수 있습니다.</p>
        <button @click="closeSuccessModal" class="modal-button success">확인</button>
      </div>
    </div>

    <!-- 에러 메시지 모달 -->
    <div v-if="isErrorModalOpen" class="modal-overlay" @click.self="closeErrorModal">
      <div class="modal error-modal">
        <div class="modal-icon">!</div>
        <h3>오류 발생</h3>
        <p>{{ errorMessage }}</p>
        <button @click="closeErrorModal" class="modal-button error">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentUserType } from '@/services/AuthService';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '@/firebaseConfig';

export default {
  name: 'TattooRequest',
  data() {
    return {
      currentStep: 1,
      totalSteps: 8,
      userType: null,
      activeTab: 'front',
      budgetError: false,
      budgetErrorMessage: '',
      genres: ['이레즈미', '치카노', '라인워크', '블랙앤그레이', '올드스쿨', '뉴스쿨', '블랙워크', '트라이벌', '컬러타투', '일러스트', '커버업', '수채화', '미니타투', '감성타투', '레터링', '동양화'],
      selectedGenres: [],
      form: {
        tattooType: '',
        size: '',
        location: '',
        budget: '50000',
        description: '',
        city: '',
        district: '',
        preferredDate: '',
        preferredTime: '',
      },
      districts: {
        서울: ['강남구', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구', 
              '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구',
              '성북구', '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'],
        부산: ['강서구', '금정구', '남구', '동구', '동래구', '부산진구', '북구', '사상구',
              '사하구', '서구', '수영구', '연제구', '영도구', '중구', '해운대구'],
        대구: ['남구', '달서구', '동구', '북구', '서구', '수성구', '중구'],
        인천: ['계양구', '남동구', '동구', '미추홀구', '부평구', '서구', '연수구', '중구'],
        광주: ['광산구', '남구', '동구', '북구', '서구'],
        대전: ['대덕구', '동구', '서구', '유성구', '중구'],
        울산: ['남구', '동구', '북구', '중구', '울주군'],
        세종: ['세종시'],
        경기: ['가평군', '고양시', '과천시', '광명시', '광주시', '구리시', '군포시', '김포시',
              '남양주시', '동두천시', '부천시', '성남시', '수원시', '시흥시', '안산시', '안성시',
              '안양시', '양주시', '양평군', '여주시', '연천군', '오산시', '용인시', '의왕시',
              '의정부시', '이천시', '파주시', '평택시', '포천시', '하남시', '화성시'],
        강원: ['강릉시', '고성군', '동해시', '삼척시', '속초시', '양구군', '양양군', '영월군',
              '원주시', '인제군', '정선군', '철원군', '춘천시', '태백시', '평창군', '홍천군',
              '화천군', '횡성군'],
        충북: ['괴산군', '단양군', '보은군', '영동군', '옥천군', '음성군', '제천시', '진천군',
              '청주시', '충주시', '증평군'],
        충남: ['계룡시', '공주시', '금산군', '논산시', '당진시', '보령시', '부여군', '서산시',
              '서천군', '아산시', '예산군', '천안시', '청양군', '태안군', '홍성군'],
        전북: ['고창군', '군산시', '김제시', '남원시', '무주군', '부안군', '순창군', '완주군',
              '익산시', '임실군', '장수군', '전주시', '정읍시', '진안군'],
        전남: ['강진군', '고흥군', '곡성군', '광양시', '구례군', '나주시', '담양군', '목포시',
              '무안군', '보성군', '순천시', '신안군', '여수시', '영광군', '영암군', '완도군',
              '장성군', '장흥군', '진도군', '함평군', '해남군', '화순군'],
        경북: ['경산시', '경주시', '고령군', '구미시', '군위군', '김천시', '문경시', '봉화군',
              '상주시', '성주군', '안동시', '영덕군', '영양군', '영주시', '영천시', '예천군',
              '울릉군', '울진군', '의성군', '청도군', '청송군', '칠곡군', '포항시'],
        경남: ['거제시', '거창군', '고성군', '김해시', '남해군', '밀양시', '사천시', '산청군',
              '양산시', '의령군', '진주시', '창녕군', '창원시', '통영시', '하동군', '함안군',
              '함양군', '합천군'],
        제주: ['서귀포시', '제주시']
      },
      uploadedImages: [],
      isSuccessModalOpen: false,
      isErrorModalOpen: false,
      errorMessage: '',
      selectedBodyParts: [], // 선택된 부위들을 저장할 배열 추가
    };
  },
  computed: {
    minDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },
    canProceed() {
      switch (this.currentStep) {
        case 1:
          return this.selectedGenres.length > 0;
        case 2:
          return this.form.size !== '';
        case 3:
          return this.form.location !== '';
        case 4:
          return this.form.budget !== '' && !this.budgetError && Number(this.form.budget) >= 50000;
        case 5:
          return this.form.city !== '' && this.form.district !== '' && this.form.preferredDate !== '' && this.form.preferredTime !== '';
        case 6:
          return this.form.description.trim() !== '';
        case 7:
          return true; // 이미지는 선택사항
        case 8:
          return this.form.tattooType !== '' && this.form.size !== '' && this.form.location !== '' && 
                 this.form.budget !== '' && this.form.city !== '' && this.form.district !== '' && 
                 this.form.preferredDate !== '' && this.form.preferredTime !== '' && 
                 this.form.description.trim() !== '';
        default:
          return true;
      }
    }
  },
  created() {
    this.checkUserType();
    this.setupAutoDelete();
  },
  methods: {
    validateBudget() {
      const budget = Number(this.form.budget);
      if (budget < 50000) {
        this.budgetError = true;
        this.budgetErrorMessage = '안전한 작업환경을 위한 최소 예산은 50,000원 입니다.';
        this.form.budget = '50000';
      } else {
        this.budgetError = false;
        this.budgetErrorMessage = '';
      }
    },
    selectBodyPart(part) {
      const index = this.selectedBodyParts.indexOf(part);
      if (index === -1) {
        // 선택되지 않은 부위라면 추가
        this.selectedBodyParts.push(part);
      } else {
        // 이미 선택된 부위라면 제거
        this.selectedBodyParts.splice(index, 1);
      }
      // form.location을 콤마로 구분된 문자열로 업데이트
      this.form.location = this.selectedBodyParts.join(', ');
    },
    nextStep() {
      if (this.canProceed && this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    startStep() {
      if (this.currentStep === 1) {
        this.$router.push('/my-tattoo-requests');
      }
    },
    toggleGenre(genre) {
      const index = this.selectedGenres.indexOf(genre);
      if (index === -1) {
        if (this.selectedGenres.length < 2) {
          this.selectedGenres.push(genre);
        }
      } else {
        this.selectedGenres.splice(index, 1);
      }
      this.form.tattooType = this.selectedGenres.join(', ');
    },
    selectSize(size) {
      this.form.size = size;
    },
    async checkUserType() {
      try {
        this.userType = await getCurrentUserType();
        if (this.userType !== 'regular') {
          alert('견적 요청은 일반 사용자만 이용할 수 있습니다.');
          this.$router.push('/');
          return;
        }
      } catch (error) {
        console.error('사용자 유형 확인 실패:', error);
        alert('사용자 인증에 문제가 발생했습니다.');
        this.$router.push('/login');
      }
    },
    async handleFileUpload(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (const file of Array.from(files)) {
          try {
            // Firebase Storage에 이미지 업로드
            const storageRef = ref(storage, `tattooRequests/${Date.now()}_${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            
            // 로컬 미리보기를 위한 URL 생성
            const localUrl = URL.createObjectURL(file);
            
            this.uploadedImages.push({
              file: file,
              url: localUrl,
              firebaseUrl: downloadURL
            });
          } catch (error) {
            console.error('이미지 업로드 실패:', error);
            this.errorMessage = '이미지 업로드에 실패했습니다.';
            this.isErrorModalOpen = true;
          }
        }
      }
    },
    removeImage(index) {
      URL.revokeObjectURL(this.uploadedImages[index].url); // 메모리 해제
      this.uploadedImages.splice(index, 1);
    },
    async submitRequest() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('사용자가 인증되지 않았습니다.');
        }

        const requestData = {
          userId: user.uid,
          tattooType: this.form.tattooType,
          size: this.form.size,
          location: this.form.location,
          budget: this.form.budget,
          description: this.form.description,
          city: this.form.city,
          district: this.form.district,
          preferredDate: this.form.preferredDate,
          preferredTime: this.form.preferredTime,
          referenceImages: this.uploadedImages.map(img => img.firebaseUrl),
          createdAt: serverTimestamp(),
          requestDate: new Date().toISOString(),
          status: 'open',
          quotes: [],
          deadline: new Date(Date.now() + 72 * 60 * 60 * 1000), // 72시간 후
        };

        await addDoc(collection(db, 'tattooRequests'), requestData);
        
        this.isSuccessModalOpen = true;
        this.resetForm();
        
      } catch (error) {
        console.error('견적 요청 글 등록 실패:', error);
        this.errorMessage = error.message || '알 수 없는 오류가 발생했습니다.';
        this.isErrorModalOpen = true;
      }
    },
    resetForm() {
      this.currentStep = 1;
      this.selectedGenres = [];
      this.form.tattooType = '';
      this.form.size = '';
      this.form.location = '';
      this.form.budget = '50000';
      this.form.description = '';
      this.form.city = '';
      this.form.district = '';
      this.form.preferredDate = '';
      this.form.preferredTime = '';
      // 업로드된 이미지의 메모리 해제
      this.uploadedImages.forEach(image => {
        URL.revokeObjectURL(image.url);
      });
      this.uploadedImages = [];
    },
    closeSuccessModal() {
      this.isSuccessModalOpen = false;
      this.$router.push('/my-tattoo-requests');
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
    },
    setupAutoDelete() {
      // 1시간마다 체크
      setInterval(async () => {
        const now = new Date();
        const requestsRef = collection(db, 'tattooRequests');
        
        // 마감시간이 지난 요청들 조회
        const q = query(requestsRef, where('deadline', '<=', now));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          const deadline = data.deadline.toDate();
          
          // 마감시간으로부터 48시간이 지났는지 확인
          if (now - deadline >= 48 * 60 * 60 * 1000) {
            // 48시간이 지났다면 문서 삭제
            await deleteDoc(doc.ref);
          }
        });
      }, 60 * 60 * 1000); // 1시간마다 실행
    },
  },
};
</script>

<style scoped>
.tattoo-request {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
}

.title {
  font-size: 2.4rem;
  color: #111111;
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 800;
  letter-spacing: -0.03em;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: #f1f3f5;
  border-radius: 100px;
  margin-bottom: 3rem;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.step-container {
  min-height: 320px;
  margin-bottom: 2.5rem;
}

.step {
  animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.budget-slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 100px;
  background: #f1f3f5;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  margin-bottom: 20px;
}

.budget-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(135deg, #2d3436 0%, #636e72 100%);
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
}

.budget-slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(135deg, #2d3436 0%, #636e72 100%);
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  border: none;
}

.budget-slider:hover {
  opacity: 1;
}

.budget-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.budget-slider::-moz-range-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.step h3 {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: #111111;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 3rem;
}

.nav-button {
  padding: 1.2rem 2.5rem;
  border-radius: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  font-size: 1rem;
}

.nav-button.prev {
  background: #f1f3f5;
  color: #111111;
}

.nav-button.next {
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  color: white;
}

.nav-button.submit {
  background: linear-gradient(90deg, #2f9e44 0%, #37b24d 100%);
  color: white;
  width: 50%;
}

.nav-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.nav-button:not(:disabled):hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.summary {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 16px;
  margin-top: 1.5rem;
  border: 1px solid #e9ecef;
}

.summary p {
  margin-bottom: 0.8rem;
  font-size: 1.05rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.genre-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.genre-button {
  padding: 1.2rem 1rem;
  border: 2px solid #e9ecef;
  border-radius: 14px;
  background: white;
  color: #495057;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.genre-button:hover {
  border-color: #2d3436;
  color: #2d3436;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.genre-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.genre-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f8f9fa;
}

.size-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.size-button {
  padding: 2rem 1.5rem;
  border: 2px solid #e9ecef;
  border-radius: 16px;
  background: white;
  color: #495057;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.size-button:hover {
  border-color: #2d3436;
  color: #2d3436;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.size-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.body-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 300px;
}

.tab-button {
  flex: 1;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  background: white;
  color: #495057;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tab-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.body-image {
  position: relative;
  width: 200px;
  height: 400px;
}

.body-front, .body-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  border-radius: 16px;
  transition: opacity 0.3s ease;
}

.body-front svg {
  width: 100%;
  height: 100%;
}

.body-part {
  fill: #e0e0e0;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.body-part:hover {
  fill: #a0a0a0;
}

/* 선택된 부위의 스타일 */
.body-part[data-selected="true"] {
  fill: #2d3436;
}

.selected-part {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3436;
  white-space: pre-wrap;
}

.input-field {
  width: 100%;
  padding: 1rem 1.2rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1.05rem;
  margin-top: 1.2rem;
  transition: border-color 0.2s ease;
}

.input-field:focus {
  border-color: #2d3436;
  outline: none;
}

.input-field-day {
  width: calc(100% - 40px);
  padding: 1rem 1.2rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1.05rem;
  margin-top: 1.2rem;
  transition: border-color 0.2s ease;
}

.textarea-field {
  width: calc(100% - 40px);
  padding: 1rem 1.2rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1.05rem;
  margin-top: 1.2rem;
  resize: vertical;
  min-height: 140px;
  transition: border-color 0.2s ease;
}

.textarea-field:focus {
  border-color: #2d3436;
  outline: none;
}

.file-upload-container {
  border: 2px dashed #e9ecef;
  border-radius: 16px;
  padding: 2.5rem;
  text-align: center;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.file-upload-container:hover {
  border-color: #2d3436;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  text-align: center;
  max-width: 90%;
  width: 420px;
  animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.uploaded-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 1.2rem;
  margin-top: 1.5rem;
}

.modal-button {
  padding: 0.5rem 2rem;
  color: white;
  background-color: #444444;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
}
</style>
