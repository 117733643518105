import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      userType: null,
      userData: {},
    };
  },
  mutations: {
    SET_USER_TYPE(state, type) {
      state.userType = type;
    },
    SET_USER_DATA(state, data) {
      state.userData = data;
    },
  },
  actions: {
    setUserType({ commit }, type) {
      commit('SET_USER_TYPE', type);
    },
    setUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
  },
  getters: {
    isUser: (state) => state.userType === 'user',
    isArtist: (state) => state.userType === 'artist',
    getUserData: (state) => state.userData,
  },
});

export default store;
