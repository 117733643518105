<template>
  <div class="signup-page">
    <h2 class="title">회원가입</h2>

    <!-- 진행 상태 표시 -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: `${(currentStep / totalSteps) * 100}%` }"></div>
    </div>

    <!-- 스텝별 회원가입 폼 -->
    <div class="step-container">
      <!-- 스텝 1: 사용자 유형 선택 -->
      <div v-if="currentStep === 1" class="step">
        <h3>회원 유형을 선택해주세요</h3>
        <div class="user-type-selection">
          <button
            v-for="type in userTypes"
            :key="type.id"
            :class="['type-button', { active: selectedUserType === type.value }]"
            @click="selectUserType(type.value)"
          >
            <i :class="type.icon"></i>
            {{ type.name }}
          </button>
        </div>
      </div>

      <!-- 스텝 2: 이메일/비밀번호 입력 -->
      <div v-if="currentStep === 2" class="step">
        <h3>로그인 정보를 입력해주세요</h3>
        <div class="form-group">
          <label for="email">이메일</label>
          <input type="email" id="email" v-model="email" class="input-field" required />
        </div>
        <div class="form-group">
          <label for="password">비밀번호</label>
          <input type="password" id="password" v-model="password" class="input-field" required />
        </div>
        <div class="form-group">
          <label for="confirmPassword">비밀번호 확인</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" class="input-field" required />
        </div>
      </div>

      <!-- 스텝 3: 프로필 정보 입력 -->
      <div v-if="currentStep === 3" class="step">
        <h3>프로필 정보를 입력해주세요</h3>
        <div class="form-group">
          <label for="nickname">닉네임</label>
          <input type="text" id="nickname" v-model="nickname" class="input-field" required />
        </div>
        <div v-if="selectedUserType === 'OKartist'" class="form-group">
          <label for="representativeImage">대표 이미지</label>
          <div class="file-upload-container" @click="$refs.fileInput.click()">
            <input 
              type="file" 
              ref="fileInput" 
              @change="handleImageUpload" 
              accept="image/*" 
              class="file-input" 
              required 
              style="display: none"
            />
            <div class="upload-icon">
              <i class="fas fa-cloud-upload-alt"></i>
              <p>이미지를 선택하거나 드래그하세요</p>
            </div>
          </div>
          <div v-if="representativeImage" class="image-preview">
            <img :src="previewImage" alt="프로필 이미지 미리보기" />
          </div>
        </div>
      </div>

      <!-- 스텝 4: 이용약관 동의 -->
      <div v-if="currentStep === 4" class="step">
        <h3>이용약관에 동의해주세요</h3>
        <div class="terms-container">
          <div class="terms-content">
            <h3>제1조 (목적)</h3>
            <p>본 약관은 TATTOO MAP(이하 "회사")가 제공하는 모든 서비스(이하 "서비스")의 이용 조건 및 절차, 이용자와 회사 간의 권리, 의무 및 책임 사항 등을 규정하는 것을 목적으로 합니다.</p>
            
            <h3>제2조 (정의)</h3>
            <p>1. "서비스"란 회사가 제공하는 웹사이트, 모바일 애플리케이션 및 기타 온라인 플랫폼을 통해 제공되는 모든 서비스를 의미합니다.</p>
            <p>2. "이용자"란 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.</p>
            <p>3. "회원"이란 회사의 서비스에 가입한 자로서, 지속적으로 회사가 제공하는 서비스를 이용할 수 있는 자를 의미합니다.</p>
            <p>4. "비회원"이란 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 의미합니다.</p>

            <h3>제3조 (약관의 효력 및 변경)</h3>
            <p>1. 본 약관은 서비스를 이용하고자 하는 모든 이용자에게 그 효력이 발생합니다.</p>
            <p>2. 회사는 관련 법령을 위반하지 않는 범위에서 본 약관을 개정할 수 있으며, 변경된 약관은 공지사항을 통해 사전 공지합니다.</p>
            <p>3. 변경된 약관에 대해 이용자가 동의하지 않을 경우, 이용자는 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.</p>

            <h3>제4조 (회원 가입 및 이용계약의 체결)</h3>
            <p>1. 회원 가입은 이용자가 약관에 동의하고, 회사가 정한 절차에 따라 이용 신청을 완료함으로써 체결됩니다.</p>
            <p>2. 회사는 다음에 해당하는 경우, 회원 가입 신청을 거부하거나 취소할 수 있습니다.</p>
            <p> ● 허위 정보를 기재하거나 타인의 정보를 도용한 경우</p>
            <p> ● 회사의 서비스 운영을 방해하거나 중단시키는 행위를 한 경우</p>
            <p> ● 기타 회사가 합리적으로 판단했을 때 승인하기 어려운 경우</p>

            <h3>제5조 (서비스 이용)</h3>
            <p>1. 회사는 이용자에게 위치 기반 아티스트 탐색, 포트폴리오 열람, 상담 예약 기능 등을 포함한 서비스를 제공합니다.</p>
            <p>2. 서비스 이용은 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 다만, 회사의 사정이나 기술적 문제가 발생할 경우 서비스가 일시 중단될 수 있습니다.</p>

            <h3>제6조 (회원의 의무)</h3>
            <p>회원은 서비스를 이용함에 있어 다음 행위를 해서는 안 됩니다.</p>
            <p> ● 허위 정보 등록</p>
            <p> ● 타인의 개인정보 도용</p>
            <p> ● 회사의 서비스를 이용하여 영리 목적의 활동을 하는 행위</p>
            <p> ● 기타 법령 및 본 약관을 위반하는 행위</p>

            <h3>제7조 (회사의 의무)</h3>
            <p>1. 회사는 관련 법령 및 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공합니다.</p>
            <p>2. 회사는 이용자로부터 제기된 의견이나 불만 사항이 정당하다고 판단될 경우 이를 신속히 처리합니다.</p>

            <h3>제8조 (개인정보의 보호)</h3>
            <p>1. 회사는 이용자의 개인정보를 보호하기 위해 관련 법령 및 개인정보 처리방침에 따라 적절한 보호 조치를 취합니다.</p>
            <p>2. 이용자는 언제든지 자신의 개인정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리방침은 서비스 내에서 확인할 수 있습니다.</p>

            <h3>제9조 (서비스의 중단)</h3>
            <p>1. 회사는 천재지변, 시스템 오류 등 불가항력적인 사유로 인해 서비스 제공이 어려울 경우, 서비스를 일시 중단할 수 있습니다.</p>
            <p>2. 서비스 중단 시 회사는 사전 또는 사후에 공지사항을 통해 이를 알립니다.</p>

            <h3>제10조 (책임 제한)</h3>
            <p>1. 회사는 이용자가 서비스를 통해 얻은 정보 및 자료에 대한 신뢰도, 정확성에 대해 책임을 지지 않습니다.</p>
            <p>2. 회사는 이용자 간 또는 이용자와 제3자 간에 발생한 분쟁에 대해 개입하거나 책임을 지지 않습니다.</p>

            <h3>제11조 (관할 법원)</h3>
            <p>본 약관과 관련된 분쟁은 대한민국 법률에 따라 처리되며, 분쟁이 발생할 경우 관할 법원은 회사의 본사 소재지를 관할하는 법원으로 합니다.</p>
            <br>

            <p>본 약관은 2024년 12월 5일부터 시행됩니다.</p>
          </div>
          <div class="form-group terms">
            <input type="checkbox" id="agreeTerms" v-model="agreeTerms" required />
            <label for="agreeTerms">
              이용약관에 동의합니다
            </label>
          </div>
        </div>
      </div>

      <!-- 스텝 5: 최종 확인 -->
      <div v-if="currentStep === 5" class="step">
        <h3>입력하신 정보를 확인해주세요</h3>
        <div class="summary">
          <p><strong>회원 유형:</strong> {{ selectedUserType === 'regular' ? '일반 사용자' : '타투이스트' }}</p>
          <p><strong>이메일:</strong> {{ email }}</p>
          <p><strong>닉네임:</strong> {{ nickname }}</p>
          <p v-if="selectedUserType === 'OKartist'"><strong>대표 이미지:</strong> 업로드됨</p>
        </div>
      </div>
    </div>

    <!-- 네비게이션 버튼 -->
    <div class="navigation-buttons">
      <button 
        v-if="currentStep > 1" 
        @click="prevStep" 
        class="nav-button prev"
      >
        이전
      </button>
      <button 
        v-if="currentStep < totalSteps" 
        @click="nextStep" 
        class="nav-button next"
        :disabled="!canProceed"
      >
        다음
      </button>
      <button 
        v-if="currentStep === totalSteps" 
        @click="handleSignup" 
        class="nav-button submit"
        :disabled="!isFormValid"
      >
        가입하기
      </button>
    </div>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../firebaseConfig'; // Firestore 인스턴스 가져오기
import { doc, setDoc } from 'firebase/firestore';

export default {
  name: 'SignupPage',
  data() {
    return {
      selectedUserType: null,
      userTypes: [
        { id: 1, name: '일반 사용자', value: 'regular', icon: 'fas fa-user' },
        { id: 2, name: '타투이스트', value: 'OKartist', icon: 'fas fa-paint-brush' }, // 변경된 값
      ],
      email: '',
      password: '',
      confirmPassword: '',
      nickname: '',
      representativeImage: null,
      agreeTerms: false,
      currentStep: 1,
      totalSteps: 5,
      previewImage: null
    }
  },
  computed: {
    isFormValid() {
      if (this.selectedUserType === 'regular') {
        return this.email && 
               this.password && 
               this.confirmPassword && 
               this.nickname && 
               this.agreeTerms &&
               this.password === this.confirmPassword;
      } else if (this.selectedUserType === 'OKartist') { // 변경된 값
        return this.email && 
               this.password && 
               this.confirmPassword && 
               this.nickname && 
               this.representativeImage &&
               this.agreeTerms &&
               this.password === this.confirmPassword;
      }
      return false;
    },
    canProceed() {
      switch (this.currentStep) {
        case 1:
          return this.selectedUserType !== null;
        case 2:
          return this.email && 
                 this.password && 
                 this.confirmPassword && 
                 this.password === this.confirmPassword;
        case 3:
          if (this.selectedUserType === 'regular') {
            return this.nickname;
          }
          return this.nickname && this.representativeImage;
        case 4:
          return this.agreeTerms;
        case 5:
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    selectUserType(type) {
      this.selectedUserType = type;
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.nickname = '';
      this.representativeImage = null;
      this.agreeTerms = false;
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.representativeImage = file;
        this.previewImage = URL.createObjectURL(file);
      }
    },
    async handleSignup() {
      if (this.password !== this.confirmPassword) {
        alert('비밀번호가 일치하지 않습니다.');
        return;
      }
      if (!this.agreeTerms) {
        alert('이용약관에 동의해야 합니다.');
        return;
      }

      try {
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // 이메일 인증 메일 보내기
        await sendEmailVerification(user);
        alert('이메일 인증 메일이 발송되었습니다. 이메일을 확인하고 인증을 완료하세요.');

        let profileImageUrl = null;

        // 타투이스트인 경우 이미지 업로드
        if (this.selectedUserType === 'OKartist' && this.representativeImage) { // 변경된 값
          const storage = getStorage();
          const imgRef = storageRef(storage, `profile-images/${user.uid}/${Date.now()}_${this.representativeImage.name}`);
          await uploadBytes(imgRef, this.representativeImage);
          profileImageUrl = await getDownloadURL(imgRef);
        }

        // Firestore에 사용자 정보 저장
        if (this.selectedUserType === 'regular') {
          await setDoc(doc(db, "users", user.uid), {
            userType: this.selectedUserType,
            email: this.email,
            nickname: this.nickname,
            profileImageUrl: profileImageUrl
          });
        } else if (this.selectedUserType === 'OKartist') { // 변경된 값
          await setDoc(doc(db, "artists", user.uid), {
            userType: this.selectedUserType,
            email: this.email,
            nickname: this.nickname,
            profileImageUrl: profileImageUrl,
            representativeImageUrl: profileImageUrl
          });
        }

        // 회원가입 후 로그아웃
        await signOut(auth);
        
        this.$router.push('/login'); // 로그인 페이지로 리다이렉트
      } catch (error) {
        console.error('회원가입 오류:', error);
        alert('회원가입 중 오류가 발생했습니다.');
      }
    },
    nextStep() {
      if (this.canProceed && this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    }
  }
}
</script>

<style scoped>
.signup-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
}

.title {
  font-size: 2.4rem;
  color: #111111;
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 800;
  letter-spacing: -0.03em;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: #f1f3f5;
  border-radius: 100px;
  margin-bottom: 3rem;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.step-container {
  min-height: 320px;
  margin-bottom: 2.5rem;
}

.step {
  animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.step h3 {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: #111111;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.user-type-selection {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 1.5rem;
}

.type-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 16px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #495057;
  cursor: pointer;
  transition: all 0.2s ease;
}

.type-button i {
  font-size: 1.4rem;
}

.type-button:hover {
  border-color: #2d3436;
  transform: translateY(-2px);
}

.type-button.active {
  background: #2d3436;
  color: white;
  border-color: #2d3436;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  font-weight: 600;
  color: #495057;
}

.input-field {
  width: 90%;
  padding: 1rem 1.2rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1.05rem;
  transition: border-color 0.2s ease;
}

.input-field:focus {
  border-color: #2d3436;
  outline: none;
}

.file-upload-container {
  border: 2px dashed #e9ecef;
  border-radius: 16px;
  padding: 2.5rem;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.file-upload-container:hover {
  border-color: #2d3436;
}

.upload-icon {
  font-size: 2rem;
  color: #adb5bd;
  margin-bottom: 1rem;
}

.upload-icon p {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.image-preview {
  margin-top: 1.5rem;
  text-align: center;
}

.image-preview img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 12px;
  object-fit: cover;
}

.terms-container {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.terms-content {
  max-height: 200px;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 1.5rem;
  background: white;
  border-radius: 12px;
}

.form-group.terms {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.form-group.terms input[type="checkbox"] {
  margin-right: 0.8rem;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 3rem;
}

.nav-button {
  padding: 1.2rem 2.5rem;
  border-radius: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  font-size: 1rem;
}

.nav-button.prev {
  background: #f1f3f5;
  color: #111111;
}

.nav-button.next {
  background: linear-gradient(90deg, #2d3436 0%, #636e72 100%);
  color: white;
}

.nav-button.submit {
  background: linear-gradient(90deg, #2f9e44 0%, #37b24d 100%);
  color: white;
  width: 50%;
}

.nav-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.nav-button:not(:disabled):hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.summary {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 16px;
  margin-top: 1.5rem;
  border: 1px solid #e9ecef;
}

.summary p {
  margin-bottom: 0.8rem;
  font-size: 1.05rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .signup-page {
    margin: 1rem;
    padding: 1.5rem;
  }

  .title {
    font-size: 2rem;
  }

  .step h3 {
    font-size: 1.4rem;
  }

  .nav-button {
    padding: 1rem 2rem;
    font-size: 0.95rem;
  }
}
</style> 