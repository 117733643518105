<template>
  <div class="google-map-container">
    <div id="map" class="map"></div>
      
      <div class="button-container">
        <button class="current-location-btn" @click="getCurrentLocation" aria-label="현재 위치">
          <i class="material-icons">my_location</i>
        </button>
        <button class="save-location-btn" @click="saveLocation">위치 저장</button>
        <button class="cancel-btn" @click="cancelLocation">취소</button>
      </div>
  </div>
</template>

<script>
/* global google */
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '@/firebaseConfig';

export default {
  name: 'GoogleMap',
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    initialPosition: {
      type: Object,
      default: () => ({
        lat: 37.5665, // 기본 서울 위치
        lng: 126.9780,
      }),
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      selectedCoordinates: null,
      selectedAddress: '',
      tempSelectedCoordinates: null, // 임시 선택 위치
      tempSelectedAddress: '',        // 임시 선택 주소
      touchStartTime: 0,
      touchStartPosition: null,
      isTouchMoved: false
    };
  },
  mounted() {
    this.loadGoogleMaps().then(() => {
      this.initializeMap();
    }).catch(error => {
      console.error('Google Maps API 로드 실패:', error);
      alert('Google Maps API를 불러오는 데 실패했습니다.');
    });
  },
  methods: {
    loadGoogleMaps() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
          return;
        }

        const existingScript = document.getElementById('google-maps-script');
        if (existingScript) {
          existingScript.remove();
        }

        window.initMap = () => {
          resolve();
        };

        const script = document.createElement('script');
        script.id = 'google-maps-script';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onerror = (error) => {
          reject(error);
        };
        document.head.appendChild(script);
      });
    },
    async initializeMap() {
      const auth = getAuth();
      const user = auth.currentUser;

      const defaultLocation = {
        lat: 37.5666805,
        lng: 126.9784147
      };

      let initialLocation = defaultLocation;
      let userDoc = null; // userDoc을 초기화

      if (user) {
        userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().location?.coordinates) {
          initialLocation = {
            lat: userDoc.data().location.coordinates.lat,
            lng: userDoc.data().location.coordinates.lng
          };
          this.selectedCoordinates = initialLocation;
          this.selectedAddress = userDoc.data().location.address;
        }
      }

      this.map = new google.maps.Map(document.getElementById('map'), {
        center: initialLocation,
        zoom: 15,
        gestureHandling: 'greedy', // 한 손가락으로 지도 조작 가능
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER // 줌 컨트롤 우측 중앙에 배치
        },
        streetViewControl: false, // 스트리트뷰 컨트롤 제거
        mapTypeControl: false, // 지도 타입 컨트롤 제거
        fullscreenControl: false // 전체화면 컨트롤 제거
      });
      
      this.marker = new google.maps.Marker({
        position: initialLocation,
        map: this.map,
        draggable: true,
      });

      this.selectedCoordinates = initialLocation;
      this.selectedAddress = userDoc && userDoc.exists() ? userDoc.data().location.address : '';

      // 마커 드래그 종료 시 임시 변수에 위치 저장
      this.marker.addListener('dragend', () => {
        const newPosition = this.marker.getPosition();
        this.tempSelectedCoordinates = {
          lat: newPosition.lat(),
          lng: newPosition.lng(),
        };
        this.reverseGeocode(this.tempSelectedCoordinates);
      });

      // 터치 이벤트 핸들러 추가
      const mapElement = document.getElementById('map');
      mapElement.addEventListener('touchstart', this.handleTouchStart);
      mapElement.addEventListener('touchmove', this.handleTouchMove);
      mapElement.addEventListener('touchend', this.handleTouchEnd);
    },
    handleTouchStart(event) {
      this.touchStartTime = Date.now();
      this.touchStartPosition = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      };
      this.isTouchMoved = false;
    },
    handleTouchMove(event) {
      if (this.touchStartPosition) {
        const deltaX = Math.abs(event.touches[0].clientX - this.touchStartPosition.x);
        const deltaY = Math.abs(event.touches[0].clientY - this.touchStartPosition.y);
        if (deltaX > 10 || deltaY > 10) {
          this.isTouchMoved = true;
        }
      }
    },
    handleTouchEnd(event) {
      const touchDuration = Date.now() - this.touchStartTime;
      if (!this.isTouchMoved && touchDuration < 500) {
        // 짧은 탭으로 간주하고 마커 이동
        const element = document.getElementById('map');
        const rect = element.getBoundingClientRect();
        const point = new google.maps.Point(
          event.changedTouches[0].clientX - rect.left,
          event.changedTouches[0].clientY - rect.top
        );
        const latLng = this.pixelToLatLng(point);
        this.marker.setPosition(latLng);
        this.tempSelectedCoordinates = {
          lat: latLng.lat(),
          lng: latLng.lng()
        };
        this.reverseGeocode(this.tempSelectedCoordinates);
      }
    },
    pixelToLatLng(pixel) {
      const projection = this.map.getProjection();
      const bounds = this.map.getBounds();
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = Math.pow(2, this.map.getZoom());
      const worldPoint = new google.maps.Point(
        pixel.x / scale + bottomLeft.x,
        pixel.y / scale + topRight.y
      );
      return projection.fromPointToLatLng(worldPoint);
    },
    reverseGeocode(coords) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: coords }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.tempSelectedAddress = results[0].formatted_address;
          } else {
            alert('결과를 찾을 수 없습니다.');
          }
        } else {
          alert('Geocoder 실패: ' + status);
        }
      });
    },
    // 위치 저장 메소드
    async saveLocation() {
      if (this.tempSelectedCoordinates && this.tempSelectedAddress) {
        try {
          // 로그인 상태와 관계없이 위치 데이터를 로컬 스토리지에 저장
          localStorage.setItem('tempLocationCoordinates', JSON.stringify(this.tempSelectedCoordinates));
          localStorage.setItem('tempLocationAddress', this.tempSelectedAddress);
          alert('위치가 성공적으로 저장되었습니다.');

          // 실제 위치 업데이트
          this.selectedCoordinates = { ...this.tempSelectedCoordinates };
          this.selectedAddress = this.tempSelectedAddress;
          this.userLocation = this.selectedCoordinates;

          // 임시 변수 초기화
          this.tempSelectedCoordinates = null;
          this.tempSelectedAddress = '';

          // 위치 저장 후 추가 작업 (예: 부모 컴포넌트에 알리기)
          this.$emit('location-selected', {
            coordinates: this.selectedCoordinates,
            address: this.selectedAddress,
          }
    );

      this.$emit('close');
    } catch (error) {
      console.error('위치 저장 실패:', error);
      alert('위치 저장에 실패했습니다.');
    }
  } else {
    alert('유효한 위치를 선택해주세요.');
  }
},
    // 위치 취소 메소드
    cancelLocation() {
      if (this.selectedCoordinates) {
        // 마커를 기존 위치로 되돌림
        this.marker.setPosition(this.selectedCoordinates);
        this.tempSelectedCoordinates = null;
        this.tempSelectedAddress = '';
      }
      this.$emit('close');
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        // 위치 정보 요청 (이 시점에 브라우저가 권한 요청 팝업을 표시)
        navigator.geolocation.getCurrentPosition(
          // 성공 콜백 (권한이 허용되고 위치를 가져왔을 때)
          (position) => {
            const currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            
            // 지도와 마커 위치 업데이트
            this.marker.setPosition(currentLocation);
            this.map.setCenter(currentLocation);
            this.tempSelectedCoordinates = currentLocation;
            this.reverseGeocode(currentLocation);
          },
          // 실패 콜백 (권한이 거부되거나 오류 발생 시)
          (error) => {
            console.error('위치 정보 가져오기 실패:', error);
            if (error.code === 1) { // PERMISSION_DENIED
              alert('위치 정보 접근을 허용해주세요.');
            } else {
              alert('위치 정보를 가져올 수 없습니다.');
            }
          },
          // 옵션
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        );
      } else {
        alert('이 브라우저에서는 위치 정보를 지원하지 않습니다.');
      }
    }
  },
};
</script>

<style scoped>
.google-map-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 90%;
  position: fixed;
  top: 10%;
  left: 5%;
  background-color: #fff;
  border-radius: 15px;
}

.map {
  flex: 1;
  margin: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.current-location-btn {
  align-self: center;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 14px;
  z-index: 1;
}

.current-location-btn:hover {
  background-color: #f5f5f5;
}
.address-display {
  padding: 20px;
  background-color: white;
  font-size: 15px;
  color: #333;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* 버튼 사이 간격 */
  padding: 0 20px;
  margin-bottom: 20px;
}

.save-location-btn {
  margin: 10px 0;
  padding: 12px 12px;
  background-color: #2d3436;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 40%; /* 너비 조정 */
  max-width: 150px;
}

.save-location-btn:hover {
  background-color: #636e72;
}

.cancel-btn {
  margin: 10px 0;
  padding: 12px 12px;
  background-color: #e74c3c; /* 취소 버튼 색상 */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 40%; /* 너비 조정 */
  max-width: 150px;
}

.cancel-btn:hover {
  background-color: #c0392b;
}
</style>