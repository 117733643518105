import { createRouter, createWebHistory } from 'vue-router';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import HomePage from '../components/HomePage.vue';
import ProfilePage from '../components/ProfilePage.vue';
import FindArtist from '../components/FindArtist.vue';
import AboutPage from '../components/AboutPage.vue';
import SignupPage from '../views/SignupPage.vue'
import LoginPage from '../views/LoginPage.vue'
import MyPage from '../components/MyPage.vue'
import UserPage from '../components/UserPage.vue'
import GoogleMap from '../components/googlemap.vue'
import ChatRoom from '@/components/ChatRoom.vue'
import ChatList from '@/components/ChatList.vue'
import AdminDashboard from '@/components/AdminDashboard.vue'
import PostList from '@/components/postList.vue'
import CreatePost from '@/components/CreatePost.vue'
import PostDetail from '@/components/PostDetail.vue'
import TattooRequest from '@/components/TattooRequest.vue'
import TattooRequestUser from '@/components/TattooRequestUser.vue'
import TattooRequestPage from '@/components/TattooRequestPage.vue'
import FindDesign from '@/components/FindDesign.vue'
import MainPage from '@/components/MainPage.vue'
import EditPost from '@/components/EditPost.vue'
import TattooChoice from '@/components/TattooChoice.vue'

const routes = [

  {
    path: '/',
    name: 'Main',
    component: MainPage,
  },
  {
    path: '/Home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: ProfilePage,
    props: true,
  },
  {
    path: '/my-page',
    name: 'MyPage',
    component: MyPage
  },
  {
    path: '/user-page',
    name: 'UserPage',
    component: UserPage
  },
  {
    path: '/find-artist',
    name: 'FindArtist',
    component: FindArtist
  },
  {
    path: '/find-design',
    name: 'FindDesign',
    component: FindDesign
  },
  {
    path: '/aboutpage',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/googlemap',
    name: 'GoogleMap',
    component: GoogleMap
  },
  {
    path: '/chats',
    name: 'ChatList',
    component: ChatList,
    meta: { requiresAuth: true }
  },
  {
    path: '/chat/:chatId',
    name: 'ChatRoom',
    component: ChatRoom,
    props: true
  },
  {
    path: '/posts',
    name: 'PostList',
    component: PostList
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: CreatePost,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-post/:id',
    name: 'EditPost',
    component: EditPost,
    props: true
  },
  {
    path: '/post/:id',
    name: 'PostDetail',
    component: PostDetail,
    props: true
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true },
    beforeEnter: async (to, from, next) => {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().role === 'admin') {
          next();
        } else {
          next('/');
        }
      } else {
        next('/');
      }
    }
  },
  {
    path: '/my-tattoo-requests',
    name: 'TattooRequestUser',
    component: TattooRequestUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/tattoo-request-page',
    name: 'TattooRequestPage',
    component: TattooRequestPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/tattoo-request',
    name: 'TattooRequest',
    component: TattooRequest,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().userType === 'regular') {
          next();
        } else {
          alert('일반 사용자만 견적 요청을 작성할 수 있습니다.');
          next('/');
        }
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/tattoo-choice',
    name: 'TattooChoice',
    component: TattooChoice
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 전역 네비게이션 가드 (선택사항)
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  
  if (requiresAdmin && !auth.currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
