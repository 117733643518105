<template>
  <div class="create-post-container">
    <h2>게시글 수정</h2>
    <form @submit.prevent="updatePost" v-if="postData" class="post-form">
      <div class="form-group">
        <input 
          type="text" 
          id="title"
          v-model="postData.title" 
          required
          placeholder="제목을 입력하세요"
          class="modern-input"
        >
      </div>

      <div class="form-group category-group">
        <select 
          id="category"
          v-model="postData.category" 
          required
          class="modern-select"
        >
          <option value="스튜디오">스튜디오</option>
          <option value="장터">장터</option>
          <option value="질문">질문</option>
          <option value="정보">정보</option>
        </select>
      </div>

      <div class="form-group">
        <textarea 
          id="content"
          v-model="postData.content" 
          rows="10" 
          required
          placeholder="내용을 입력하세요"
          class="modern-textarea"
        ></textarea>
      </div>

      <div class="form-group" v-if="postData.images && postData.images.length">
        <div class="image-preview">
          <div v-for="(url, index) in postData.images" :key="'existing-'+index" class="preview-item">
            <img :src="url" alt="Existing image" />
            <button type="button" @click="removeExistingImage(index)" class="remove-image">
              <span class="material-icons">close</span>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group image-upload-group">
        <label for="images" class="image-upload-label">
          <span class="material-icons">add_photo_alternate</span>
          새 이미지 추가
        </label>
        <input 
          type="file"
          id="images"
          @change="handleImageUpload"
          accept="image/*"
          multiple
          class="file-input"
        >
        <div class="image-preview" v-if="imagePreviewUrls.length">
          <div v-for="(url, index) in imagePreviewUrls" :key="'new-'+index" class="preview-item">
            <img :src="url" alt="Preview" />
            <button type="button" @click="removeImage(index)" class="remove-image">
              <span class="material-icons">close</span>
            </button>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button type="submit" :disabled="isSubmitting" class="submit-button">
          <span class="material-icons">{{ isSubmitting ? 'hourglass_empty' : 'save' }}</span>
          {{ isSubmitting ? '수정 중...' : '수정하기' }}
        </button>
        <button type="button" @click="cancelEdit" class="cancel-button">
          <span class="material-icons">close</span>
          취소
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebaseConfig';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'EditPost',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const postId = route.params.id;
    const postData = ref(null);
    const isSubmitting = ref(false);
    const selectedImages = ref([]);
    const imagePreviewUrls = ref([]);

    const loadPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, 'posts', postId));
        if (postDoc.exists()) {
          postData.value = {
            ...postDoc.data(),
            id: postDoc.id
          };
          
          const auth = getAuth();
          if (auth.currentUser?.uid !== postData.value.authorId) {
            alert('게시글을 수정할 권한이 없습니다.');
            router.push('/posts');
          }
        } else {
          alert('게시글을 찾을 수 없습니다.');
          router.push('/posts');
        }
      } catch (error) {
        console.error('게시글 로딩 오류:', error);
        alert('게시글을 불러오는데 실패했습니다.');
        router.push('/posts');
      }
    };

    const handleImageUpload = (event) => {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          selectedImages.value.push(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            imagePreviewUrls.value.push(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      });
    };

    const removeExistingImage = (index) => {
      postData.value.images.splice(index, 1);
    };

    const removeImage = (index) => {
      selectedImages.value.splice(index, 1);
      imagePreviewUrls.value.splice(index, 1);
    };

    const uploadNewImages = async () => {
      const storage = getStorage();
      const imageUrls = [];

      for (const image of selectedImages.value) {
        const imageName = `posts/${Date.now()}_${image.name}`;
        const imageRef = storageRef(storage, imageName);
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        imageUrls.push(url);
      }

      return imageUrls;
    };

    const updatePost = async () => {
      try {
        isSubmitting.value = true;
        
        const newImageUrls = await uploadNewImages();
        
        const allImages = [...(postData.value.images || []), ...newImageUrls];

        const updateData = {
          title: postData.value.title,
          content: postData.value.content,
          category: postData.value.category,
          images: allImages,
          updatedAt: serverTimestamp()
        };

        await updateDoc(doc(db, 'posts', postId), updateData);
        
        router.push(`/post/${postId}`);
      } catch (error) {
        console.error('게시글 수정 오류:', error);
        alert('게시글 수정 중 오류가 발생했습니다.');
      } finally {
        isSubmitting.value = false;
      }
    };

    const cancelEdit = () => {
      router.push(`/post/${postId}`);
    };

    onMounted(() => {
      loadPost();
    });

    return {
      postData,
      isSubmitting,
      updatePost,
      handleImageUpload,
      removeImage,
      removeExistingImage,
      imagePreviewUrls,
      cancelEdit
    };
  }
}
</script>

<style scoped>
.create-post-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 32px;
}

.post-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modern-input,
.modern-select,
.modern-textarea {
  width: 100%;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.modern-input:focus,
.modern-select:focus,
.modern-textarea:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.1);
  background: #ffffff;
}

.modern-textarea {
  min-height: 200px;
  resize: vertical;
  line-height: 1.6;
}

.image-upload-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: #f8f9fa;
  border: 1px dashed #ccc;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.image-upload-label:hover {
  background: #f1f3f5;
  border-color: #666;
}

.file-input {
  display: none;
}

.image-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.preview-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease;
}

.remove-image:hover {
  background: rgba(0, 0, 0, 0.7);
}

.button-group {
  display: flex;
  gap: 12px;
}

.submit-button,
.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 32px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button {
  background: #333;
  color: white;
  flex: 2;
}

.cancel-button {
  background: #f1f3f5;
  color: #333;
  flex: 1;
}

.submit-button:hover:not(:disabled) {
  background: #222;
  transform: translateY(-1px);
}

.cancel-button:hover {
  background: #e9ecef;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .create-post-container {
    padding: 20px;
    margin: 20px;
  }
  
  .modern-input,
  .modern-select,
  .modern-textarea {
    padding: 12px;
  }
}
</style>
