<template>
  <div class="login-page">
    <h2>TATTOO MAP 로그인</h2>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <form @submit.prevent="handleLogin" class="login-form">
      <div class="form-group">
        <label for="email">이메일</label>
        <input type="email" id="email" v-model="email" required />
      </div>
      
      <div class="form-group">
        <label for="password">비밀번호</label>
        <input type="password" id="password" v-model="password" required />
      </div>

      <div class="form-options">
        <label class="stay-logged-in">
          <input type="checkbox" v-model="stayLoggedIn" />
          <span>로그인 상태 유지</span>
        </label>
      </div>
      
      <button 
        type="submit" 
        class="submit-button" 
        :disabled="!isFormValid || isLoading" 
        :class="{ 'button-disabled': !isFormValid || isLoading }"
      >
        {{ isLoading ? '로그인 중...' : '로그인' }}
      </button>
    </form>
    
    <div class="additional-links">
      <router-link to="/signup" class="signup-link">회원가입</router-link>
      <button type="button" class="forgot-password-link" @click="openResetModal">
        비밀번호 찾기
      </button>
    </div>

    <!-- 비밀번호 재설정 모달 -->
    <div v-if="isResetModalOpen" class="modal-overlay">
      <div class="modal">
        <h3>비밀번호 재설정</h3>
        <p v-if="!resetSuccess">
          가입하신 이메일 주소를 입력하시면<br>
          비밀번호 재설정 링크를 보내드립니다.
        </p>
        <p v-else class="success-message">
          비밀번호 재설정 이메일이 전송되었습니다.<br>
          이메일을 확인해주세요.
        </p>
        
        <div v-if="!resetSuccess" class="form-group">
          <label for="resetEmail">이메일</label>
          <input 
            type="email" 
            id="resetEmail" 
            v-model="resetEmail" 
            required 
          />
        </div>

        <div class="modal-buttons">
          <button 
            v-if="!resetSuccess"
            @click="handlePasswordReset" 
            class="reset-button"
          >
            재설정 링크 전송
          </button>
          <button @click="closeResetModal" class="cancel-button">
            {{ resetSuccess ? '닫기' : '취소' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      stayLoggedIn: false,
      isFormValid: false,
      isLoading: false,
      errorMessage: '',
      isResetModalOpen: false,
      resetEmail: '',
      resetSuccess: false
    }
  },
  computed: {
    isFormValidComputed() {
      return this.email && this.password;
    }
  },
  watch: {
    email() {
      this.isFormValid = this.isFormValidComputed;
      this.errorMessage = '';
    },
    password() {
      this.isFormValid = this.isFormValidComputed;
      this.errorMessage = '';
    }
  },
  methods: {
    async handleLogin() {
      if (!this.isFormValid) {
        this.errorMessage = '이메일과 비밀번호를 모두 입력해주세요.';
        return;
      }

      this.isLoading = true;
      this.errorMessage = '';

      try {
        const auth = getAuth();
        // 로그인 유지 설정
        const persistence = this.stayLoggedIn ? browserLocalPersistence : browserSessionPersistence;
        await setPersistence(auth, persistence);
        
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        if (!user.emailVerified) {
          alert('이메일 인증이 완료되지 않았습니다. 이메일을 확인하고 인증을 완료하세요.');
          await auth.signOut();
          return;
        }

        alert('로그인 성공!');
        this.$router.push('/');
      } catch (error) {
        console.error('로그인 오류:', error);
        switch (error.code) {
          case 'auth/invalid-email':
            this.errorMessage = '유효하지 않은 이메일 형식입니다.';
            break;
          case 'auth/user-disabled':
            this.errorMessage = '비활성화된 계정입니다.';
            break;
          case 'auth/user-not-found':
            this.errorMessage = '등록되지 않은 이메일입니다.';
            break;
          case 'auth/wrong-password':
            this.errorMessage = '잘못된 비밀번호입니다.';
            break;
          default:
            this.errorMessage = '로그인에 실패했습니다. 다시 시도해주세요.';
        }
      } finally {
        this.isLoading = false;
      }
    },

    // 비밀번호 재설정 이메일 전송
    async handlePasswordReset() {
      if (!this.resetEmail) {
        this.errorMessage = '이메일을 입력해주세요.';
        return;
      }

      try {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, this.resetEmail);
        this.resetSuccess = true;
        this.errorMessage = '';
        setTimeout(() => {
          this.closeResetModal();
          this.resetSuccess = false;
        }, 3000);
      } catch (error) {
        console.error('비밀번호 재설정 오류:', error);
        switch (error.code) {
          case 'auth/invalid-email':
            this.errorMessage = '유효하지 않은 이메일 형식입니다.';
            break;
          case 'auth/user-not-found':
            this.errorMessage = '등록되지 않은 이메일입니다.';
            break;
          default:
            this.errorMessage = '비밀번호 재설정 이메일 전송에 실패했습니다.';
        }
      }
    },

    openResetModal() {
      this.isResetModalOpen = true;
      this.resetEmail = this.email; // 로그인 폼의 이메일을 자동으로 채움
      this.errorMessage = '';
    },

    closeResetModal() {
      this.isResetModalOpen = false;
      this.resetEmail = '';
      this.errorMessage = '';
    }
  }
}
</script>

<style scoped>
.signup-page,
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.signup-page h2,
.login-page h2 {
  margin-bottom: 30px;
  color: #333333;
  font-weight: 600;
}

.login-form {
  width: 90%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #555555;
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555555;
}

.stay-logged-in {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.forgot-password-btn {
  background: none;
  border: none;
  color: #27ae60;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.forgot-password-btn:hover {
  text-decoration: underline;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #636e72;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button:hover:not(.button-disabled) {
  background-color: #2d3436;
}

.button-disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* 추가 스타일: 비밀번호 찾기 및 회원가입 링크 */
.additional-links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 14px;
}

.signup-link,
.forgot-password-link {
  color: #27ae60;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

.signup-link:hover,
.forgot-password-link:hover {
  text-decoration: underline;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .login-form {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .submit-button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .login-page {
    padding: 20px 10px;
  }
  
  .login-form {
    padding: 15px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="password"],
  .form-group input[type="file"] {
    padding: 10px;
    font-size: 14px;
  }
  
  .submit-button {
    padding: 10px;
    font-size: 16px;
  }
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* 모달 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal h3 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.modal p {
  margin-bottom: 20px;
  color: #666;
  text-align: center;
  line-height: 1.5;
}

.modal .form-group {
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.reset-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.reset-button {
  background-color: #27ae60;
  color: white;
}

.reset-button:hover {
  background-color: #219a52;
}

.cancel-button {
  background-color: #95a5a6;
  color: white;
}

.cancel-button:hover {
  background-color: #7f8c8d;
}

.success-message {
  color: #27ae60;
  font-weight: 500;
}

/* 반응형 디자인 */
@media (max-width: 480px) {
  .modal {
    padding: 20px;
  }

  .form-options {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
</style> 